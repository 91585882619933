import { Directive, ElementRef, Renderer2, HostBinding, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[noBoxShadow]',
})
export class NoBoxShadowDirective   implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.el.nativeElement.style.boxshadow = 'none';
    }
}
