import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Signature } from '../../interfaces/signature.interface';

@Injectable()
export class SignatureService {

    private url = `${environment.api}/signature/`;
    constructor(private http: HttpClient) { }

    save(signature: Signature): Observable<any> {
        return this.http.post<any>(`${this.url}`, signature);
    }
    
    update(signature: Signature): Observable<any> {
        return this.http.put<any>(`${this.url}`, signature);
    }

    delete(primaryKey: any): Observable<any> {
        return this.http.delete<any>(`${this.url}/${encodeURI(JSON.stringify(primaryKey))}`, );
    }

    query(queryParameter: any): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api}/signature/query/${encodeURI(JSON.stringify(queryParameter))}`);
    }

    aggregate(param: any): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api}/signature/aggregate/${encodeURI(JSON.stringify(param))}`);
    }
    // Trouve un seul enregistrements de TypeEntity
    findOne(primaryKey: any): Observable<any> {
        return this.http.get<any>(`${environment.api}/signature/${encodeURI(JSON.stringify(primaryKey))}`);
    }
}
