<div>
    <input [(ngModel)]="searchString" />
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node *matTreeNodeDef="let node" [style.display]="filterLeafNode(node) ? 'none' : 'block'" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="20">
            <button class="button__arrow" mat-icon-button disabled></button>
            <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)" (change)="todoLeafItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        </mat-tree-node>
    
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
            [style.display]="filterParentNode(node) ? 'none' : 'block'" matTreeNodePadding
            matTreeNodePaddingIndent="20">
            <button class="button__arrow" mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.item">
                <mat-icon class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
            </button>
            <mat-checkbox [checked]="descendantsAllSelected(node)"
                [indeterminate]="descendantsPartiallySelected(node)"
                (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
        </mat-tree-node>
    </mat-tree>
</div>
