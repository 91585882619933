import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Like } from '../../core/classes/typeorm';

import { MatDialog } from '@angular/material/dialog';
import { ModalService, ParamDialog } from '../../core/services/modal.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { LIVE_TREATMENT, PARAM_NOTIF_END, DECLARATION_NOTIF_END, FLUID } from 'src/app/app.constant';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  
  
  paramIsNotInExecution: boolean = false;
  declarationIsNotInExecution: boolean = false;

  @ViewChild(MatPaginator, {}) paginator!: MatPaginator;
  displayedColumns: string[] = ['uuid','month', 'year', 'startDate','endDate','total','loaded','parent','origin', 'action'];
  fidelityes!: any[];
  dataSource = new MatTableDataSource<any>([]);
  inProgress = false;
  inSearchProgress = false;

  searchText = '';

  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  constructor(
    public dialog: MatDialog,
    private modal: ModalService,
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,

  ) { }

  ngOnInit() {
    
    setInterval(()=> {
      this.paramIsNotInExecution = this.storage.get(PARAM_NOTIF_END) ? this.storage.get(PARAM_NOTIF_END) : false ;
      this.declarationIsNotInExecution = this.storage.get(DECLARATION_NOTIF_END) ? this.storage.get(DECLARATION_NOTIF_END) : false ;
    },100);

    this.load();
  }

  load() {
    this.inProgress = true; // Démarre le loader
    //this.declarationSyvalService.getAllTreatment(this.storage.get(FLUID)).subscribe((result: any) => {
        this.inProgress = false; // stop le loader
        this.dataSource = new MatTableDataSource<any>([]);
        this.dataSource.sort = this.sort;

        this.dataSource.paginator = this.paginator;
      
    //});
  }

  search(searchText: string) {
    this.inSearchProgress = true; // Démarre le loader
    /*this.treatmentService.query(
      {
        where: [
          { code: Like(`%${searchText}%`) },
          { name: Like(`%${searchText}%`) }
        ],
        relations: ['geofenses']
      }
    ).subscribe((result: any) => {
      this.inSearchProgress = false; // stop le loader
      this.dataSource = new MatTableDataSource<Treatment>(result);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });**/
  }



  applyFilter() {
    this.dataSource.filter = this.searchText;
  }

  loadDeclaration(treatment:any) {
      this.router.navigate([`/m/${this.storage.get(FLUID)}/declaration/`,treatment.id]);
  }

  newTreatment(element:any) {
    /*const dialogRef = this.dialog.open(TreatmentComponent, {
        width: '700px',
        height: '500px' ,
        disableClose: true,
        data: element
    });*/
  }

}
