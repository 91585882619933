import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Utilisateur } from '../../interfaces/utilisateur.interface';

@Injectable()
export class UtilisateurService {

    private url = `${environment.api}/utilisateur/`;
    constructor(private http: HttpClient) { }

    save(utilisateur: Utilisateur): Observable<any> {
        return this.http.post<any>(`${this.url}`, utilisateur);
    }
    
    update(utilisateur: Utilisateur): Observable<any> {
        return this.http.put<any>(`${this.url}`, utilisateur);
    }

    delete(primaryKey: any): Observable<any> {
        return this.http.delete<any>(`${this.url}/${encodeURI(JSON.stringify(primaryKey))}`, );
    }

    query(queryParameter: any): Observable<Utilisateur[]> {
        return this.http.get<any[]>(`${environment.api}/utilisateur/query/${encodeURI(JSON.stringify(queryParameter))}`);
    }

    aggregate(param: any): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api}/utilisateur/aggregate/${encodeURI(JSON.stringify(param))}`);
    }
    // Trouve un seul enregistrements de TypeEntity
    findOne(primaryKey: any): Observable<any> {
        return this.http.get<any>(`${environment.api}/utilisateur/${encodeURI(JSON.stringify(primaryKey))}`);
    }
}
