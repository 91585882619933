<div class="defaultTheme" fxFill>
    <mat-toolbar color="primary" class="mat-elevation-z0" fxFill>
        <mat-toolbar-row>
            <mat-icon>edit</mat-icon>
            <span matPadding></span>
            <span fxFlex>{{data.title}}</span>
            <button color="accent" mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
        <mat-toolbar-row>
            <form [formGroup]="form" matPaddingSmall fxLayout="column" fxFlex>
                <mat-form-field  appearance="outline" fxFlex>
                    <mat-label>{{data.message}} <span>*</span></mat-label>
                    <input matInput formControlName="value" autocomplete="off" />
                    <mat-error>Champ obligatoire </mat-error>
                </mat-form-field>
            </form>
        </mat-toolbar-row>
        <mat-toolbar-row>
            <div matPadding fxLayout="row" fxLayoutAlign="end center" fxFlex>
                <button matRounded [disabled]="form.invalid" color="accent" mat-flat-button (click)="validate()">
                    <mat-icon>check_circle</mat-icon><span matPaddingSmall></span>
                    <span>Valider</span>
                </button>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>