import { Component, OnInit, Inject } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-confirm-bottom-sheet',
  templateUrl: './confirm-bottom-sheet.component.html',
  styleUrls: ['./confirm-bottom-sheet.component.css']
})
export class ConfirmBottomSheetComponent implements OnInit {

  constructor(
    public bottomSheetRef: MatBottomSheetRef<ConfirmBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  valid($event:any) {
    this.bottomSheetRef.dismiss(true);
  }

  cancel($event:any) {
    this.bottomSheetRef.dismiss(false);
  }

}
