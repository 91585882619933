<div fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="70">
            <mat-icon>receipt_long</mat-icon>

            <div class="mat-padding-small"></div>
            <span fxFlex="20" style="padding: 10px">Document</span>

            <div fxLayout="row" fxFlex>
               
            </div>
        </div>
        
        <div fxLayout="row" fxLayoutAlign="end center" fxFlex="30">
            <button  mat-stroked-button matTooltip="Imprimer" mat-icon-button mat-stroked-button (click)="print()">
                <mat-icon>print</mat-icon>
            </button>
            <div matPaddingSmall></div>
            <button  mat-stroked-button matTooltip="Editer" mat-icon-button mat-stroked-button (click)="bModePreview = !bModePreview">
                <mat-icon *ngIf="bModePreview">edit</mat-icon>
                <mat-icon *ngIf="!bModePreview">visibility</mat-icon>
            </button>
            <div matPaddingSmall></div>

            <button matRounded leftIcon mat-stroked-button
                [disabled]="form.invalid"
                (click)="save()" color="primary">
                <mat-icon>save</mat-icon>
                <span matPaddingSmall></span><span>Enregistrer</span><span matPaddingSmall></span>
            </button>
        </div>
    </mat-toolbar>

    <mat-card fxLayout="column" fxFlex>
        <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner diameter="60"></mat-spinner>
            <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
        </div>
        <div fxLayout="row" style="overflow-y: auto;" fxFlex>
            <div fxLayout="column" fxFlex="70" style=" overflow-y: auto; background-color: #eee;">
                <div *ngIf="!bModePreview">
                    <ckeditor class="decoupled-document-demo" [editor]="Editor"  [config]="config" [(ngModel)]="documentText" (ready)="onReady($event)" fxFlex></ckeditor>
                </div>

                <div *ngIf="bModePreview" fxLayout="column" fxLayoutAlign="center center">
                    <div  class="preview">
                        <div id="toPrint" class="ck-content" [innerHtml]="documentText | safeHtml"></div>
                    </div>
                </div>
                
                <!--<pdf-viewer [src]="report"  [render-text]="true"  [show-all]="true" fxFlex></pdf-viewer>-->
            </div>
            <div matPadding fxLayout="column" style="background: rgb(245 245 245);" fxFlex>
                <div matPadding class="mat-padding-small" fxLayout="column" fxFlex>
                    <!-- <mat-accordion>-->

                        <form [formGroup]="form" fxLayout="column" fxFlex>
                            <div style="font-weight: 100; font-size: 20px;">Information sur le modèle</div>
                            <div matPadding></div>
                            <div fxLayout="row">
                                <mat-form-field appearance="outline" fxFlex>
                                    <mat-label>Libelle</mat-label>
                                    <input matInput formControlName="libelle" autocomplete="off" />
                                    <mat-hint>Nom du document</mat-hint>
                                    <mat-error>Champ obligatoire </mat-error>
                                </mat-form-field>
                                <div matPadding></div>
                                <mat-form-field appearance="outline">
                                    <mat-label>Type</mat-label>
                                    <mat-select formControlName="type">
                                        <mat-option value="_ARRETE_">Projet d'arreté</mat-option>
                                        <mat-option value="_REJET_">Courrier de rejet</mat-option>
                                        <mat-option value="_NOTE_">Note de présentation</mat-option>
                                    </mat-select>
                                    <mat-error>Champ obligatoire </mat-error>
                                </mat-form-field>
                            </div>
                            <!--<div matPadding></div>
                            <mat-form-field>
                                <mat-label>Classification</mat-label>
                                <mat-select formControlName="classification" [compareWith]="compareFnCode">
                                    <mat-option *ngFor="let classification of classifications" [value]="classification">
                                        {{ classification.libelle }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>-->
                            <div matPadding></div>
                            <!--<button matRounded leftIcon mat-flat-button  (click)="editer(modele.document)" color="primary">
                                <mat-icon>edit</mat-icon>
                                <span matPaddingSmall></span><span>Editer le modèle</span><span matPaddingSmall></span>
                            </button>
                            <div matPadding></div>-->
                            <div fxLayout="column">
                                <div style="font-weight: 100; font-size: 20px;">Commentaire</div>
                                <div matPadding></div>
                                <div>
                                    <textarea formControlName="description" cols="30" rows="6" style="border: 1px solid #ccc; border-radius: 8px; padding: 8px;" fxFlex></textarea>
                                </div>
                            </div>
                        </form>
                </div>
            </div>
        </div>

    </mat-card>
</div>