import { Directive, ElementRef, Renderer2, HostBinding, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[matRounded]',
})
export class MatRoundedDirective   implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.el.nativeElement.style.borderRadius = '50px';
    }
}

@Directive({
  selector: '[matSquare]',
})
export class MatSquareDirective   implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.el.nativeElement.style.borderRadius = '0px';
    }
}