import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Passation } from '../../interfaces/passation.interface';
import { Observable } from 'rxjs';

@Injectable()
export class PassationService {

    constructor(private http: HttpClient) { }
    // Ajoute un nouvel enregistrement de PassationEntity 
    save(passation: Passation): Observable<any> {
        return this.http.post<any>(`${environment.api}/passation/`, passation);
    }

    // Ajoute un nouvel enregistrement de SouscriptionEntity 
    update(passation: Passation): Observable<any> {
        return this.http.put<any>(`${environment.api}/passation/`, passation);
    }
    // Supprime un enregistrement de PassationEntity
    delete(primaryKey: any): Observable<any> {
        return this.http.delete<any>(`${environment.api}/passation/${primaryKey}`);
    }
    // Trouve tous les enregistrements de PassationEntity
    findAll(): Observable<any> {
        return this.http.get<any>(`${environment.api}/passation/`);
    }
    info(ip: string): Observable<any> {
        return this.http.get<any[]>(`${environment.api}/api/passation/infos/${ip}`);
    }

    // Ajoute un nouvel enregistrement de PassationEntity 
    install(info: any): Observable<any> {
        return this.http.post<any>(`${environment.api}/administration/passation/install`, info);
    }

    query(queryParameter: any): Observable<Passation[]> {
        return this.http.get<any[]>(`${environment.api}/passation/query/${encodeURI(JSON.stringify(queryParameter))}`);
    }

    available(primaryKey: any): Observable<Passation[]> {
        return this.http.get<any[]>(`${environment.api}/passation/available/${primaryKey}`);
    }

    mandataire(primaryKey: any): Observable<Passation[]> {
        return this.http.get<any[]>(`${environment.api}/passation/mandataire/${primaryKey}`);
    }

    // Trouve un seul enregistrements de PassationEntity
    findOne(primaryKey: any): Observable<any> {
        return this.http.get<any>(`${environment.api}/passation/${encodeURI(JSON.stringify(primaryKey))}`);
    }
}
