import { Injectable, Injector, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ModalService, ParamDialog } from '../services/modal.service';

import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
//import { TOKEN, TOKEN_ERROR, TOKEN_EXPIRE_ERROR } from '../../app.constant';

//import { AuthService } from '../../services/auth/auth.service';

import { Router } from '@angular/router';
import { TOKEN } from 'src/app/app.constant';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(
      private readonly injector: Injector,
      private modal: ModalService,
      //private authService: AuthService,
      private router: Router,
      @Inject(LOCAL_STORAGE) private storage: StorageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
      // Interception de la requete
      const _token_ = this.storage.get(TOKEN)?this.storage.get(TOKEN): {};
      request = request.clone({
          setHeaders: {
              Authorization: `Bearer ${_token_.token}`
          }
      });
   

      return next.handle(request)
      .pipe(map((event: HttpEvent<any>) => {
          // Interception de la reponse
          if (event instanceof HttpResponse) {
              // transformation de la reponse
              event = event.clone({body: event.body.data});
          }
          return event;
      }),
      catchError((error: HttpErrorResponse) => {
          const errorObject = error.error;
          console.log(error)

          /*if (
                errorObject.status.error.name === TOKEN_ERROR ||
                errorObject.status.error.name === TOKEN_EXPIRE_ERROR
              ) {
                console.log('error');
                this.storage.set(TOKEN,errorObject.status.error);
                this.router.navigate(['/a/signin']);
                // this.authService.signin({ })
          }
        //{"name":"JsonWebTokenError","message":"jwt malformed"}
          /*let data = {};
          data = {
              reason: error && error.error.reason ? error.error.reason : '',
              status: error.status
          };*/
          // this.modal.interceptorDialog(error);
          return throwError(error.error);
          // return new Observable<any>(error.error);

      }));
  }
}
