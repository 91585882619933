import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize, map, startWith, mergeMapTo } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ModalService, ParamDialog } from 'src/app/core/services/modal.service';

import { Socket } from 'ngx-socket-io';
import { ThemeService } from 'src/app/core/services/theme.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html',
    styleUrls: ['./side-menu.component.scss'],
    animations: [
        trigger('popOverState', [
            state('show', style({
                width: 200
            })),
            state('hide', style({
                width: 65
            })),
            transition('show => hide', animate('150ms ease-out')),
            transition('hide => show', animate('150ms ease-in'))
        ])
    ]
})
export class SideMenuComponent implements OnInit {

    show = false;
    pushed = false;

    get stateName() {
        return this.show ? 'show' : 'hide'
    }

    execution: any = { operation: { uuid: null, name: ` Aucun traitement en cours`, step: 100, allstep: 100 }, message: `Aucun traitement en cours` };
    public remoteExecution: any[] = [];
    fluid!: string;
    screenWidth!: number;
    user: any = <any>{ nom: null, prenoms: null };
    paramNotif: boolean = false;
    declarationNotif: boolean = false;
    organisation: any = <any>{ code: null }

    searchControl = new FormControl();
    places!: Observable<any>;
    isLoading = false;

    nb: number = 0;
    nbDemande: number = 0;
    progressRate = 0;
    organisations: any[] = [];

    @Input() items: any[] = [];
    @Output() callBack = new EventEmitter<any>();

    //
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        //private socket: Socket,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private modal: ModalService,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar

    ) { }

    ngOnInit() {
        this.init();
    }

    public toggle = () => this.show = !this.show;


    public closeSide = () => {
        if(!this.pushed) this.show = false;
    }
    public openSide = () => {
        if(!this.pushed) this.show = true;
    } 

    public epingle = () =>{
        this.pushed = !this.pushed;
        if(this.pushed){
            this.show = true
            this.openSide();
        }
        else{
            this.show = false;
            this.closeSide();
        }
        setTimeout(()=> this.callBack.emit(this.pushed) , 155)
        
    } 

    private init = (): void => {

        //this.nb = this.storage.get(NB_MESSAGE);
        this.screenWidth = window.innerWidth;
        window.onresize = () => { this.screenWidth = window.innerWidth; };
    }


    public goToState = (path: string) => this.router.navigate([path])

}
