import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { APPLICATIONS } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService, ParamDialog } from 'src/app/core/services/modal.service';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Utilisateur } from 'src/app/interfaces/utilisateur.interface';
import { Router } from '@angular/router';
import { UtilisateurService } from 'src/app/services/database/utilisateur.service';
import { MatSort } from '@angular/material/sort';
import { Passation } from 'src/app/interfaces/passation.interface';
import { PassationService } from 'src/app/services/database/passation.service';
import { ORGANISATION , USER } from '../../app.constant';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-passation-modal',
    templateUrl: './passation-modal.component.html',
    styleUrls: ['./passation-modal.component.scss']
})
export class PassationModalComponent implements OnInit {

    public selectedCheckValue : any;
    public passations: any[] = [];
    public isLinear: Boolean = false;
    paramIsNotInExecution: boolean = false;
    declarationIsNotInExecution : boolean = false;
    public form!: FormGroup;

    utilisateur = { _id:null, nom: null, prenoms: null, code : null, mail:null };

    public passation: Passation = <Passation>{}

    displayedColumns: string[] = ['type', 'utilisateur', 'debut', 'fin', 'action'];
    @ViewChild(MatSort, {static: true}) sort!: MatSort;
    @ViewChild(MatPaginator, {}) paginator!: MatPaginator;
    dataSource = new MatTableDataSource<Passation>([]);

    inProgress = false;
    inSearchProgress = false;
    users = [];

    @ViewChild(MatStepper, { static: true }) stepper!: MatStepper;
    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};

    date = new FormControl(new Date());
    serializedDate = new FormControl((new Date()).toISOString());

    panelOpenState = false;

    applications = [];

    searchText = '';

    typeChoises = [
        { label: "Déléguation", value: "delegation"},
        { label: "Intérim", value: "interim"}
    ];

    fluid: any;
    progress: { loading: boolean; label: string; } = { loading: false, label:''};
    edit: any;

    constructor(
        public dialogRef: MatDialogRef<PassationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modal: ModalService,
        public fb: FormBuilder,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private _snackBar: MatSnackBar,
        private router: Router,
        public utilisateurService: UtilisateurService,
        public passationService: PassationService,
        // public periodeService: PeriodeService

    ) { }

    ngOnInit(): void {
        this.applications = this.storage.get(APPLICATIONS);
        //this.load();
        this.buildForm();

        this.utilisateur = this.storage.get(USER);
        this.loadPassations();

    }

    primaryClick(event:any){
        this.selectedCheckValue = event.value;
        this.form.patchValue({utilisateur: this.selectedCheckValue});
        console.log(this.selectedCheckValue);

    }

    selectDateDebut(event:any) {
        this.form.patchValue({dateDebut: event.value});
    }

    selectDateFin(event:any) {
        this.form.patchValue({ dateFin: event.value});
    }

    PeriodeSelected(event:any) {
        this.form.patchValue({periode: {dateDebut: event.value, dateFin: event.value}});
    }

     // Construction du formulaire
  buildForm() {
    this.form = this.fb.group({
        _id: new FormControl(null),
        type: new FormControl(null, [Validators.required]),
        responsable: new FormControl(this.utilisateur, [Validators.required]),
        mandataire: new FormControl(null, [Validators.required]),
        debut: new FormControl(null, [Validators.required]),
        fin: new FormControl(null),
        imputation: new FormControl(null),
        signature: new FormControl(null)
  
    });
  }

    loadPassations() {
        this.inProgress = true; // Démarre le loader
        this.passationService.query(
                {
                    'responsable._id' : this.utilisateur._id
                }
            ).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const { status, data } = result as any;
            this.passations = result;
            this.dataSource = new MatTableDataSource<Passation>(result);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

/*
      load() {
        this.inProgress = true; // Démarre le loader
        this.utilisateurService.query({  }).subscribe((result: any) => {
          this.inProgress = false; // stop le loader
          const { status, data } = result as any;
          this.dataSource = new MatTableDataSource<Passation>(result);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;

        });
      }
*/
    openApp(app:any) {
        this.dialogRef.close(app);
    }
    applyFilter() {
        //this.dataSource.filter = this.searchText;
      }


    getSubalterne(event:any, fn?: Function): void {
        let query;
        if(event.value === 'delegation'){
            query = { 'organisation.hierachie': this.storage.get(ORGANISATION).code };
            const mandat: Passation = this.form.value;
            mandat.imputation = true; mandat.signature = false;
            this.form.patchValue(mandat);
        }
        else {
            query = { 'organisation.parent.code': this.storage.get(ORGANISATION).hierachie , mail :{ $ne: this.utilisateur.mail} };
            const mandat: Passation = this.form.value;
            mandat.imputation = true; mandat.signature = true;
            this.form.patchValue(mandat);
        }

        this.utilisateurService.query(query).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const { status, data } = result as any;
            this.users = result;

            if(fn) fn();
  
          });
 
    }


    

    getCollg(): void {
        //this.load();
     }
     

    save(): void {
        this.inProgress = true;
        this.progress = { loading: true, label: '' }; 
        const passation: Passation  = this.form.value;
        
        const send = passation._id ? this.passationService.update(passation) : this.passationService.save(passation)
        send.subscribe((result: any) => {
            this.inProgress = false;
            setTimeout(()=>{
                this.progress = { loading: false, label: '' }; // stop le loader
                if (result) {
                    this._snackBar.open('Passation sauvegardé', 'Dance', { duration: 2000 });
                    this.dialogRef.close(result);
                } else {
                    this._snackBar.open('Impossible d\'enregistrer avec succès', 'Dance', { duration: 2000 });
                }
            },3000);
            
        });
    }

    close() {
        this.dialogRef.close();
    }

    new() {
        this.passation = <Passation>{}
        this.form.patchValue({ responsable : this.utilisateur });
        this.stepper.next();
    }

    select(element: any) {
        this.passation = element;
        this.getSubalterne({value : this.passation.type}, ()=> {
            this.form.patchValue(element);
            this.stepper.next();
        })
    }

    delete(element:any) {
        this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: 'Voulez-vous vous supprimer ce mandat ?' })
            .afterClosed().subscribe((confirmed: any) => {
                if (confirmed) {
                    this.progress = { loading: true, label: 'suppression en cours ...' };
                    this.passationService.delete(element._id).subscribe(
                        result => {
                            this.loadPassations();
                            setTimeout(() => {
                                this._snackBar.open(`Mandat supprimé`, 'Fermer', { duration: 2000 });
                                this.progress = { loading: false, label: '' };
                            }, 3000);
                        },
                        error => {
                            setTimeout(() => {
                                this.progress = { loading: false, label: '' };
                                this._snackBar.open(`Impossible de supprimer le mandat`, 'Fermer', { duration: 2000 });
                            }, 3000);
                        }
                    );
                }
            }
        );
    }

}
