import { DecimalPipe } from '@angular/common';
import { isNumber } from 'util';

const Template = (params: any) => {
    console.log(params)
    const t = {
        content: [
            {
                margin: [0, 0, 0, 50],
                table: {
                    widths: [200, '*'],
                    body: [
                        [
                            {
                                stack: [
                                    { text: 'MINISTERE DES MINES,', alignment: "center", bold: true },
                                    { text: 'DU PETROLE ET DE L’ENERGIE', alignment: "center", bold: true },
                                ],
                                margin: [17, 0, 0, 5],
                                alignment: "center"
                            },
                            {
                                stack: []
                            }
                        ]
                    ]
                }, layout: 'noBorders'
            },

            // ============================================================================================= //
            {
                margin: [0, 0, 0, 0],
                table: {
                    widths: [350, '*'],
                    body: [
                        [
                            {   stack: [
                                
                                    {   
                                        text: `N°${params.numDoc}/MMPE/CAB00`, 
                                        style: 'subheader',
                                        margin: [17, 0, 0, 5],
                                        bold: false
                                    },
                                ] 
                            },
                            {
                                stack: [
                                    { text: `Abidjan, le ${GenerateDate(params.dateSignature)}\n\n`, bold: false },
                                            { text: 'LE MINISTRE \n', bold: true },
                                            { text: 'Monsieur le Directeur Général,\n' },
                                            { text: params.distributeur.raisonSocial + '\n\n', bold: true },
                                            { text: 'ABIDJAN', bold: true, style: "soulignment" },
                                            
                                    
                                ],
                                fontSize: 13,
                                lineHeight: 1,
                                alignment: "left",
                                bold: true,
                            }
                        ]
                    ]
                },
                layout: 'noBorders'
            },
            // ============================================================================================= //
            { text: '\n\n' },
            {
                text: [{ text: 'OBJET :', bold: true, style: 'soulignment' }, ' Votre demande d’agrément \n\n']
                ,  margin: [17, 0, 0, 5], bold: true, fontSize: 14
            },
            {
                text: [
                    { text: 'Monsieur le Directeur Général,\n\n', bold: true },
                    { text: 'J’accuse réception de votre demande relative à l’objet et vous en remercie.' },
                    { text: 'Après examen de votre dossier, je suis au regret de vous informer qu’une suite favorable n’a pu être accordée à votre requête.' },
                    { text: '\n\n' },
                ],

                margin: [17, 0, 0, 5],
                fontSize: 13,
                alignment: "justify"
            },
            {
                text: [
                    { text: 'Veuillez agréer, ' },
                    { text: 'Monsieur le Directeur Général', bold: true },
                    { text: ', l’expression de ma profonde considération.'},
                ],
                alignment: "justify",
                lineHeight: 1.5,
                fontSize: 13,
                margin: [17, 0, 0, 5]
            },
            params.signature ? { image: params.signature , fit:[100,100],alignment:'right' } : { text: '\n\n\n\n\n\n' },
                                    
            {
                text: 'Mamadou SANGAFOWA-COULIBALY',
                alignment: "right",
                fontSize: 13,
                bold: true,
                margin: [17, 0, 0, 5]
            }

        ],
        styles: {
            soulignment: {
                decoration: 'underline', decorationStyle: 'solid', decorationColor: 'black', bold: true
            },
            ampl: {
                fontSize: 8
            },
            header: {
                fontSize: 13,
                bold: true,
                alignment: 'right',
                margin: [0, 1, 0, 80]
            },
            headerRight: {
                fontSize: 13,
                bold: true,
                alignment: 'left',
                margin: [0, 12, 0, 2]
            },
            signatureName: {
                fontSize: 13,
                bold: true,
                alignment: 'right',
                margin: [2, 1, 64, 27]
            },
            signature: {
                fontSize: 13,
                bold: false,
                alignment: 'right',
                margin: [0, 1, 35, 60]
            },
            ampliations: {
                fontSize: 13,
                bold: false,
                alignment: 'left',

            },
            subheader: {
                fontSize: 11,
                bold: true
            },
            superMargin: {
                margin: [20, 0, 40, 0],
                fontSize: 15
            },
            home: {
                fontSize: 12,
                alignment: 'justify'
            }
        }

    }
    return t;
}

const GenerateDate = (dt) => {
    const prefix = '0';
    const d = new Date(dt ? dt : new Date());
    const y = d.getFullYear();
    const m = d.getMonth() + 1;
    const dy = d.getDate();
    //
    const numMonth = `${prefix.repeat(2 - m.toString().length)}`;
    const numDay = `${prefix.repeat(2 - dy.toString().length)}`;

    return `${dy}/${m}/${y}`
}

const Injector = (template: any, data: any, model: any) => {
    return template(data);
}
export const RejeteTemplate = {
    template: Template,
    injector: Injector
}
