<div matPadding fxLayout="column" fxLayoutAlign="center center" fxFill>
    <perfect-scrollbar fxLayout="column" fxLayoutAlign="center center" [config]="{}">


        <div fxLayout="column" fxLayoutAlign="center center" fxFill>

            <form [formGroup]="form" fxLayout="column" style="width: 75%;">

                <div fxLayout="row" fxLayoutAlign="center center" matPadding>
                    <span class="mat-headline">Inscription</span>
                </div>
                <!--<div class="mat-caption mat-padding-small" style="background-color: #fbc02d;padding: 10px;border-radius: 5px;" fxFlex> Je ne souhaite pas recevoir
                    de messages promotionnels d'Airbnb. Je peux également désactiver cette option à tout moment dans
                    les paramètres de mon compte ou à l'aide du lien contenu dans ce message.</div>-->
                <div matPaddingSmall></div>

                <div class="bloc-input" fxLayout="column">
                    <mat-form-field  appearance="outline">
                        <mat-label>Nom</mat-label>
                        <input matInput placeholder="Entrez votre nom" formControlName="nom" autocomplete="off">
                    </mat-form-field>
                    <div matPaddingSmall></div>

                    <mat-form-field  appearance="outline">
                        <mat-label>Prenoms</mat-label>
                        <input matInput placeholder="Entrez votre prénom" formControlName="prenoms" autocomplete="off">
                    </mat-form-field>
                    <div matPaddingSmall></div>

                    <mat-form-field  appearance="outline">
                        <mat-label>Adresse mail</mat-label>
                        <input matInput placeholder="adresse mail" formControlName="mail" autocomplete="off">
                        <mat-icon matSuffix>alternate_email</mat-icon>
                        <mat-hint class="mat-caption">Nous vous enverrons les confirmations et les reçus de voyage par e-mail.</mat-hint>
                        <mat-error *ngIf="form.controls['mail'].errors">
                            <span class="mat-caption mat-error" *ngIf="form.controls['mail'].errors['pattern']">
                                Vous devez renseigner une adresse mail valide
                            </span>
                        </mat-error>
                    </mat-form-field>
                    <div class="mat-caption mat-error">{{error.email}}</div>
                </div>
                <div matPaddingSmall></div>
                <div matPaddingSmall></div>
                <div matPaddingSmall></div>

                <mat-form-field  appearance="outline">
                    <mat-label>Téléphone</mat-label>
                    <input matInput placeholder="Numero de téléphone" formControlName="phone" autocomplete="off">
                </mat-form-field>
                
                

                

                <div matPaddingSmall></div>
                <div class="bloc-input" fxLayout="column">
                    <mat-form-field  appearance="outline">
                        <mat-label>Mot de passe</mat-label>
                        <button mat-icon-button type="button" matSuffix (click)="showPassword = !showPassword">
                            <mat-icon  *ngIf="showPassword" >visibility_off</mat-icon>
                            <mat-icon  *ngIf="!showPassword" >visibility</mat-icon>
                        </button>
                        <input matInput *ngIf="!showPassword"  placeholder="Mot de passe" type="password" formControlName="pass" autocomplete="off">
                        <input matInput *ngIf="showPassword" placeholder="Mot de passe" type="text" formControlName="pass" autocomplete="off">
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label>Confirmation du mot de passe</mat-label>
                        <button mat-icon-button matSuffix type="button" (click)="showCPassword = !showCPassword">
                            <mat-icon  *ngIf="showCPassword" >visibility_off</mat-icon>
                            <mat-icon  *ngIf="!showCPassword" >visibility</mat-icon>
                        </button>
                        <input matInput  *ngIf="!showCPassword"   placeholder="Mot de passe" type="password" formControlName="cpass" autocomplete="off">
                        <input matInput  *ngIf="showCPassword" placeholder="Mot de passe" type="text" formControlName="cpass" autocomplete="off">
                    </mat-form-field>
                </div>
                <div class="mat-caption mat-error">{{error.password}}</div>

                
                <div matPaddingSmall></div>

                <button matRounded color="primary" type="submit" [disabled]="form.invalid" mat-flat-button
                    (click)="register()" mat-button>
                    Accepter et Continuer
                </button>

                <div fxLayout="column" fxLayoutAlign="center center" matPaddingSmall>
                    <span class="mat-body-2">ou connectez vous</span>
                </div>
                <div matPaddingSmall></div>
                <button matRounded routerLink="/a/signin" color="primary" type="submit" mat-stroked-button mat-button>
                    Connectez vous par e-mail
                </button>
            </form>
        </div>
        <!-- Overlay de Connexion -->
        <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner diameter="60"></mat-spinner>
            <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
        </div>
        <!-- Fin Overlay -->

    </perfect-scrollbar>
</div>