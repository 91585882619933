import { Component, OnInit, Inject, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentDemande } from '../../../statistic/index';

import { ModalService, ParamDialog } from '../../../core/services/modal.service';
import { Demande } from 'src/app/interfaces/demande.interface';
import { Organisation } from 'src/app/interfaces/organisation.interface';
import { ParametreGlobalService } from 'src/app/services/settings/parametre-global.service';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { DemandeService } from '../../../services/database/demande.service';
import { ValeurService } from '../../../services/settings/valeur.service';
import { Piece } from 'src/app/interfaces/piece.interface';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { USER, _PROGRESSER_, ORGANISATION, _RETOURNER_, _VALIDER_, _REJETER_, PASSATIONS } from 'src/app/app.constant';
import { WorkflowService } from '../../../services/workflow/workflow.service';

import { environment } from 'src/environments/environment';
import { ObservationFormComponent } from 'src/app/modals/observation-form/observation-form.component';
import { DocumentSigneComponent } from 'src/app/modals/document-signe/document-signe.component';
import { DomSanitizer } from '@angular/platform-browser';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Valeur } from 'src/app/interfaces/valeur.interface';
import { Passation } from 'src/app/interfaces/passation.interface';
import { TransmitComponent } from 'src/app/modals/transmit/transmit.component';
import { Utilisateur } from 'src/app/interfaces/utilisateur.interface';

import * as Editor from '../../../components/ckeditor';
import * as html2pdf from 'html2pdf.js'
import { ModeleService } from 'src/app/services/database/modele.service';
import { Modele } from 'src/app/interfaces/modele.interfce';
import { APP_CODE } from 'src/app.constant';
import { MailtrapService } from 'src/app/services/database/mailtrap.service';
import { Mailtrap } from 'src/app/interfaces/mailtrap.interface';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface Progress { loading: boolean; label: string; }

@Component({
    selector: 'app-demande-details',
    templateUrl: './demande-details.component.html',
    styleUrls: ['./demande-details.component.scss']
})
export class DemandeDetailsComponent implements OnInit, OnDestroy {


    isPreview = false;
    currentDocument!: string;

    isReadOnly = true;
    simpleContent!: string;
    public Editor = Editor;
    public onReady(editor: any) {
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement()
        );
    }

    pdf: any = null;
    report: any = new Blob();
    reporting: any = {};

    sPage!: string;
    sEdit: string = 'arrete';
    pdfResume: any = null;
    reportResume: Blob = new Blob();
    reportingResume: any = {};
    commentaire: string = '';
    documentUrl = {};
    message: any = {};
    demande: Demande = <Demande>{}
    organisation: Organisation = <Organisation><unknown>{ code: null };

    public progress: Progress = { loading: false, label: '' };

    private navigateConfirmed: boolean = false;

    constructor(
        private sanitizer: DomSanitizer,
        private modal: ModalService,
        public dialog: MatDialog,
        @Inject(LOCAL_STORAGE) private storage: StorageService,

        private route: ActivatedRoute,
        private workflowService: WorkflowService,
        private demandeService: DemandeService,
        private modeleService: ModeleService,
        private _snackBar: MatSnackBar,
        public fb: FormBuilder,
        private valeurService: ValeurService,
        private router: Router,
        private mailtrapService: MailtrapService,
    ) {
        
        /*router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
              if(!this.navigateConfirmed){
                const currentRoute = this.router.routerState;
                this.router.navigateByUrl(currentRoute.snapshot.url, { skipLocationChange: true });
                this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: `Vous pouvez perdre vos modifications si le document n'est pas enregistré. Voulez-vous continuer ?` }).afterClosed().subscribe((confirmed: any) => {
                    if (confirmed) {
                        this.navigateConfirmed = true;
                        console.log(event)
                        this.router.navigateByUrl(event.url, { skipLocationChange: true });
                    }
                });
              }
            }
        });*/
    }

    @ViewChild('scrollable') private scrollable!: ElementRef;

    public form!: FormGroup;
    public inProgress: Boolean = false;
    public isLinear: Boolean = false;

    public utilisateur!: Utilisateur;

    public modeleArrete!: Modele;
    public modeleNote!: Modele;
    public modeleRejet!: Modele;


    public pieces: Piece[] = [];
    public modeles: any[] = [];

    public formeJuridiques: Valeur[] = [];
    public typeDemandes: Valeur[] = [];

    ngOnInit() {

        this.loadValeurs();

        this.organisation = this.storage.get(ORGANISATION) ? this.storage.get(ORGANISATION) : <Organisation><unknown>{ code: null };
        this.utilisateur = this.storage.get(USER);

        this.route.paramMap.subscribe(params => {
            const id = params.get('id');
            this.buildForm();
            if (id) {
                this.loadModeles(() => this.loadFormData(id));
            }
            else {
                this.loadPiece();
            }
        });
    }

    ngOnDestroy() {
        
    }

    public changeDoc(){
        switch(this.sEdit) {
            case 'arrete':  { this.loadModele('_ARRETE_'); break; }
            case 'note':    { this.loadModele('_NOTE_'); break; }
            case 'rejet':   { this.loadModele('_REJET_'); break; }
        }
        //this.loadModele();
    }

    selectDoc(doc){

        this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: `Vous allez perdre toute modification éffectué dans le document actuel. Voulez-vous continuer ?` }).afterClosed().subscribe((confirmed: any) => {
            if (confirmed) {

                switch(this.sEdit) {
                    case 'arrete':  { 
                        this.form.controls['document'].patchValue(doc.documentText);
                        this.demande.document = doc.documentText;
                        break; 
                    }
                    case 'note':    { 
                        this.form.controls['documentNote'].patchValue(doc.documentText);        
                        this.demande.documentNote = doc.documentText;
                        break; }
                    case 'rejet':   { 
                        this.form.controls['document'].patchValue(doc.documentText);
                        this.demande.document = doc.documentText;
                        break; 
                    }
                }
            }
        })
        console.log(doc)
    }

    private prepareDocument = (demande: any) => {
        console.log(this.DossierIsValide())
        const b = (demande.valide == true || demande.valide == false) ? demande.valide : this.DossierIsValide();
        this.reporting = (b) ? (demande.isRenouvellement ? DocumentDemande.RenouvellementDocument : DocumentDemande.ProjetDocument) : DocumentDemande.RejetDocument
        const { template, injector } = this.reporting.template
        const documentDefinition = injector(template, this.demande, this.reporting.model);
        this.generate(documentDefinition);
    }

    private generate = (documentDefinition: any) => {
        this.pdf = pdfMake.createPdf(documentDefinition);
        this.pdf.getBlob(async (blob: any) => {
            this.report = await blob.arrayBuffer();
            console.log(this.report)
        });
    }

    private prepareDocumentResume = (resume: any) => {
        this.reportingResume = DocumentDemande.ResumeDocument
        const { template, injector } = this.reportingResume.template
        const documentDefinition = injector(template, this.demande, this.reportingResume.model);
        this.generateResume(documentDefinition);
    }

    private generateResume = (documentDefinition: any) => {
        this.pdfResume = pdfMake.createPdf(documentDefinition);
        this.pdfResume.getBlob(async (blob: any) => {
            this.reportResume = await blob.arrayBuffer();
        });
    }

    loadModele(s:string) {
        this.modeleService.query({ type: s }).subscribe(result => {
            this.modeles = result;
        });

    }

    loadPiece = () => {
        this.inProgress = true; // Démarre le loader
        this.valeurService.query({ code: 'DOCAGREMENT' }).subscribe(result => {
            this.inProgress = false; // stop le loader
            const valeur = (result.length > 0) ? result[0] : { valeurs: [] };
            for (let i = 0; i <= valeur.valeurs.length - 1; i++) {
                this.pieces.push(
                    {
                        type: valeur.valeurs[i],
                        fichier: null,
                        commentaire: '',
                        valid: false
                    }
                )
            }
        });
    }

    loadFormData = (id: any) => {
        this.inProgress = true; // Démarre le loader
        this.demandeService.query({ _id: id }).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const demande: Demande = result[0];
            this.demande = demande;
            console.log(demande.valide)
            this.pieces = demande.pieces;
            this.formPatchValue(demande);
            //this.onChange();
            console.log(demande)
            this.markAsView(this.demande)

            this.prepareDocument(this.demande);


            //LIRE INSTRUCTION

            const { suivi } = demande.workflow;
            for (let i = 0; i <= suivi.length - 1; i++) {
                const { responsable, mandataire, action, imputation, commentaire } = suivi[i];
                if (action || imputation) {
                    if (mandataire._id == this.utilisateur._id) {
                        this.commentaire = commentaire;
                    }
                    break;
                }
            }


        });
    }

    markAsView = (demande: Demande) => {
        for (let i = 0; i <= demande?.workflow?.visibilite?.length - 1; i++) {

            if (!demande?.workflow?.visibilite[i].reinitialisation && demande?.workflow?.visibilite[i].mail == this.utilisateur.mail) {
                console.log(demande?.workflow?.visibilite[i].reinitialisation)
                demande.workflow.visibilite[i].reinitialisation = true;
                console.log('-->', demande?.workflow?.visibilite[i])
                const send = (!demande._id) ? this.demandeService.save(demande) : this.demandeService.update(demande);
                send.subscribe((result: any) => { });
                break;
            }
        }
    }

    cleanAnalyse = () => {
        for (let i = 0; i <= this.pieces.length - 1; i++) {
            this.pieces[i].valid = false;
            this.pieces[i].commentaire = '';
        }
    }

    addDocument = ($event: any, piece: any) => {
        for (let i = 0; i <= this.pieces.length - 1; i++) {
            if (piece.type.code == this.pieces[i].type.code) {
                this.pieces[i].fichier = $event;
                break;
            }
        }
    }

    // Construction du formulaire
    buildForm() {

        this.form = this.fb.group({
            _id: new FormControl(null),
            numero: new FormControl(null),
            app: new FormControl(null),
            niveau: new FormControl(null),
            typeDemande: new FormControl(null, [Validators.required]),
            clientEdit: new FormControl(null),
            distributeur: new FormGroup({
                formeJuridique: new FormControl(null, [Validators.required]),
                raisonSocial: new FormControl(null, [Validators.required]),
                activite: new FormControl(null, [Validators.required]),
                adresse: new FormControl(null, [Validators.required]),
                complementAdresse: new FormControl(null),
                codePostal: new FormControl(null, [Validators.required]),
                cnps: new FormControl(null, [Validators.required]),
                rccm: new FormControl(null, [Validators.required]),
                cc: new FormControl(null, [Validators.required]),
                phone: new FormControl(null),
                mail: new FormControl(null, [Validators.required]),
                siteWeb: new FormControl(null),
            }),
            isRenouvellement: new FormControl(null),
            direction: new FormControl(null),
            valide: new FormControl(null),
            date: new FormControl(null),
            debut: new FormControl(null),
            document: new FormControl(null),
            documentNote: new FormControl(null),

            numDoc: new FormControl(null),
            signe: new FormControl(null),
            dateSignature: new FormControl(null),
            signature: new FormControl(null),
            qrcode: new FormControl(null),

            siteWord: new FormControl(null),
            regionSite: new FormControl(null),
            article9: new FormControl(null),

            pAgrement: new FormControl(null),
            pDateSignature: new FormControl(null),

            observation: new FormControl(null),
            observationClient: new FormControl(null),
            workflow: new FormControl(null),
            utilisateur: new FormControl(this.storage.get(USER), [Validators.required]),
            statut: new FormControl(null)
        });
    }

    // Remplissage du formulaire avec les données initiales
    formPatchValue(value: any) {
        this.form.patchValue(value);
    }


    Next = () => {
        this.transmit(_PROGRESSER_,
            (demande: any) => demande ? this.progresser(_PROGRESSER_, demande) : null
        );

    }

    Valider = () => {
        this.SignerDocument(() => this.progresser(_VALIDER_));
    }

    Rejeter = () => {
        this.SignerDocument(() => this.progresser(_REJETER_));
    }

    Previous = () => {
        this.transmit(_RETOURNER_,
            (demande: any) => demande ? this.progresser(_RETOURNER_, demande) : null
        );
    }


    activeClient(): void {

        const dialogRef = this.dialog.open(ObservationFormComponent, { width: '550px', height: '350px', disableClose: false, data: {} }).afterClosed().subscribe((result: any) => {
            if (result) {
                this.progress = { loading: true, label: '' }; // Démarre le loader
                const demande: Demande = this.form.value;
                demande.clientEdit = true;
                demande.observationClient = result;
                demande.pieces = this.pieces;



                const send = (!demande._id) ? this.demandeService.save(demande) : this.demandeService.update(demande);

                send.subscribe((result: any) => {
                    setTimeout(() => {

                        //Envoi de mail au concerné
                        this.mailtrapService.send(<Mailtrap> {
                            from:{  email:"erp@dgh.ci","name":"ERP DGH"},
                            to:[
                                {    email:demande.utilisateur.mail}
                            ],
                            subject:"La demande",
                            text:`
                            Bonjour M./Mme/Mlle ${demande.utilisateur.nom} ${demande.utilisateur.prenoms}
                            Vous avez récu un retour sur Le dossier N° ${this.demande.numero} de la part de la DGH.

                            "${demande.observationClient}
                
                            Cordialement,
                            DGH
                            `,
                            category:"Agrément Marketeur"
                        }).subscribe(
                            r => {
                                console.log('transmit')
                            }
                        )

                        this.loadFormData(demande._id);
                        this.progress = { loading: false, label: '' }; // stop le loader
                        if (result) this._snackBar.open('Dossier enregistré', 'OK', { duration: 2000 });
                    }, 3000);
                });
            }
        });
    }

    saveDemande(): void {
        this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: `Vous allez enregistrer la demande ?` }).afterClosed().subscribe((confirmed: any) => {
            if (confirmed) {
                this.save();
            }
        });
    }

    save(d?: Demande, fn?: Function): void {

        this.progress = { loading: true, label: '' }; // Démarre le loader
        const demande: Demande = !d ? /*this.form.value*/ this.demande : d;
        const _d_ = this.form.value;
        demande.distributeur = _d_.distributeur;
        demande.siteWord = _d_.siteWord;
        demande.regionSite = _d_.regionSite;
        demande.article9 = _d_.article9;
        demande.pAgrement = _d_.pAgrement;
        demande.pDateSignature = _d_.pDateSignature;

        demande.pieces = this.pieces;
        console.log(demande);
        const send = (!demande._id) ? this.demandeService.save(demande) : this.demandeService.update(demande);

        send.subscribe((result: any) => {
            setTimeout(() => {
                this.progress = { loading: false, label: '' }; // stop le loader
                if (result) this._snackBar.open('Dossier enregistré', 'OK', { duration: 2000 });
            }, 3000);
            if (fn) fn();
        });
    }


    loadValeurs = () => {
        this.inProgress = true; // Démarre le loader
        this.valeurService.query({}).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const valeurs = result;
            for (let i = 0; i <= valeurs.length - 1; i++) {
                if (valeurs[i].code == 'FORMEJURIDIQUE') { this.formeJuridiques = valeurs[i].valeurs; }
                if (valeurs[i].code == 'TYPEAGREMENT') { this.typeDemandes = valeurs[i].valeurs; }
            }
        });
    }

    loadModeles = (fn: Function) => {
        this.inProgress = true; // Démarre le loader
        this.modeleService.query({ app: APP_CODE }).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const modeles = result;
            console.log(modeles)
            for (let i = 0; i <= modeles.length - 1; i++) {

                const modele = modeles[i];
                if (modele.type == "_ARRETE_") { this.modeleArrete = modele; }
                if (modele.type == "_NOTE_") { this.modeleNote = modele; }
                if (modele.type == "_REJET_") { this.modeleRejet = modele; }
            }
            if (fn) fn();
        });
    }

    progresser(action: string, d?: Demande): void {
        const demande: Demande = d ? d : this.form.value;
        demande.pieces = this.pieces;

        console.log(demande)
        const send = this.workflowService.progressDemande(
            {
                demande,
                action: action,
                options: {},
                utilisateur: this.storage.get(USER)
            }
        );

        this.progress = { loading: true, label: 'Progression en cours ...' };
        send.subscribe((result: any) => {
            setTimeout(() => {
                this.progress = { loading: false, label: '' };
                this._snackBar.open('Demande envoyé', 'OK', { duration: 2000 });

                this.goToState('/m/demande/list');


            }, 3000);
        });
    }

    GenerateDate = () => {
        const prefix = '0';
        const d = new Date();
        const y = d.getFullYear();
        const m = d.getMonth() + 1;
        const dy = d.getDate()

        const numMonth = `${prefix.repeat(2 - m.toString().length)}`;
        const numDay = `${prefix.repeat(2 - dy.toString().length)}`;

        return `${numDay}/${numMonth}/${y}`
    }

    goToState(root: string) {
        this.router.navigate([root]);
    }

    onChange() {
        console.log(this.demande.valide)
        const b = this.demande.valide == null ? this.DossierIsValide() : this.demande.valide;
        const content = b ? this.modeleArrete.documentText : this.modeleRejet.documentText;
        const contentNote = this.modeleNote.documentText; //b ? this.modeleNote.documentText : null;

        this.demande.document = !this.demande.document ? content : this.demande.document;
        this.demande.documentNote = !this.demande.documentNote ? contentNote : this.demande.documentNote;

        this.demande.valide = b;
        const message = this.generateObservation({ pieces: this.pieces });
        this.demande.observation = message;
        this.demande.statut = this.demande.statut == '_SOUMIS_' ? '_ENCOURS_' : this.demande.statut



        //this.form.controls['valide'].patchValue(b)
        //this.form.controls['observation'].patchValue(this.generateObservation({ pieces: this.pieces }))
        const demande = this.form.value;
        console.log('hihihihihih')
        this.prepareDocument(demande);
    }

    generateObservation(option: any) {
        let pieces = '';
        for (let i = 0; i <= option.pieces.length - 1; i++) {
            pieces += ` ${i + 1} - ${option.pieces[i].type.libelle} - ${option.pieces[i].valid ? ' Valide ' : ' Invalide '} - ${option.pieces[i].commentaire ? option.pieces[i].commentaire : ' Sans commentaire'} \n`;
        }
        return pieces;
    }

    open = (message: any) => {
        console.log(message)
        this.message = message;
    }

    openFile = (piece: Piece) => {
        window.open(piece.fichier.url);
    }

    openDocument = (fichier: any) => {
        window.open(fichier.url);
    }

    download() {
        if (this.pdf) {
            this.pdf.print();
        }
        /*var element = document.getElementById('toPrint');
        var opt = {
            margin: 1, //top, left, buttom, right
            filename: 't.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 192, scale: 2, letterRendering: true, useCORS: true },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
            pageBreak: { mode: 'css', after: '.break-page' }
        };
        // New Promise-based usage:
        html2pdf().from(element).set(opt).save();*/


    }

    preview() {
        this.isPreview = !this.isPreview;
        this.currentDocument = null;
        if (this.isPreview) {
            switch (this.sEdit) {
                case 'arrete': {
                    this.currentDocument = this.demande.document;
                    break;
                }
                case 'note': {
                    this.currentDocument = this.demande.documentNote;
                    break;
                }
                case 'rejet': {
                    this.currentDocument = this.demande.document;
                    break;
                }
            }
        }

    }

    print() {
        /*if (this.pdf) {
            this.pdf.print();
        }*/
        var element = document.getElementById('toPrint');
        var opt = {
            margin: 1,
            filename: 'output.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf().set({
            pagebreak: { mode: 'avoid-all', before: '.page-break' }
        });

        // New Promise-based usage:
        html2pdf().from(element).set(opt).save();
    }


    DossierIsValide() {
        let b = true;
        for (let i = 0; i <= this.pieces.length - 1; i++) {
            if (!this.pieces[i].valid) {
                b = false;
                break;
            }
        }
        return b;
    }

    GenererDocument(b) {
        this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: `Vous allez perdre toute modification éffectué dans le document actuel. Voulez-vous continuer ?` }).afterClosed().subscribe((confirmed: any) => {
            if (confirmed) {
                const dialogRef = this.dialog.open(ObservationFormComponent, { width: '550px', height: '350px', disableClose: false, data: {} }).afterClosed().subscribe((result: any) => {
                    if (result) {
                        this.form.controls['observation'].patchValue(result);
                        this.form.controls['valide'].patchValue(b);
                        this.demande.valide = b;

                        const content = b ? this.modeleArrete.documentText : this.modeleRejet.documentText;
                        const contentNote = !this.demande.documentNote ? this.modeleNote.documentText : this.demande.documentNote; //b ? this.modeleNote.documentText : null;

                        this.form.controls['document'].patchValue(content);
                        this.form.controls['documentNote'].patchValue(contentNote);

                        this.demande.document = content;
                        this.demande.documentNote = contentNote;
                        this.prepareDocument(this.demande);
                    }
                });
            }
        });

    }

    SignerDocument(fn: Function) {
        const dialogRef = this.dialog.open(DocumentSigneComponent, { width: '550px', disableClose: false, data: {} }).afterClosed().subscribe((signatures: any) => {
            if (signatures) {
                const [qr, sign, num, date] = signatures;
                const isValide = this.form.controls['valide'].value;
                let hasError = false;

                this.demande.signature = sign;
                this.demande.qrcode = qr;
                this.demande.numDoc = num;
                this.demande.dateSignature = date;

                /*if (!isValide) {
                    if (this.demande.document.includes('[QRCODE]') && this.demande.document.includes('[SIGNATURE]')) {
                        this.demande.document = this.demande.document.replace('[NUMERO]', this.demande.numero.substring(this.demande.numero.length - 4, this.demande.numero.length));
                        this.demande.document = this.demande.document.replace('[DATE]', this.GenerateDate());
                        this.demande.document = this.demande.document.replace('[DISTRIBUTEUR]', this.demande.distributeur.raisonSocial);
                        this.demande.document = this.demande.document.replace('[QRCODE]', `<img src="${qr}" height="180"/>`);
                        this.demande.document = this.demande.document.replace('[SIGNATURE]', `<img src="${sign}" height="90"/>`);
                        this.form.controls['document'].patchValue(this.demande.document);
                    }
                    else {
                        hasError = true
                    }
                }
                else {
                    if (this.demande.document.includes('[QRCODE]') && this.demande.document.includes('[SIGNATURE]')) {
                        this.demande.document = this.demande.document.replace('[NUMERO]', this.GenerateDate());
                        this.demande.document = this.demande.document.replace('[DATE]', this.GenerateDate());
                        this.demande.document = this.demande.document.replace('[DISTRIBUTEUR]', this.demande.distributeur.raisonSocial);
                        this.demande.document = this.demande.document.replace('[QRCODE]', `<figure class="image image_resized"><img src="${qr}" height="150"/></figure>`);
                        this.demande.document = this.demande.document.replace('[SIGNATURE]', `<figure class="image image_resized"><img src="${sign}" height="120"/></figure>`);
                        this.form.controls['document'].patchValue(this.demande.document);
                    }
                    else {
                        hasError = true
                    }
                }

                if (hasError) {
                    this.modal.alert({ title: 'Erreur', message: `Le document ci ne prévoit pas de signature` })
                }
                else {
                    this.demande.signe = true;
                    this.demande.niveau = null;//Le dossier n'est plus positionné chez quelqu'un
                    this.demande.workflow.etape = null;//Le dossier n'est plus positionné chez quelqu'un
                    this.save(this.demande, () => {
                        this.loadFormData(this.demande._id)
                    });
                }*/

                this.demande.signe = true;
                this.demande.niveau = null;//Le dossier n'est plus positionné chez quelqu'un
                this.demande.workflow.etape = null;//Le dossier n'est plus positionné chez quelqu'un
                this.save(this.demande, () => {
                    this.loadFormData(this.demande._id)
                });

                /*
                this.form.controls['document'].patchValue(result);
                fn();*/

            }
        });
    }


    getDroitImputation = (organisationCode: any) => {
        const passations = this.storage.get(PASSATIONS);
        const { code } = this.storage.get(ORGANISATION);
        let ret: boolean = false;
        if (code == organisationCode) ret = true;
        for (let i = 0; i <= passations.length - 1; i++) {
            const passation: Passation = passations[i];
            if (passation.imputation) {
                if (passation?.responsable?.organisation?.code == organisationCode) ret = true;
                break;
            }
        }
        return ret;
    }

    getDroitSignature = (organisationCode: any) => {
        const passations = this.storage.get(PASSATIONS);
        const { code } = this.storage.get(ORGANISATION);
        let ret: boolean = false;
        if (code == organisationCode) ret = true;
        for (let i = 0; i <= passations.length - 1; i++) {
            const passation: Passation = passations[i];
            if (passation.signature) {
                if (passation?.responsable?.organisation?.code == organisationCode) ret = true;
                break;
            }
        }
        return ret;
    }

    transmit(action: any, fn: Function) {
        const demande: Demande = this.demande;
        const dialogRef = this.dialog.open(TransmitComponent, {
            width: '500px',
            height: '506px',
            disableClose: false,
            data: { action, demande }
        }).afterClosed().subscribe((result: any) => {
            if (fn) fn(result);
        });
    }

    imputer = (action: any) => {
        this.transmit(action, (demande: any) => {
            if (demande) {
                this.demande = demande;
                this.save(demande, () => {
                    
                    this.goToState('/m/demande/list');
                });
            }
        });
    }



}
