import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import { Account } from '../../interfaces/account.interface';
import { AccountService } from '../../services/auth/account.service';

interface Progress { loading: boolean; label: string; }

import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ENTREPRISE, ORGANISATION, SIGNATURE, TOKEN, USER } from '../../app.constant';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CollaborationService } from 'src/app/services/database/collaboration.service';
import { Entreprise } from 'src/app/interfaces/entreprise.interface';
import { CollaborationFormComponent } from '../collaboration-form/collaboration-form.component';
import { ModalService, ParamDialog } from 'src/app/core/services/modal.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PassationModalComponent } from '../passation-modal/passation-modal.component';
import { PassationService } from 'src/app/services/database/passation.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Utilisateur } from 'src/app/interfaces/utilisateur.interface';
import { Signature } from 'src/app/interfaces/signature.interface';
import { SignatureService } from 'src/app/services/database/signature.service';
import { Passation } from 'src/app/interfaces/passation.interface';
@Component({
    selector: 'app-profil',
    templateUrl: './profil.component.html',
    styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

    public iForm!: FormGroup;
    public pForm!: FormGroup;
    public inProgress!: Boolean;
    public user!: Account;
    public progress: Progress = { loading: false, label: '' }; // Progression du

    displayedColumns: string[] = ['utilisateur'];
    @ViewChild(MatSort, {static: true}) sort!: MatSort;
    @ViewChild(MatPaginator, {}) paginator!: MatPaginator;
    dataSource = new MatTableDataSource<Passation>([]);
    public passations: any[] = [];
    
    public mode: string = '_PROFIL_';
    entreprise: Entreprise = <Entreprise>{};
    organisation: any;

    //SIgnature
    input: any = document.createElement('input');
    signature!: Signature;
    public file!: any;
    public iconeFile!: string;
    public imageB64!:string;
    public imageStyle!: string;


    constructor(
        public fb: FormBuilder,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ProfilComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private accountService: AccountService,
        private collaborationService: CollaborationService,
        private _snackBar: MatSnackBar,
        private modal: ModalService,
        private administrationSyvalService: AccountService,
        public passationService: PassationService,
        private signatureService: SignatureService,




    ) { }

    ngOnInit(): void {
        this.iForm = this.fb.group({
            nom: new FormControl(null, [Validators.required]),
            prenoms: new FormControl(null, [Validators.required]),
            mail: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
        });

        this.pForm = this.fb.group({
            pass: new FormControl(null, [Validators.required]),
            newpass: new FormControl(null, [Validators.required]),
            cpass: new FormControl(null, [Validators.required]),
        });

        this.user = this.storage.get(USER);
        this.organisation = this.storage.get(ORGANISATION);
        console.log(this.user)
        this.iForm.patchValue(this.user);
        this.loadCollaboration();
        this.loadPassations();

        this.signature = this.storage.get(SIGNATURE) ? this.storage.get(SIGNATURE): <Signature><unknown>{ utilisateur: null, data: null }  ;
        this.onFileSelected();
    }

    save() {
        const user: Account = { ...this.user, ...this.iForm.value }
        this.progress = { loading: true, label: 'sauvegarde en cours ...' };


        this.accountService.firstConnexion(user).subscribe(
            result => {
                this.storage.set(USER, user);
                setTimeout(() => {
                    this.progress = { loading: false, label: '' };
                    this.dialogRef.close();
                }, 3000);
                this.dialogRef.close();
                console.log(result);
            },
            error => {
                setTimeout(() => { this.progress = { loading: false, label: '' }; }, 3000);
                console.log(error);

            }
        )
    }

    loadCollaboration() {
        this.collaborationService.query({ 'utilisateur._id': this.storage.get(USER)._id }).subscribe((result: any) => {
            const collaboration: any = (result.length == 1) ? result[0] : {};
            this.entreprise = collaboration.entreprise;
        });
    }

    seCoupler() {
        const dialogRef = this.dialog.open(CollaborationFormComponent, { width: '400px', height: '400px', disableClose: false, data: {} })
        .afterClosed().subscribe((result: any) => {
            if(result){
                this.entreprise = result;
                this.storage.set(ENTREPRISE,result);
            }
        });
    }

    dissocier() {
        this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: `Voulez-vous dissocier votre compte de  ${this.storage.get(ENTREPRISE).raisonSocial} ?` }).afterClosed().subscribe((confirmed: any) => {
            if (confirmed){
                //Recupère la collaboration et on supprime
                this.collaborationService.query({ 'utilisateur._id': this.storage.get(USER)._id }).subscribe((result: any) => {
                    const collaboration: any = (result.length == 1) ? result[0] : null;
                    if(collaboration){
                        this.collaborationService.delete(collaboration._id).subscribe((result: any) => {
                            this.entreprise = <Entreprise>{};
                            this.storage.set(ENTREPRISE,this.entreprise);
                            setTimeout(() => {
                                //this.loadFormData();
                                //this.progress = { loading: false, label: '' };
                                //this._snackBar.open('Demande supprimé', 'OK', { duration: 2000 });
                            }, 3000);
                        })
                    }
                });
            }
        });
    }

    savePassword() {
        const user = { ...this.pForm.value }
        user.mail = this.storage.get(USER).mail;
        this.progress = { loading: true, label: 'sauvegarde en cours ...' };
        this.administrationSyvalService.updatePassword(user).subscribe(
            result => {
                console.log('resultat', result)
                setTimeout(() => { this.progress = { loading: false, label: '' }; }, 20000);
                this._snackBar.open(`Mot de passe modifié`, 'Fermer', { duration: 2000 });
                this.dialogRef.close();
            },
            error => {
                setTimeout(() => { this.progress = { loading: false, label: '' }; }, 3000);
                this._snackBar.open(`Impossible de modifier le mot de passe`, 'Fermer', { duration: 2000 });

            }
        )
    }


    cancelPassword() {
        this.pForm.patchValue({});
    }

    cancel() {
        this.user = this.storage.get(USER);
        this.iForm.patchValue(this.user);
        this.dialogRef.close();

    }

    openPassationApp() {
        const dialogRef = this.dialog.open(PassationModalComponent, {
            width: '700px',
            height: '500px',
            disableClose: true,
            data: {}
        }).afterClosed().subscribe((result: any) => {
           
        });
    }

    loadPassations() {
        this.inProgress = true; // Démarre le loader
        this.passationService.available(this.user._id).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const { status, data } = result as any;
            this.passations = result;
            this.dataSource = new MatTableDataSource<Passation>(result);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    onFileSelected() {
        this.inProgress = false;
        this.input.setAttribute('type', 'file');
        this.input.setAttribute('accept', "");
        this.input.addEventListener('change', (e:any) => {
            this.file = this.input.files[0];
            const reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = () => {

                    this.signature = { ...this.signature, ...<Signature>{
                        utilisateur: this.storage.get(USER),
                        data: reader.result
                    } } 
                };
                reader.onerror = function (error) { console.log('Error: ', error); };
        });
  }

  selectFile() { 
    this.input.click();
    console.log(this.input)
   }
   
   saveSignature() {
    this.progress = { loading: true, label: 'sauvegarde en cours ...' };
    const signature: Signature = this.signature;
    const send = (!signature._id) ? this.signatureService.save(signature) : this.signatureService.update(signature);
    send.subscribe((result: any) => {
        setTimeout(() => {
            if (result) this._snackBar.open('Signature enregistré', 'OK', { duration: 2000 });
            this.signature = result;
            this.storage.set(SIGNATURE, this.signature);
            this.progress = { loading: false, label: '' };
        },3000);
    });
}


    close() {
        this.dialogRef.close();
    }

    

}