import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { APPLICATIONS } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/core/services/modal.service';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
    selector: 'app-observation-form',
    templateUrl: './observation-form.component.html',
    styleUrls: ['./observation-form.component.scss']
})
export class ObservationFormComponent implements OnInit {


    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};
    public observation: string = '';

    applications = [];
    searchText!:string;
    constructor(
        public dialogRef: MatDialogRef<ObservationFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modal: ModalService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,

    ) { }

    ngOnInit(): void {
        this.applications = this.storage.get(APPLICATIONS);
    }

    close() {
        this.dialogRef.close();
    }

    Send(){
        this.dialogRef.close(this.observation);
    }

    applyFilter() {
        //this.dataSource.filter = this.searchText;
    }

}
