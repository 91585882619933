import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Injector } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from '../core/interceptor/request.interceptor';
// Module
import { MaterialModule } from './modules/material.module';

// Service
import { ModalService } from './services/modal.service';
import { ThemeService } from './services/theme.service';
//import { MatTreeViewService } from './services/mat-treeview.service';
import { GeoLocationService } from './services/geolocation.service';
import { FileUploadService } from './services/file-upload.service';

// Component
import { AlertComponent } from './components/alert/alert.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ConfirmBottomSheetComponent } from './components/confirm-bottom-sheet/confirm-bottom-sheet.component';
import { HttpErrorInterceptorComponentComponent } from './components/http-error-interceptor-component/http-error-interceptor-component.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';

import { ContentEditableFormDirective } from './directives/content-editable-form.directive';
import { GRoleDirective } from './directives/grole.directive';
import { RoleGuard } from './guards/role.guard';
import { MatPaddingDirective, MatPaddingSmallDirective } from './directives/mat-padding.directive';
import { NoBoxShadowDirective } from './directives/no-boxshadow.directive';
import { MatRoundedDirective, MatSquareDirective } from './directives/mat-rounded.directive';
import { LeftIconDirective } from './directives/left-icon.directive';
import { RightIconDirective } from './directives/right-icon.directive';
import { OverlaySpinerComponent } from './components/overlay-spiner/overlay-spiner.component';
import { PromptComponent } from './components/prompt/prompt.component';
import { ExternalModule } from './modules/external.module';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { TimelineDirective } from './directives/timeline.directive';
import { OverflowYDirective } from './directives/overflow.directive';
import { TreeViewComponent } from './components/tree-view/tree-view.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
@NgModule({
    declarations: [
        SafeHtmlPipe,
        AlertComponent,
        
        ConfirmComponent,
        ConfirmBottomSheetComponent,
        HttpErrorInterceptorComponentComponent,
        FileUploadComponent,
        ContentEditableFormDirective,
        SideMenuComponent,

        GRoleDirective,
        MatPaddingDirective,
        MatPaddingSmallDirective,
        NoBoxShadowDirective,
        MatRoundedDirective,
        MatSquareDirective,
        LeftIconDirective,
        RightIconDirective,
        OverlaySpinerComponent,
        PromptComponent,
        TimelineDirective,
        OverflowYDirective,
        TreeViewComponent
    ],
    imports: [
        HttpClientModule,
        ReactiveFormsModule,
        BrowserModule,
        MaterialModule,
        ExternalModule,
        FormsModule,
        BrowserAnimationsModule,
      
    ],
    providers: [ModalService, //MatTreeViewService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        },
        GeoLocationService, FileUploadService, ThemeService,
        RoleGuard
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    entryComponents: [AlertComponent, ConfirmComponent, ConfirmBottomSheetComponent, HttpErrorInterceptorComponentComponent],
    exports: [
        MaterialModule, ExternalModule, SideMenuComponent,
        AlertComponent, ConfirmComponent, MaterialModule, 
        FileUploadComponent, GRoleDirective, MatPaddingDirective, 
        MatPaddingSmallDirective, MatRoundedDirective, LeftIconDirective, 
        RightIconDirective, OverlaySpinerComponent, TimelineDirective, 
        OverflowYDirective, TreeViewComponent,
        SafeHtmlPipe
    ],

  })
  export class CoreModule {   }
