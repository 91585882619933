import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import localeFr from '@angular/common/locales/fr';

//import { StorageServiceModule } from 'ngx-webstorage-service';
//import { ChartsModule } from 'ng2-charts';

//import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { CoreModule } from './core/core.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { QuillModule } from 'ngx-quill';

import { AuthService } from './services/auth/auth.service';


import { BarometerComponent } from './components/barometer/barometer.component';
import { OverSpinnerComponent } from './components/over-spinner/over-spinner.component';


import { MComponent } from './pages/m/m.component';
import { AComponent } from './pages/a/a.component';
import { SignupComponent } from './pages/signup/signup.component';
import { SigninComponent } from './pages/signin/signin.component';

import { IsSignedGuard, IsNotSignedGuard } from './guards/auth.guard';

//import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { GRoleDirective } from './core/directives/grole.directive';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

//import { ParametreGlobalService } from './services/parametre.service';

import { ParameterComponent } from './pages/parameter/parameter.component';
import { ParametreGlobalService } from './services/settings/parametre-global.service';
import { ParameterService } from './services/database/parametre.service';
import { SettingService } from './services/settings/setting.service';
import { WorkflowService } from './services/workflow/workflow.service';
import { DemandeService } from './services/database/demande.service';
import { ValeurService } from './services/settings/valeur.service';
import { ValeurFormComponent } from './modals/valeur-form/valeur-form.component';
import { ValeurComponent } from './pages/valeur/valeur.component';
import { AuthentificationComponent } from './pages/authentification/authentification.component';
import { AuthentificationService } from './services/auth/authentification.service';
import { ApplicationSyvalComponent } from './modals/application-syval/application-syval.component';
import { AccountService } from './services/auth/account.service';
import { SettingAccountComponent } from './pages/setting-account/setting-account.component';
import { VerifyAccountComponent } from './pages/verify-account/verify-account.component';
import { FComponent } from './pages/f/f.component';
import { SouscriptionComponent } from './pages/f/souscription/souscription.component';
import { SouscriptionListComponent } from './pages/f/souscription-list/souscription-list.component';
import { SouscriptionFormComponent } from './pages/f/souscription-form/souscription-form.component';
import { DemandeComponent } from './pages/m/demande/demande.component';
import { DemandeListComponent } from './pages/m/demande-list/demande-list.component';
import { DemandeDetailsComponent } from './pages/m/demande-details/demande-details.component';
import { MessageComponent } from './pages/message/message.component';
import { MessageClientComponent } from './pages/f/message-client/message-client.component';
import { NotificationService } from './services/database/notification.service';
import { ObservationFormComponent } from './modals/observation-form/observation-form.component';
import { DocumentSigneComponent } from './modals/document-signe/document-signe.component';
import { ProfilComponent } from './modals/profil/profil.component';
import { ResetPasswordComponent } from './modals/reset-password/reset-password.component';
import { CollaborationFormComponent } from './modals/collaboration-form/collaboration-form.component';

//import { AngularFireModule } from '@angular/fire';
//import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { UtilisateurService } from './services/database/utilisateur.service';
import { EntrepriseService } from './services/database/entreprise.service';
import { CollaborationService } from './services/database/collaboration.service';
import { DemandeValidComponent } from './pages/f/demande-valid/demande-valid.component';
import { DemandeCoursComponent } from './pages/f/demande-cours/demande-cours.component';
import { PassationModalComponent } from 'src/app/modals/passation-modal/passation-modal.component';
import { PassationService } from './services/database/passation.service';
import { SignatureService } from './services/database/signature.service';
import { TransmitComponent } from './modals/transmit/transmit.component';
import { NgxMaskModule } from 'ngx-mask';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';
//import { StorageServiceModule } from 'ngx-webstorage-service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModeleService } from './services/database/modele.service';
import { ModeleDocumentDetailsComponent } from './pages/m/modele-document-details/modele-document-details.component';
import { ModeleDocumentListComponent } from './pages/m/modele-document-list/modele-document-list.component';
import { ModeleDocumentComponent } from './pages/m/modele-document/modele-document.component';
import { QrCodeModule } from 'ng-qrcode';
import { MailtrapService } from './services/database/mailtrap.service';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

registerLocaleData(localeFr, 'fr-FR');

@NgModule({
    declarations: [
        AppComponent,
        VerifyAccountComponent, SettingAccountComponent,
        BarometerComponent, ParameterComponent,
        MComponent, AComponent, SigninComponent, SignupComponent,
        OverSpinnerComponent,  DashboardComponent, ValeurFormComponent, ValeurComponent, AuthentificationComponent,
        ApplicationSyvalComponent, AccessDeniedComponent,
        FComponent,
        SouscriptionComponent,
        SouscriptionListComponent,
        SouscriptionFormComponent,
        DemandeComponent,
        DemandeListComponent,
        DemandeDetailsComponent,
        MessageComponent,
        MessageClientComponent,
        ObservationFormComponent,
        DocumentSigneComponent,
        ProfilComponent,
        ResetPasswordComponent,
        CollaborationFormComponent,
        DemandeValidComponent,
        DemandeCoursComponent,
        PassationModalComponent,
        TransmitComponent,
        ModeleDocumentListComponent,
        ModeleDocumentDetailsComponent,
        ModeleDocumentComponent
    ],
    imports: [
        CoreModule,
        CommonModule,
        //SocketIoModule.forRoot(environment.socketIoConfig),
        FormsModule, ReactiveFormsModule, PdfViewerModule,
        BrowserModule, //ChartsModule,
        NgxMaskModule.forRoot({}),
        QrCodeModule,
        PerfectScrollbarModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CKEditorModule,
        ContenteditableValueAccessorModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule
    ],
    schemas: [
        NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA
    ],
    providers: [
        { provide: DEFAULT_PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }, ParametreGlobalService, IsSignedGuard, IsNotSignedGuard, NotificationService,
        AuthService, CollaborationService, UtilisateurService, DecimalPipe, EntrepriseService, ParameterService, SettingService, ValeurService, AuthentificationService, AccountService, DemandeService, ModeleService, WorkflowService, SignatureService,
        PassationService, MailtrapService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }