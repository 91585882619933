<div fxLayout="column" class="defaultTheme route" fxFill>
    <div matPadding fxLayout="column" fxLayoutAlign="center center" fxFlex>
        <div class="mat-headline capitalize">{{data.title}}</div>
        <div class="mat-body-1">{{data.message}}</div>
        <div matPadding></div>
        <div matPadding>
            <button color="primary" (click)="close()" mat-flat-button mat-button>
                <span  class="mat-body-2 capitalize">OK</span>
            </button>
        </div>
    </div>
</div>