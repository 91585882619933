import { Component, OnInit, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { FLUID } from 'src/app/app.constant';

@Component({
  selector: 'app-a',
  templateUrl: './a.component.html',
  styleUrls: ['./a.component.scss']
})
export class AComponent implements OnInit {
  fluid: any;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,

  ) { }

  ngOnInit(): void {
    this.fluid = this.storage.get(FLUID);

  }

}
