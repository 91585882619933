<div fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar>
        <div fxLayout="row" *ngIf="sPage != 'courrier'" fxLayoutAlign="start center" fxFlex="33">

            <mat-icon>font_download</mat-icon>

            <div matPaddingSmall></div>
            <span fxFlex="20" style="padding:10px;" *ngIf="!demande.isRenouvellement">Demande d'agrément</span>
            <span fxFlex="20" style="padding:10px;" *ngIf="demande.isRenouvellement">Demande de rénouvellement</span>
        </div>
        <div fxLayout="row" *ngIf="sPage == 'courrier'" fxFlex="33">
            <mat-button-toggle-group [(ngModel)]="sEdit" class="mat-body-2" *ngIf="!isPreview" (click)="changeDoc()">
                <mat-button-toggle *ngIf="!form.controls.valide.value" value="rejet">Courrier de
                    rejet</mat-button-toggle>
                <mat-button-toggle *ngIf="form.controls.valide.value" value="arrete">Projet d'arreté</mat-button-toggle>
                <mat-button-toggle value="note">Note de présentation</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center" fxFlex="33">
            <mat-button-toggle-group [(ngModel)]="sPage" class="mat-body-2">
                <mat-button-toggle value="declaration">Dossier</mat-button-toggle>
                <mat-button-toggle value="courrier"
                    [disabled]="demande.statut== '_SOUMIS_'">Traitement</mat-button-toggle>
                <mat-button-toggle value="observation" [disabled]="demande.statut== '_SOUMIS_'">Historique
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="sPage != 'courrier'" fxLayout="row" fxLayoutAlign="end center" fxFlex="33">
            <!--<button mat-icon-button mat-stroked-button matTooltip="Retourner" [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value" (click)="Previous()">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>-->
            <div matPaddingSmall></div>
            <button mat-icon-button
                [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value"
                matTooltip="Retour au client" (click)="activeClient()">
                <mat-icon>face</mat-icon>
            </button>
            <div matPaddingSmall></div>
            <button mat-stroked-button mat-icon-button (click)="imputer('_RETOURNER_')"
                matTooltip="Descendre le dossier"
                [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value">
                <mat-icon>call_received</mat-icon>
            </button>
            <div matPaddingSmall></div>


            <button mat-stroked-button mat-icon-button (click)="imputer('_PROGRESSER_')" matTooltip="Monter le dossier"
                [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value">
                <mat-icon>call_made</mat-icon>
            </button>
            <div matPaddingSmall></div>
            <div matPaddingSmall></div>
            <button mat-icon-button matTooltip="Enregistrer" (click)="saveDemande()"
                [disabled]="!getDroitImputation(form.controls.niveau.value)">
                <mat-icon>save</mat-icon>
            </button>
            <div matPaddingSmall *ngIf="commentaire"></div>
            <button *ngIf="commentaire" mat-icon-button matTooltip="Instruction / Message"
                [matBadge]="1 |number: '1.0-0'" matBadgeColor="warn" [matMenuTriggerFor]="menuMessage">
                <mat-icon>message</mat-icon>
            </button>
            <mat-menu #menuMessage="matMenu" xPosition="before">
                <div matPadding style="overflow-y: auto;" fxFlex>
                    <span class="mat-body-1">{{commentaire}}</span>
                </div>
            </mat-menu>

            <!--<div matPaddingSmall></div>
            <button mat-icon-button mat-stroked-button matTooltip="Progresser" [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value" *ngIf="!getDroitImputation('MPEER')" (click)="Next()" [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value">
                <mat-icon>arrow_right_alt</mat-icon>
            </button>-->
            <div matPaddingSmall></div>
        </div>
        <div *ngIf="sPage == 'courrier'" fxLayout="row" fxLayoutAlign="end center" fxFlex="33">
            <!--<button mat-button [matMenuTriggerFor]="menu" mat-stroked-button>Modèle</button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let m of modeles" (click)="selectDoc(m)">{{m?.libelle}}</button>
            </mat-menu>
            <div matPaddingSmall></div>-->
            <button mat-icon-button matTolltip="Télécharger" (click)="download()">
                <mat-icon *ngIf="!isPreview">print</mat-icon>
                <mat-icon *ngIf="isPreview">edit</mat-icon>
            </button>
            <div matPaddingSmall *ngIf="isPreview"></div>
            <button *ngIf="isPreview" mat-stroked-button mat-button color="primary" (click)="download()">
                <span>Télécharger</span>
            </button>
            <div matPaddingSmall></div>
            <button [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value"
                mat-stroked-button mat-button *ngIf="form.controls.valide.value && !isPreview" color="accent"
                (click)="GenererDocument(false)">
                <span>Rejeter</span>
            </button>

            <button mat-stroked-button
                [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value"
                *ngIf="!form.controls.valide.value && !isPreview" mat-button color="primary"
                (click)="GenererDocument(true)">
                <span>Approuver</span>
            </button>
            <!--<div matPaddingSmall *ngIf="getDroitImputation('DGH') && !demande.valide && !isPreview"></div>
            <button mat-flat-button  [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value" matTooltip="Signer le document de rejet" color="accent" *ngIf="getDroitImputation('DGH') && !demande.valide && !isPreview"  (click)="Valider()" >
                <mat-icon>draw</mat-icon><span matPaddingSmall></span><span>Signez pour Clore le dossier</span>
            </button>-->
            <button mat-stroked-button
                [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value"
                matTooltip="Signer le document" *ngIf="getDroitImputation('MMPE') && !isPreview" (click)="Valider()"
                [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value">
                <mat-icon>draw</mat-icon><span matPaddingSmall></span><span>Signez pour Clore le dossier</span>
            </button>
        </div>


    </mat-toolbar>
    <div overflowY fxFlex>
        <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner diameter="60"></mat-spinner>
            <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
        </div>
        <div fxLayout="column" fxLayaoutAlign="start center" fxFlex>


            <div [ngSwitch]="sPage" fxFlex>
                <div *ngSwitchDefault fxLayout="column" fxFlex>
                    <div fxLayout="row" fxFlex>
                        <div fxLayout="column" fxFlex>
                            <form matPadding [formGroup]="form" fxLayout="column" fxFlex>
                                <div matPadding matPaddingSmall fxLayout="column" fxFlex>


                                    <div fxLayout="column" matPadding>

                                        <mat-form-field appearance="outline">
                                            <mat-label>Type d'agrément<span>*</span></mat-label>
                                            <mat-select formControlName="typeDemande" [disabled]="true">
                                                <mat-option *ngFor="let demande of typeDemandes"
                                                    [value]="demande.libelle"> {{demande.libelle}} </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div fxLayout="column" formGroupName="distributeur" matPadding>
                                        <div fxLayout="row">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Forme juridique</mat-label>
                                                <mat-select formControlName="formeJuridique" [disabled]="true">
                                                    <mat-option *ngFor="let forme of formeJuridiques"
                                                        [value]="forme.libelle"> {{forme.libelle}} </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div matPadding></div>
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>Raison Sociale</mat-label>
                                                <input matInput formControlName="raisonSocial" autocomplete="off"
                                                    [readOnly]="isReadOnly">
                                                <mat-error *ngIf="form.get('distributeur.raisonSocial').invalid">Champ
                                                    obligatoire
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div fxLayout="row" fxLayoutAlign="start center">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>Siège social</mat-label>
                                                <input matInput formControlName="adresse" autocomplete="off"
                                                    [readOnly]="isReadOnly">
                                                <mat-error *ngIf="form.get('distributeur.adresse').invalid">Champ
                                                    obligatoire
                                                </mat-error>
                                            </mat-form-field>
                                            <div matPadding></div>
                                            <mat-form-field appearance="outline">
                                                <mat-label>Adresse postale</mat-label>
                                                <input matInput formControlName="codePostal" autocomplete="off"
                                                    [readOnly]="isReadOnly">
                                                <mat-error *ngIf="form.get('distributeur.codePostal').invalid">Champ
                                                    obligatoire
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <mat-form-field appearance="outline">
                                            <mat-label>Complement d'adresse</mat-label>
                                            <input matInput formControlName="complementAdresse" autocomplete="off"
                                                [readOnly]="isReadOnly">
                                            <mat-error *ngIf="form.get('distributeur.complementAdresse').invalid">Champ
                                                obligatoire
                                            </mat-error>
                                        </mat-form-field>

                                        <div matPadding></div>
                                        <div fxLayout="row" fxLayoutAlign="start center">
                                            <mat-form-field appearance="outline">
                                                <mat-label>CNPS</mat-label>
                                                <input matInput formControlName="cnps" autocomplete="off"
                                                    [readOnly]="isReadOnly">
                                                <mat-error *ngIf="form.get('distributeur.cnps').invalid">Champ
                                                    obligatoire</mat-error>
                                            </mat-form-field>
                                            <div matPadding></div>
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>RCCM</mat-label>
                                                <input matInput formControlName="rccm" autocomplete="off"
                                                    [readOnly]="isReadOnly">
                                                <mat-error *ngIf="form.get('distributeur.rccm').invalid">Champ
                                                    obligatoire</mat-error>
                                            </mat-form-field>
                                        </div>

                                        <mat-form-field appearance="outline">
                                            <mat-label>CC</mat-label>
                                            <input matInput formControlName="cc" autocomplete="off"
                                                [readOnly]="isReadOnly">
                                            <mat-error *ngIf="form.get('distributeur.cc').invalid">Champ
                                                obligatoire</mat-error>
                                        </mat-form-field>

                                        <div matPadding></div>
                                        <div fxLayout="row" fxLayoutAlign="start center">
                                            <mat-form-field appearance="outline">
                                                <mat-icon matSuffix>phone</mat-icon>
                                                <mat-label>Téléphone</mat-label>
                                                <input matInput formControlName="phone" autocomplete="off"
                                                    [readOnly]="isReadOnly">
                                                <mat-error *ngIf="form.get('distributeur.phone').invalid">Champ
                                                    obligatoire</mat-error>
                                            </mat-form-field>
                                            <div matPadding></div>
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-icon matSuffix>mail_outline</mat-icon>
                                                <mat-label>Mail</mat-label>
                                                <input matInput formControlName="mail" autocomplete="off"
                                                    [readOnly]="isReadOnly">
                                                <mat-error *ngIf="form.get('distributeur.mail').invalid">Champ
                                                    obligatoire</mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="!demande.isRenouvellement" matPadding matPaddingSmall fxLayout="column" fxFlex>
                                    <mat-accordion>
                                        <mat-expansion-panel expanded>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Prémiers investissements
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxLayout="column" matPadding>
                                                <div fxLayout="row">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>Nature du site</mat-label>
                                                        <input matInput formControlName="siteWord"
                                                            autocomplete="off" [readOnly]="isReadOnly">
                                                        <mat-error
                                                            *ngIf="form.get('siteWord').invalid">Champ
                                                            obligatoire
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <div matPadding></div>
                                                    <mat-form-field appearance="outline" fxFlex>
                                                        <mat-label>Lieu</mat-label>
                                                        <input matInput formControlName="regionSite"
                                                            autocomplete="off" [readOnly]="isReadOnly">
                                                        
                                                        <mat-error
                                                            *ngIf="form.get('regionSite').invalid">Champ
                                                            obligatoire
                                                        </mat-error>
                                                    </mat-form-field>

                                                </div>
                                                <div fxLayout="row">
                                                    <mat-form-field appearance="outline" fxFlex>
                                                        <mat-label>Article 9</mat-label>
                                                        <input matInput formControlName="article9"
                                                            autocomplete="off" [readOnly]="isReadOnly">
                                                        <mat-error
                                                            *ngIf="form.get('article9').invalid">Champ
                                                            obligatoire
                                                        </mat-error>
                                                    </mat-form-field>

                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>

                                <div *ngIf="demande.isRenouvellement" matPadding matPaddingSmall fxLayout="column" fxFlex>
                                    <mat-accordion>
                                        <mat-expansion-panel expanded>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Renouvelement
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                            <div fxLayout="column" matPadding>
                                                <div fxLayout="row">
                                                    <mat-form-field appearance="outline">
                                                        <mat-label>Numero du dernier agrément</mat-label>
                                                        <input matInput formControlName="pAgrement"
                                                            autocomplete="off" [readOnly]="isReadOnly">
                                                        <mat-error
                                                            *ngIf="form.get('pAgrement').invalid">Champ
                                                            obligatoire
                                                        </mat-error>
                                                    </mat-form-field>
                                                    <div matPadding></div>
                                                    <mat-form-field appearance="outline" fxFlex>
                                                        <mat-label>Date de signature du dernier agrément</mat-label>

                                                            <input matInput [matDatepicker]="picker"  formControlName="pDateSignature" autocomplete="off" [readOnly]="isReadOnly">
                                                            <mat-hint>MM/DD/YYYY</mat-hint>
                                                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                        
                                                        <mat-error
                                                            *ngIf="form.get('pDateSignature').invalid">Champ
                                                            obligatoire
                                                        </mat-error>
                                                    </mat-form-field>

                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>

                            </form>
                            <div matPadding></div>
                            <div matPaddingSmall fxLayout="column" style="width: 100%;">
                                <form matPadding [formGroup]="form" fxLayout="column" fxLayoutAlign="start center">

                                </form>
                            </div>


                            <!--<pdf-viewer [src]="reportResume" fxFlex [render-text]="true" style="display: block;" [show-all]="true"></pdf-viewer>-->
                        </div>
                        <div matPaddingSmall fxLayout="column" fxFlex>
                            <div matPaddingSmall fxLayout="column" fxFlex>
                                <div matPadding fxLayout="row">
                                    <div fxFlex></div>
                                    <button matRounded rightIcon mat-flat-button
                                        [disabled]="!getDroitImputation(form.controls.niveau.value)"
                                        (click)="cleanAnalyse()" color="primary">
                                        <span matPaddingSmall></span><span>Reprendre l'analyse</span><span
                                            matPaddingSmall></span>
                                        <mat-icon>refresh</mat-icon>
                                    </button>
                                </div>
                                <table class="div fxFlexle cdk-table table-max-width">
                                    <!-- Position Column -->
                                    <thead>
                                        <tr>
                                            <th class="mat-header-cell cdk-header-cell" align="left">Valider</th>
                                            <th class="mat-header-cell cdk-header-cell" align="left" style="">Document
                                            </th>
                                            <th class="mat-header-cell cdk-header-cell" align="left">commentaire
                                            </th>
                                            <th class="mat-header-cell cdk-header-cell" style="max-width: 30px;">
                                                Télécharger</th>
                                        </tr>
                                    </thead>
                                    <!-- Weight Column  -->
                                    <tbody>
                                        <tr *ngFor="let piece of pieces">
                                            <td class="mat-row cdk-row mat-footer-cell">
                                                <mat-checkbox [(ngModel)]="piece.valid"
                                                    [disabled]="!getDroitImputation(form.controls.niveau.value)"
                                                    (change)="onChange()">
                                                </mat-checkbox>
                                            </td>
                                            <td class="mat-row cdk-row mat-footer-cell">
                                                <div class=".mat-body-2" mat-line>{{piece.type.libelle}}</div>
                                            </td>
                                            <td class="mat-row cdk-row mat-footer-cell">
                                                <input class="comm" [(ngModel)]="piece.commentaire"
                                                    style="background: #eee; border-radius: 2px; padding: 10px; width: 90%;"
                                                    [readonly]="!getDroitImputation(form.controls.niveau.value)"
                                                    placeholder="Commentaire" autocomplete="off">
                                            </td>
                                            <td class="mat-row cdk-row mat-footer-cell">
                                                <button matTooltip="Télécharger" mat-icon-button mat-flat-button
                                                    (click)="openFile(piece)">
                                                    <mat-icon>cloud_download</mat-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div matPadding fxLayout="column" fxLayoutAlign="center center" fxFlex>
                                <div class="mat-headline-1" matPadding>Observation</div>
                                <!--<ckeditor [editor]="Editor"  formControlName="observation" format="html"  (ready)="onReady($event)"                                        [readOnly]="!getDroitImputation(form.controls.niveau.value)"
                                        placeholder="Commentaire">
                                    </ckeditor>-->

                                <textarea [(ngModel)]="demande.observation"
                                    style="width: 95%; height: 135px; padding: 1em; border-radius: 6px; background: rgb(238, 238, 238); border: none;"
                                    [readOnly]="!getDroitImputation(demande.niveau)" placeholder="Commentaire">
                                    </textarea>
                            </div>

                            <div matPaddingSmall>
                                <form matPadding [formGroup]="form" fxLayout="column">
                                    <div matPadding>
                                        <mat-divider></mat-divider>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="end center">
                                        <div class="mat-body-1" fxFlex>Statut :</div>

                                        <span class="stick-grey" *ngIf="!demande.statut">Brouillon</span>
                                        <span class="stick-orange"
                                            *ngIf="demande?.statut == '_SOUMIS_' && !demande.clientEdit">Soumis</span>
                                        <span class="stick-blue"
                                            *ngIf="demande?.statut == '_ENCOURS_' && !demande.signe && !demande.clientEdit">En
                                            cours de traitement</span>
                                        <span class="stick-green"
                                            *ngIf="demande?.valide == true && demande.signe">Validé</span>
                                        <span class="stick-red"
                                            *ngIf="demande?.valide == false && demande.signe">Rejeté</span>
                                        <span class="stick-red"
                                            *ngIf="(demande?.statut  == '_SOUMIS_' || demande?.statut  == '_ENCOURS_') && demande.clientEdit">Dossier
                                            retouné</span>
                                    </div>
                                    <div matPadding>
                                        <mat-divider></mat-divider>
                                    </div>
                                    <div class="mat-body-1" fxFlex>Observation au client :</div>
                                    <div [innerHtml]="form.controls.observationClient.value" matPadding></div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="'courrier'" fxLayout="column" fxFlex>
                    <div fxLayout="column" style="overflow-y: auto;" fxFlex>
                        <div fxLayout="row">

                            <!--<button matRounded mat-stroked-button mat-button [disabled]="!form.controls.valide.value"
                                color="accent" (click)="GenererDocument(false)">
                                <mat-icon>cancel</mat-icon><span matPaddingSmall></span><span>Génerer le courrier de
                                    rejet</span>
                            </button>
                            <div matPaddingSmall></div>
                            <button matTooltip="Imprimer" mat-stroked-button mat-icon-button mat-flat-button
                                (click)="print()">
                                <mat-icon>local_printshop</mat-icon>
                            </button>
                            <div matPaddingSmall></div>
                            <button matRounded rightIcon mat-stroked-button [disabled]="form.controls.valide.value"
                                mat-button color="primary" (click)="GenererDocument(true)">
                                <span>Génerer le projet d'arreté</span><span matPaddingSmall></span>
                                <mat-icon>check</mat-icon>
                            </button>-->
                        </div>

                        <div fxLayout="column" fxLayoutAlign="center center" fxFlex>
                            <div *ngIf="!isPreview" fxLayout="column" fxLayoutAlign="center center" fxFlex>
                                <div *ngIf="sEdit == 'arrete' && form.controls.valide.value" fxLayout="column"
                                    fxLayoutAlign="center center" fxFlex>
                                    <!-- <ckeditor [editor]="Editor" [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value"  [(ngModel)]="demande.document" (ready)="onReady($event)" fxFlex></ckeditor> -->

                                    <pdf-viewer [src]="report" [render-text]="true"
                                        style="display: block; width: 1000px; height: 100%;"
                                        [show-all]="true"></pdf-viewer>

                                </div>
                                <div *ngIf="sEdit == 'note'" fxLayout="row" fxLayoutAlign="center center" fxFlex>
                                    <ckeditor [editor]="Editor"
                                        [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value || getDroitImputation('DIRCAB') || getDroitImputation('CSP') || getDroitImputation('MMPE')"
                                        [(ngModel)]="demande.documentNote" (ready)="onReady($event)" fxFlex></ckeditor>
                                </div>
                                <div fxLayout="column" fxLayoutAlign="center center"
                                    *ngIf="sEdit == 'rejet' && !form.controls.valide.value" label="Courrier de rejet"
                                    fxFlex>
                                    <!--<ckeditor [editor]="Editor" [disabled]="!getDroitImputation(form.controls.niveau.value) || form.controls.clientEdit.value" [(ngModel)]="demande.document" (ready)="onReady($event)" fxFlex></ckeditor>-->
                                    <pdf-viewer [src]="report" [render-text]="true"
                                        style="display: block; width: 1000px; height: 100%;"
                                        [show-all]="true"></pdf-viewer>
                                </div>
                            </div>
                            <div *ngIf="isPreview" fxLayout="column" fxLayoutAlign="center center" fxFlex>
                                <div class="preview">
                                    <div id="toPrint" class="ck-content" [innerHtml]="currentDocument | safeHtml"></div>
                                </div>
                            </div>
                            <!--<pdf-viewer [src]="report" fxFlex [render-text]="true" style="display: block;" [show-all]="true"></pdf-viewer>
                         <pdf-viewer src="https://storage.googleapis.com/assets.sudcontractors.com/b9a40b29-f2a2-49a7-8a1c-7f1f58bc5b8a.pdf" fxFlex [render-text]="true" style="display: block;" [show-all]="true"></pdf-viewer>-->
                        </div>
                    </div>
                    <div fxLayout="column" *ngSwitchCase="'observation'" fxFlex>

                        <div fxLayout="row" fxFlex>
                            <div fxLayout="column" fxFlex="30" style="height: 500px; overflow-y: auto;">
                                <mat-selection-list [multiple]="false" fxFlex>
                                    <mat-list-option *ngFor="let s of demande.workflow?.suivi" (click)="open(s)">
                                        <mat-icon mat-list-icon>folder</mat-icon>
                                        <div mat-line>{{s.niveau}}</div>
                                        <div mat-line> {{s.date | date:'medium' }}... </div>
                                    </mat-list-option>
                                </mat-selection-list>
                            </div>
                            <div fxLayout="column" matPadding fxFlex>

                                <div fxLayout="row" matPadding>
                                    <mat-chip-list aria-label="Fish selection">
                                        <mat-chip>{{message?.utilisateur?.nom}}
                                            {{message?.utilisateur?.prenoms}}</mat-chip>
                                    </mat-chip-list>
                                    <div fxFlex></div>
                                    <div>{{ message.date | date:'medium' }}</div>
                                </div>
                                <div matPadding class="mat-headline">{{message.niveau}}</div>
                                <div [innerHtml]="message.commentaire" matPadding>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngSwitchCase="'observation'" fxLayout="column" fxFlex>

                    <div matPadding *ngSwitchCase="'observation'" fxFlex>
                        <div matPadding fxLayout="row" class="animate__animated animate__fadeInUp" fxFlex>
                            <div fxLayout="column" fxFlex="30" style="overflow-y: auto;" fxFlex>
                                <mat-selection-list [multiple]="false" fxFlex>
                                    <mat-list-option *ngFor="let s of demande.workflow?.suivi" (click)="open(s)">
                                        <mat-icon mat-list-icon>folder</mat-icon>
                                        <div mat-line>De {{s.responsable.organisation.code }} vers
                                            {{s.mandataire.organisation.code }}</div>
                                        <div mat-line> Le {{s.date | date:'medium' }}... </div>
                                    </mat-list-option>
                                </mat-selection-list>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="center center" matPadding fxFlex="70">

                                <mat-card fxLayout="column" fxLayoutAlign="center center"
                                    style="width: 350px; height: 180px; margin: 10px;" matPadding>
                                    <div class="mat-headline">{{message?.responsable?.nom}}
                                        {{message?.responsable?.prenoms}}</div>
                                    <div class="mat-body-2">{{message?.responsable?.organisation?.code}}</div>
                                </mat-card>

                                <div fxLayout="column" fxLayoutAlign="center center"
                                    style="width: 200px; height: 75px; margin: 0px;" matPadding>
                                    <div class="mat-headline">
                                        <hr style=" width: 150px; height: 4px; background: #ccc; border: 0;" />
                                    </div>
                                </div>

                                <mat-card fxLayout="column" fxLayoutAlign="center center"
                                    style="width: 350px; height: 180px; margin: 10px;" matPadding>
                                    <div class="mat-headline">{{message?.mandataire?.nom}}
                                        {{message?.mandataire?.prenoms}}</div>
                                    <div class="mat-body-2">{{message?.mandataire?.organisation?.code}}</div>
                                </mat-card>

                                <!--<div fxLayout="row" matPadding>
                                    <mat-chip-list aria-label="Fish selection">
                                        <mat-chip>{{message?.utilisateur?.nom}} {{message?.utilisateur?.prenoms}}</mat-chip>
                                    </mat-chip-list>
                                    <div fxFlex></div>
                                    <div>{{ message.date | date:'medium' }}</div>
                                </div>
                                <div matPadding class="mat-headline">{{message.niveau}}</div>
                                <div [innerHtml]="message.commentaire" matPadding>
                                </div>-->
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>