import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Modele } from '../../interfaces/modele.interfce';
import { Observable } from 'rxjs';

@Injectable()
export class ModeleService {

    constructor(private http: HttpClient) { }
    // Ajoute un nouvel enregistrement de ModeleEntity 
    save(modele: Modele): Observable<any> {
        return this.http.post<any>(`${environment.api}/modele/`, modele);
    }
    // Ajoute un nouvel enregistrement de ModeleEntity 
    update(modele: Modele): Observable<any> {
        return this.http.put<any>(`${environment.api}/modele/`, modele);
    }
    // Supprime un enregistrement de ModeleEntity
    delete(primaryKey: any): Observable<any> {
        return this.http.delete<any>(`${environment.api}/modele/${encodeURI(JSON.stringify(primaryKey))}`);
    }
    // Trouve tous les enregistrements de ModeleEntity
    findAll(): Observable<any> {
        return this.http.get<any>(`${environment.api}/modele/`);
    }
    query(queryParameter: any): Observable<any> {
        return this.http.get<any[]>(`${environment.api}/modele/query/${encodeURI(JSON.stringify(queryParameter))}`);
    }
    // Trouve un seul enregistrements de ModeleEntity
    findOne(primaryKey: any): Observable<any> {
        return this.http.get<any>(`${environment.api}/modele/${encodeURI(JSON.stringify(primaryKey))}`);
    }
}