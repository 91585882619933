<div fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
            <mat-icon>draw</mat-icon>
            <div matPaddingSmall></div>
            <span fxFlex="20" style="padding:10px;">Signer le document</span>
        </div>
        <div fxLayout="row" >
            <button (click)="close()"  mat-stroked-button mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <mat-card matPadding fxFlex>
        <div fxLayout="column" matPadding>
            <div fxLayout="row" fxLayoutAlign="start center">
                <qr-code id="qr" value="Yo world!" size="150" errorCorrectionLevel="M" fxFlex></qr-code>
                <img id="signature" [src]="signature.data" width="50" width="50" fxFlex/>
            </div>
            <!--<app-file-upload  placeholder="Document signé" [fichier]="documentSigne" (onUpload)="addDocument($event)"></app-file-upload>-->
            <div fxLayout="row" fxLayoutAlign="end center">
                <button matRounded rightIcon mat-flat-button (click)="Send()" [disabled]="!signature.data" color="primary">
                    <span matPaddingSmall></span><span>Signer</span><span matPaddingSmall></span>
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </mat-card>
</div>