import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NotificationService } from '../../services/database/notification.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { USER } from 'src/app/app.constant';
import { Router } from '@angular/router';
import { FComponent } from '../f/f.component';
import { MComponent } from '../m/m.component';
import { ModalService, ParamDialog } from 'src/app/core/services/modal.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

    searchText = '';
    messages: any[] = [];
    message: any = {};
    progress: { loading: boolean; label: string; } = { loading: false, label:''};

    displayedColumns: string[] = ['message','action'];
    @ViewChild(MatPaginator, {}) paginator!: MatPaginator;
    dataSource = new MatTableDataSource<Notification>([]);
    @ViewChild(MatSort, {static: true}) sort!: MatSort;

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        //@Inject(FComponent) public parentF: FComponent,
        @Inject(MComponent) private parentM: MComponent,
        private notificationService: NotificationService,
        private router: Router,
        private modal: ModalService,
        private _snackBar: MatSnackBar,

    ) { }

    ngOnInit(): void {
        this.getMessages();
    }

    getMessages(){
        this.progress = { loading: true, label: 'Chargement des cours ...' };

        this.notificationService.query( { $query: { 'proprietaire.mail': this.storage.get(USER).mail}, $orderby: { envoyeLe : -1 } } ).subscribe((result: any)=>{
            this.messages = result;
            this.message = (result.length > 0) ? result[0] : {};
            this.dataSource = new MatTableDataSource<Notification>(result);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;

            setTimeout(() => this.progress = { loading: false, label: '' }, 3000);
        });
    }

    stripHtml(html:any) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    open = (message: any) => {
        this.message = message;
        for(let i = 0; i <= this.messages.length - 1; i++){
            if(this.messages[i]._id == message._id){
                this.messages[i].lu =true;
                break;
            }
        }
        this.notificationService.update(message).subscribe((result: any)=>{
            //this.messages = result;
            this.parentM.CountNotification();
        });
        
    }


    delete(message: any) {
        this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: 'Voulez-vous vous supprimer ce message ?' })
            .afterClosed().subscribe((confirmed:any) => {
                if (confirmed) {
                    this.progress = { loading: true, label: 'suppression en cours ...' };
                    this.notificationService.delete(message._id).subscribe(
                        result => {
                            this.getMessages();
                            setTimeout(() => {
                                this._snackBar.open(`Message supprimé`, 'Fermer', { duration: 2000 });
                                this.progress = { loading: false, label: '' };
                            }, 3000);
                        },
                        error => {
                            setTimeout(() => {
                                this.progress = { loading: false, label: '' };
                                this._snackBar.open(`Impossible de supprimer le mandat`, 'Fermer', { duration: 2000 });
                            }, 3000);
                        }
                    );
                }
            }
        );
    }

    goToState(message:any) {
        this.router.navigate([`m/demande/details/${message._id}`]);
    }

    applyFilter() {
        this.dataSource.filter = this.searchText;
    }
}
