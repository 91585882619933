import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ORGANISATION, TOKEN, USER } from '../app.constant';
@Injectable({
  providedIn: 'root',
})
export class IsSignedGuard implements CanActivate {
    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService,
                private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.storage.get(USER) ? this.storage.get(USER) : null;

        if (!user) {
            this.router.navigate(['/a/signin']);
        }
        else{
            const organisation = this.storage.get(ORGANISATION) ? this.storage.get(ORGANISATION) : null;
            if(!organisation){
                console.log(route.url)
                for(let i = 0; i <= route.url.length -1; i++){
                    if(route.url[i].path === "m"){
                        this.router.navigate(['/f']);
                        break;
                    }
                }
            } 
        }
        return user ? true : false ;
    }
}

@Injectable({
    providedIn: 'root',
})
export class IsNotSignedGuard implements CanActivate {
    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService,
                private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.storage.get(USER) ? this.storage.get(USER) : null;

        if (user?.verification?.verifie) {
            const organisation = this.storage.get(ORGANISATION) ? this.storage.get(ORGANISATION) : null;
            if(!organisation) this.router.navigate(['/f']);
            else this.router.navigate(['/m']);

        }
        return true ;
    }
}
