import { Directive, ElementRef, Renderer2, HostBinding, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[overflowY]',
})
export class OverflowYDirective   implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.el.nativeElement.style.overflowY = 'auto';
    }
}
