import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { GroupeParametre } from '../../interfaces/groupeparametre.interface';

@Injectable()
export class SettingService {
    

    constructor(private http: HttpClient) { }
    // Ajoute un nouvel enregistrement de MarketerEntity 
    save(groupeParametrees: GroupeParametre[]): Observable<any> {
        return this.http.post<any>(`${environment.api}/setting/parameter`, groupeParametrees);
    }
    reset(): Observable<any> {
        return this.http.get<any>(`${environment.api}/setting/initialize`);
    }
    update(groupeParametrees: GroupeParametre[]): Observable<any> {
        return this.http.post<any>(`${environment.api}/setting/`, groupeParametrees);
    }
}
