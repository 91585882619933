<div matPadding fxLayout="column" fxLayoutAlign="center center" fxFill>

    <perfect-scrollbar fxLayout="column" fxLayoutAlign="center center" [config]="{}">
        <div fxLayout="row" fxLayoutAlign="center center" matPadding>
            <span class="mat-headline">Connexion</span>
        </div>
        <form [formGroup]="form" class="inscription-form" fxLayout="column">
            <div class="bloc-input" fxLayout="column" fxFlex>
                <mat-form-field  appearance="outline">
                    <mat-label>Login</mat-label>
                    <mat-icon matSuffix>person</mat-icon>
                    <input matInput placeholder="Adresse mail" formControlName="mail" autocomplete="off">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Mot de passe</mat-label>
                    <button mat-icon-button matSuffix (click)="showPassword = !showPassword">
                        <mat-icon>visibility_off</mat-icon>
                    </button>
                    <input matInput *ngIf="showPassword" placeholder="Mot de passe" formControlName="pass" autocomplete="off">
                    <input matInput *ngIf="!showPassword" placeholder="Mot de passe" type="password" formControlName="pass" autocomplete="off">
                </mat-form-field>
            </div>
            <div matPaddingSmall></div>
            <div class="mat-caption">Assurez-vous que vos identifiants et mots de passe sont correctes.</div>
            <div matPadding></div>
            <button type="submit" matRounded [disabled]="form.invalid" mat-flat-button color="primary"
                (click)="signin()" fxFLex>
                <div *ngIf="inProgress" class="spiner-button" fxLayout="row" fxLayoutAlign="center center"><mat-spinner
                        diameter="20"></mat-spinner></div>
                <span *ngIf="!inProgress">Se connecter</span>
            </button>

            <div matPaddingSmall></div>
            <div fxLayou="row">
                <button matRounded routerLink="/a/signup" color="primary" type="submit" mat-stroked-button mat-button fxFlex>
                    Inscrivez vous
                </button>
                <div matPaddingSmall></div>
                <button mat-icon-button matTooltip="Guide utilisateur" (click)="openHelp()" mat-stroked-button m>
                    <mat-icon>help</mat-icon>
                </button>
            </div>

        </form>
        <!-- Overlay de Connexion -->
        <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner diameter="60"></mat-spinner>
            <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
        </div>
        <!-- Fin Overlay -->
    </perfect-scrollbar>
</div>