import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize, map, startWith, mergeMapTo } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';

//import {Howl, Howler} from 'howler';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { TOKEN, FLUID, USER, LOCATION, LIVE_TREATMENT, PARAM_NOTIF_END, DECLARATION_NOTIF_END, APP_CODE, ORGANISATION, NB_MESSAGE, PASSATIONS, MENU_ITEMS } from '../../app.constant';
import { ModalService, ParamDialog } from 'src/app/core/services/modal.service';

import { Socket } from 'ngx-socket-io';
import { ThemeService } from 'src/app/core/services/theme.service';
import { ApplicationSyvalComponent } from 'src/app/modals/application-syval/application-syval.component';
import { environment } from 'src/environments/environment';
import { Organisation } from 'src/app/interfaces/organisation.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from '../../services/database/notification.service';
import { UtilisateurService } from '../../services/database/utilisateur.service';
//import { AngularFireMessaging } from '@angular/fire/messaging';
import { Utilisateur } from 'src/app/interfaces/utilisateur.interface';
import { DemandeService } from 'src/app/services/database/demande.service';
import { ProfilComponent } from 'src/app/modals/profil/profil.component';
import { ResetPasswordComponent } from 'src/app/modals/reset-password/reset-password.component';
import { PassationModalComponent } from 'src/app/modals/passation-modal/passation-modal.component';
import { PassationService } from 'src/app/services/database/passation.service';
import { Passation } from 'src/app/interfaces/passation.interface';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    selector: 'app-m',
    templateUrl: './m.component.html',
    styleUrls: ['./m.component.scss']
})
export class MComponent implements OnInit {

    public menuItems = MENU_ITEMS;

    menuIsEpingle!: boolean;
    @ViewChild(MatSidenav, {}) drawer!: MatSidenav;
    
    execution: any = { operation: { uuid: null, name: ` Aucun traitement en cours`, step: 100, allstep: 100 }, message: `Aucun traitement en cours` };
    public remoteExecution: any[] = [];
    fluid!: string;
    screenWidth!: number;
    user: any = { nom: null, prenoms: null };
    paramNotif: boolean = false;
    declarationNotif: boolean = false;
    organisation: Organisation = <Organisation><unknown>{ code: null }

    searchControl = new FormControl();
    places!: Observable<any>;
    isLoading = false;

    nb: number = 0;
    nbDemande: number = 0;
    progressRate = 0;
    organisations: any[] = [];

    //
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        //private socket: Socket,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private modal: ModalService,
        private utilisateurService: UtilisateurService,
        public dialog: MatDialog,
        private notificationService: NotificationService,
        private demandeService: DemandeService,
        private _snackBar: MatSnackBar,
        //private afMessaging: AngularFireMessaging,
        public passationService: PassationService

    ) { }

    ngOnInit() {
        this.organisation = this.storage.get(ORGANISATION) ? this.storage.get(ORGANISATION) : <Organisation><unknown>{ code: null };
        //this.nb = this.storage.get(NB_MESSAGE);
        this.screenWidth = window.innerWidth;
        window.onresize = () => { this.screenWidth = window.innerWidth; };
        this.user = this.storage.get(USER);
        //this.getEventSocket();
        this.CountNotification();
        this.CountDemande();
        this.loadPassations();

        //this.requestPermission();
        //this.listen();


    }

    getOrganisation = (code: string) => {
        let lib;
        for(let i = 0; i <= this.organisations.length - 1; i++){
            if(this.organisations[i].code == code){
                lib = this.organisations[i].libelle;
                break;
            }
        }
        return lib;
    }

    /*  getEventSocket() {
          this.socket.connect();
          //this.socket.emit('message', 'test');
          this.socket.on('notification', notification => {
              // resultata ici
              if(notification.proprietaire.mail == this.storage.get(USER).mail){
                  this._snackBar.open(notification.objet, 'OK', { duration: 2000 });
                  this.CountNotification();
              }
          });
      }*/

    CountNotification = () => {
        this.notificationService.aggregate(
            [
                {
                    $match: { $and: [{ 'proprietaire.mail': this.storage.get(USER).mail }, { lu: { $eq: false } }] }
                },
                { $count: "nb" }
            ]
        ).subscribe(
            result => {
                if (result.length > 0) {
                    const nb = result[0];
                    this.nb = nb.nb;
                }
                else {
                    this.nb = 0;

                }

                //this.storage.set(NB_MESSAGE, nb.nb);
            }
        );
    }


    CountDemande = () => {
        this.demandeService.aggregate(
            [
                {
                    $match: { statut:"_SOUMIS_" }
                },
                { $count: "nb" }
            ]
        ).subscribe(
            result => {
                if (result.length > 0) {
                    const nb = result[0];
                    this.nbDemande = nb.nb;
                }
                else {
                    this.nbDemande = 0;

                }

                //this.storage.set(NB_MESSAGE, nb.nb);
            }
        );
    }


    goToState(root: string) {
        this.router.navigate([root]);
    }

    displayFn(place: any) {
        if (place) { return place.display_name; }
    }


    detailLog(element:any) {

    }

    Deconnexion() {
        this.modal.bottomConfirm(<ParamDialog>{ title: 'Confirmation', message: 'Voulez-vous vous déconnecter ?' })
            .afterDismissed().subscribe((confirmed: any) => {
                if (confirmed) {
                    this.storage.remove(USER);
                    this.storage.remove(DECLARATION_NOTIF_END);
                    this.storage.remove(PARAM_NOTIF_END);
                    this.storage.remove(ORGANISATION);
                    this.router.navigate(['/a/signin']);
                }
            });
    }
    openSyvalApp() {
        const dialogRef = this.dialog.open(ApplicationSyvalComponent, {
            width: '400px',
            height: '500px',
            disableClose: false,
            data: {}
        }).afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.code != APP_CODE) {
                    this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: `Voulez-vous quitter l'application ?` }).afterClosed().subscribe((confirmed:any) => {
                        if (confirmed) window.location.href = `http://${environment.targetUri(result.url)}/a/authentification/${this.storage.get(TOKEN).token}`;
                    });
                }
                else {
                    location.reload();
                }
            }
        });
    }
/*
    requestPermission() {
        this.afMessaging.requestToken.pipe(mergeMapTo(this.afMessaging.tokenChanges)).subscribe(
            (token) => {
                console.log('Permission granted! Save to the server!', token);
                // On met à jour le token du destinataire
                const utilisateur: Utilisateur = this.storage.get(USER);
                utilisateur.fcmToken = token;
                this.utilisateurService.update(utilisateur).subscribe((result) => null);
                this.listen();
            },
            (error) => {
                console.error(error);
            },
        );
    }
    */
/*
    listen() {
        this.afMessaging.messages.subscribe((message: any) => {
            if (message) {
                var sound = new Howl({ src: ['../../../assets/swiftly.ogg'] });
                sound.play();

                this.CountNotification();
                this._snackBar.open(message.notification.body, 'voir', { duration: 4000 }).afterDismissed().subscribe(info => {
                    if (info.dismissedByAction === true) {
                        // your code for handling this goes here
                        this.goToState('/m/message')
                    }
                });
            }
        });
    }
*/
    loadPassations() {
        this.passationService.available(this.user._id).subscribe(passations => {
            this.storage.set(PASSATIONS, passations)
        });
    }

    openProfil() {
        const dialogRef = this.dialog.open(ProfilComponent, {
            width: '800px',
            //height: '700px',
            disableClose: false,
            data: {}
        }).afterClosed().subscribe((result: any) => {
            
        });
    }
    
    openResetPassword() {
        const dialogRef = this.dialog.open(ResetPasswordComponent, {
            width: '400px',
            disableClose: false,
            data: {}
        }).afterClosed().subscribe((result: any) => {
            
        });
    }

    openPassationApp() {
        const dialogRef = this.dialog.open(PassationModalComponent, {
            width: '700px',
            height: '500px',
            disableClose: true,
            data: {}
        }).afterClosed().subscribe((result: any) => {
           
        });
    }

    public sideCallBack = ($event: any) => {
        this.menuIsEpingle = $event;
        if (this.menuIsEpingle) {
            this.drawer.mode = 'side'
        }
        else {
            this.drawer.close();
            setTimeout(() => { this.drawer.open(); }, 500);
        }

    }

}
