import { Component, OnInit, ViewChild } from '@angular/core';
import { Like } from '../../core/classes/typeorm';

import { MatDialog } from '@angular/material/dialog';
import { ModalService, ParamDialog } from '../../core/services/modal.service';

import { ValeurService } from '../../services/settings/valeur.service';


import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ValeurFormComponent } from 'src/app/modals/valeur-form/valeur-form.component';
import { Valeur } from 'src/app/interfaces/valeur.interface';


@Component({
    selector: 'app-valeur',
    templateUrl: './valeur.component.html',
    styleUrls: ['./valeur.component.scss']
})
export class ValeurComponent implements OnInit {


    @ViewChild(MatPaginator, {}) paginator!: MatPaginator;
    displayedColumns: string[] = ['code', 'label', 'description', 'values', 'action'];
    values!: any[];
    dataSource = new MatTableDataSource<any>([]);
    inProgress = false;
    inSearchProgress = false;

    searchText = '';

    @ViewChild(MatSort, { static: true }) sort!: MatSort;

    constructor(
        public dialog: MatDialog,
        private modal: ModalService,
        private valueService: ValeurService
    ) { }

    ngOnInit() {
        this.load();
    }

    load() {
        this.inProgress = true; // Démarre le loader
        this.valueService.query({}).subscribe((result: any) => {
            this.inProgress = false; // stop le loader

            this.dataSource = new MatTableDataSource<any>(result);
            this.dataSource.sort = this.sort;

            this.dataSource.paginator = this.paginator;

        });

    }

    search(searchText: string) {

    }


    delete(value: Valeur) {
        this.modal.bottomConfirm(<ParamDialog>{ title: 'Confirmation', message: 'Voulez-vous supprimer ?' })
            .afterDismissed().subscribe((confirmed: any) => {
                if (confirmed) {
                    this.valueService.delete({ id: value._id }).subscribe((result: any) => {
                        this.load();
                    });
                }
            });
    }

    applyFilter() {
        this.dataSource.filter = this.searchText;
    }


    newValue(element:any) {
        const dialogRef = this.dialog.open(ValeurFormComponent, {
            width: '700px',
            disableClose: true,
            data: element
        }).afterClosed().subscribe((result: any) => {
            if (result) {
                this.load();
            }
        });
    }



}
