<mat-card fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar color="primary" class="mat-elevation-z0">
        <button mat-icon-button>
            <mat-icon>person</mat-icon>
        </button>
        <div fxlayout="column" fxFlex="20" style="padding:10px;">
            <div>Gérez votre compte</div>
        </div>
        <div fxFlex></div>
        <div fxLayout="row">
            <button (click)="close()" mat-stroked-button mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <mat-card fxLayout="column" matPadding fxFlex>
        <!-- Overlay de Connexion -->
        <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner diameter="60"></mat-spinner>
            <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
        </div>
        <!-- Fin Overlay -->
        <div fxLayout="row">
            <div matPadding fxLayout="column" fxLayoutAlign="start" fxFlex="45">
                <mat-list fxFlex>
                    <div mat-subheader>Gestion de profils</div>
                    <mat-list-item (click)="mode = '_PROFIL_'">
                        <mat-icon mat-list-icon>folder</mat-icon>
                        <div mat-line>Paramètres personnelss</div>
                        <div mat-line> Modifiez vos donnée personnels </div>
                        
                        <mat-action-list>
                            <button mat-icon-button matTooltip="Ouvrir" (click)="mode = '_PROFIL_'">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </mat-action-list>

                    </mat-list-item>
                    <mat-list-item *ngIf="organisation" (click)="mode = '_SIGNATURE_'">
                        <mat-icon mat-list-icon>gesture</mat-icon>
                        <div mat-line>Signature</div>
                        <div mat-line> Enregistrer votre signature </div>

                        <mat-action-list>
                            <button mat-icon-button matTooltip="Ouvrir" (click)="mode = '_PASSWORD_'">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </mat-action-list>

                    </mat-list-item>
                    <mat-list-item (click)="mode = '_PASSWORD_'">
                        <mat-icon mat-list-icon>more_horiz</mat-icon>
                        <div mat-line>Mot de passe</div>
                        <div mat-line> Changez de mot de passe </div>

                        <mat-action-list>
                            <button mat-icon-button matTooltip="Ouvrir" (click)="mode = '_PASSWORD_'">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </mat-action-list>

                    </mat-list-item>
                    <div mat-subheader *ngIf="!organisation">Entreprise</div>
                    <mat-list-item (click)="mode = '_COUPLAGE_'" *ngIf="!organisation">
                        <mat-icon mat-list-icon>museum</mat-icon>
                        <div mat-line>Couplage </div>
                        <div mat-line> Associez vous à un compte entreprise </div>

                        <mat-action-list>
                            <button mat-icon-button matTooltip="Ouvrir" (click)="mode = '_COUPLAGE_'">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </mat-action-list>

                    </mat-list-item>
                    <div mat-subheader *ngIf="organisation">Gestion des mandats</div>
                    <mat-list-item *ngIf="organisation">
                        <mat-icon mat-list-icon>6_ft_apart</mat-icon>
                        <div mat-line>Mes passations</div>
                        <div mat-line> Parametrez vos mandataires </div>

                        <mat-action-list>
                            <button mat-icon-button matTooltip="Ouvrir" (click)="mode = '_MANDAT_'">
                                <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </mat-action-list>

                    </mat-list-item>
                    
                </mat-list>
            </div>
            <div *ngIf="mode == '_SIGNATURE_'" matPadding class="inscription-form mat-padding" fxFlex fxLayout="column">
                <div matPadding style="font-weight: 100; font-size: 20px;">Enregistrez votre signature</div>
                <form matPadding fxLayout="column" fxFlex>
                    <div fxLa yout="column"  fxLayoutAlign="center center"  fxFlex>
                        <div fxLayout="column" fxFlex>
                              <div><img class="image-preview" src="{{signature.data}}" height="140"/></div>
                          </div>
                        <div class="info-file" fxLayout="row" fxLayoutALign="end center">
                      </div>  
                    </div>

                    <div matPadding  fxLayout="row" fxLayoutAlign="start center">
                        <button matRounded mat-button color="accent" (click)="cancelPassword()">
                            <mat-icon>cancel</mat-icon><span matPaddingSmall></span><span>Annuler</span>
                        </button>
                        <div matPaddingSmall></div>
                        <button matRounded mat-button mat-stroked-button (click)="selectFile()">
                            <mat-icon>attach_file</mat-icon><span matPaddingSmall></span><span>Charger</span>
                        </button>
                        <div matPaddingSmall></div>
                        <button [disabled]="!signature.data" matRounded rightIcon  mat-flat-button (click)="saveSignature()" color="primary"fxFlex>
                          <span matPaddingSmall></span><span>Valider</span><span matPaddingSmall></span>
                          <mat-icon >arrow_right_alt</mat-icon>
                        </button>
          
                    </div>
                </form>
            </div>
            <div matPadding fxLayout="column" fxLayoutAlign="start" fxFlex>
                <form *ngIf="mode == '_PROFIL_'" matPadding [formGroup]="iForm" class="inscription-form mat-padding" fxFlex fxLayout="column"
                    fxLayoutAlign="start" fxFlex>
                    <div matPadding style="font-weight: 100; font-size: 20px;">Paramètres personnels</div>
                    <div matPadding fxLayout="column" style="width: 400px;">
                        <div fxLayout="row" fxLayoutAlign="start center"></div>
                        <mat-form-field  appearance="outline">
                            <mat-label>Nom</mat-label>
                            <mat-icon matSuffix>person</mat-icon>
                            <input matInput placeholder="Nom" formControlName="nom" autocomplete="off">
                        </mat-form-field>
                        <mat-form-field  appearance="outline" fxFlex>
                            <mat-label>Prenoms</mat-label>
                            <mat-icon matSuffix>person</mat-icon>
                            <input matInput placeholder="Entrez votre prénoms" formControlName="prenoms" autocomplete="off">
                        </mat-form-field>
                        <mat-form-field  appearance="outline" fxFlex>
                            <mat-label>mail</mat-label>
                            <mat-icon matSuffix>alternate_email</mat-icon>
                            <input matInput placeholder="Adresse mail" formControlName="mail" autocomplete="off">
                        </mat-form-field>
                        <mat-form-field  appearance="outline" fxFlex>
                            <mat-label>Téléphone</mat-label>
                            <mat-icon matSuffix>phone</mat-icon>
                            <input matInput placeholder="Numéro de téléphone" formControlName="phone" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div matPadding fxLayout="row" fxLayoutAlign="center center">
                        <div matPaddingSmall matRounded fxFlex></div>
                        <button matRounded mat-button color="accent">
                            <mat-icon>cancel</mat-icon><span matPaddingSmall></span><span>Annuler</span>
                        </button>
                        <div matPaddingSmall></div>
                        <button [disabled]="iForm.invalid" matRounded rightIcon mat-flat-button (click)="save()"
                            color="primary">
                            <span matPaddingSmall></span><span>Valider</span><span matPaddingSmall></span>
                            <mat-icon>arrow_right_alt</mat-icon>
                        </button>
                    </div>
                </form>
                <form *ngIf="mode == '_PASSWORD_'" matPadding [formGroup]="pForm" class="inscription-form mat-padding" fxFlex fxLayout="column" fxFlex>
                    <div matPadding style="font-weight: 100; font-size: 20px;">Modifiez votre mot de passe</div>
                    <div matPadding fxLayout="column">
                        <div fxLayout="column">
                            <mat-form-field >
                                <mat-label>Mot de passe actuel</mat-label>
                                <mat-icon matSuffix>more_horiz</mat-icon>
                                <input matInput type="password"  placeholder="Entrez votre mot de passe actuel" formControlName="pass"  autocomplete="off">
                            </mat-form-field>
                        </div>
                        <div class="mat-padding-small"></div>
                        <div fxLayout="column">
                            <mat-form-field  fxFlex>
                                <mat-label>Nouveau mot de passe</mat-label>
                                <mat-icon matSuffix>more_horiz</mat-icon>
                                <input matInput type="password"  placeholder="Entrez votre nouveau mot de passe" formControlName="newpass" autocomplete="off">
                            </mat-form-field>
                            <mat-form-field  fxFlex>
                                <mat-label>Confirmez le mot de passe</mat-label>
                                <mat-icon matSuffix>more_horiz</mat-icon>
                                <input matInput  type="password" placeholder="Confirmez le mot de passe" formControlName="cpass"  autocomplete="off">
                                <mat-error *ngIf="pForm.controls.newpass.value != pForm.controls.cpass.value">La confirmation du nouveau mot de passe est incorrecte</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div matPadding  fxLayout="row" fxLayoutAlign="center center"  fxFlex>
                
                        <div matPaddingSmall matRounded fxFlex></div>
                        
                        <button matRounded mat-button color="accent" (click)="cancelPassword()">
                            <mat-icon>cancel</mat-icon><span matPaddingSmall></span><span>Annuler</span>
                        </button>
                        <div matPaddingSmall></div>
                        <button [disabled]="pForm.invalid" matRounded rightIcon  mat-flat-button (click)="savePassword()" color="primary">
                          <span matPaddingSmall></span><span>Valider</span><span matPaddingSmall></span>
                          <mat-icon >arrow_right_alt</mat-icon>
                        </button>
          
                    </div>
                </form>
                <div *ngIf="mode == '_COUPLAGE_'" matPadding [formGroup]="iForm" class="inscription-form mat-padding" fxFlex fxLayout="column">
                    <div matPadding style="font-weight: 100; font-size: 20px;">Couplage</div>
                    <div matPadding *ngIf="entreprise._id"> Vous êtes en ce moment couplé à <span style="font-weight: 200; font-size: 15px;">{{entreprise.raisonSocial}}</span>
                    </div>
                    <div matPadding fxLayout="row" fxLayoutAlign="start center">
                        <button [disabled]="!entreprise._id" matRounded mat-button mat-flat-button color="accent" (click)="dissocier()">
                            <mat-icon>cancel</mat-icon><span matPaddingSmall></span><span>Dissocier</span>
                        </button>
                        <div matPaddingSmall></div>
                        <button [disabled]="entreprise._id" (click)="seCoupler()" matRounded rightIcon mat-flat-button color="primary" fxFlex>
                            <span matPaddingSmall></span><span>Associer</span><span matPaddingSmall></span>
                            <mat-icon>arrow_right_alt</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="mode == '_MANDAT_'" matPadding [formGroup]="iForm" class="inscription-form mat-padding" fxFlex fxLayout="column">
                    <div matPadding style="font-weight: 100; font-size: 20px;">Parametrage des passations</div>
                    <div matPadding>
                        <button (click)="openPassationApp()" matRounded rightIcon mat-flat-button color="primary" fxFlex>
                            <mat-icon>6_ft_apart</mat-icon>
                            <span matPaddingSmall></span><span>Ouvrir le gestionnaire des passations</span><span matPaddingSmall></span>
                        </button>
                    </div>
                    <div matPadding style="font-weight: 100; font-size: 20px;">Mes mandats</div>
                    <div fxLayout="column" style="height: 280px; overflow-y: auto;" fxFlex>
                        
                        <table mat-table matSort [dataSource]="dataSource" fxFlex>
                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                            <!-- Position Column -->

                            <ng-container matColumnDef="utilisateur">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <div> {{element.type | uppercase }}  DE {{element?.responsable?.nom}} {{element?.responsable?.prenoms}} </div>
                                    <div> ENTRE {{element.debut | date : 'dd/MM/yyyy'}} ET <span *ngIf="!element.fin"> Indéterminé </span>
                                        <span>{{element.debut | date : 'dd/MM/yyyy'}}</span>
                                    </div>
                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                </div>
                
            </div>            
        </div>


    </mat-card>
</mat-card>