import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

  import { Valeur } from '../../interfaces/valeur.interface';
  import { ValeurService } from '../../services/settings/valeur.service';

import { ModalService, ParamDialog } from '../../core/services/modal.service';


@Component({
  selector: 'app-valeur-form',
  templateUrl: './valeur-form.component.html',
  styleUrls: ['./valeur-form.component.scss']
})
export class ValeurFormComponent implements OnInit {

    constructor(
            public dialogRef: MatDialogRef<ValeurFormComponent>,
            @Inject(MAT_DIALOG_DATA) public data: any,
            private modal: ModalService,
            private valueService: ValeurService,
            private _snackBar: MatSnackBar,
            public fb: FormBuilder,
    ) { }
// Reference Component in the .html view
@ViewChild('scrollable') private scrollable!: ElementRef;

public form!: FormGroup;
public inProgress: Boolean = false;
public isLinear: Boolean = false;

ngOnInit() {
    this.buildForm();
    this.formPatchValue(this.data);
}
// Construction du formulaire
buildForm() {
    this.form = this.fb.group({

            _id: new FormControl(null),
            code: new FormControl(null, [Validators.required]),
            libelle: new FormControl(null, [Validators.required]),
            description: new FormControl(null),
            valeurs: this.fb.array([
        ])
    });
}
// Remplissage du formulaire avec les données initiales
formPatchValue(value: any) {
    if (value !== undefined) {
        if (value.valeurs !== undefined) {
            
            value.valeurs.forEach((element:any) => {
                (this.form.get('valeurs') as FormArray).push(this.fb.group(
                    {
                        _id: new FormControl(null, []),
                        code: new FormControl(null, [Validators.required]),
                        libelle: new FormControl(null, [Validators.required])
                    }
                ));
            });
        }
    }
    this.form.patchValue(value);
}
// Ajout d'une nouvelle valeur dans la lise
add() {
    const { code } = this.form.value;
    (this.form.get('valeurs') as FormArray).push(this.fb.group(
        {
            _id: new FormControl(null, []),
            code: new FormControl(code, [Validators.required]),
            libelle: new FormControl(null, [Validators.required])
        }
    ));
    this.scrollable.nativeElement.scrollTop = this.scrollable.nativeElement.scrollHeight;
}
// Supresse de valeur dans la lise
delete(index:any) {
    (this.form.get('valeurs') as FormArray).removeAt(index);
}
// Soumission du formulaire pour la sauvegarde d'un type de valeur et de ses valeurs
save(): void {
    this.inProgress = true;
    const value: Valeur  = this.form.value;
    console.log(value)
    const service = (!value._id) ? this.valueService.save(value) : this.valueService.update(value)
    service.subscribe((result: any) => {
        this.inProgress = false;
        if (result) {
            this._snackBar.open('Type de valeur sauvegardé', 'Dance', { duration: 2000 });
            this.dialogRef.close(result);
        } else {
            this._snackBar.open('Impossible d\'enregistrer avec succès', 'Dance', { duration: 2000 });
        }
    });
}
close() {
    this.dialogRef.close();
}

}
