import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FileUpload } from '../interfaces/fileupload.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class FileUploadService {

    constructor(private http: HttpClient) { }
    // Ajoute un nouvel enregistrement de type
    upload(fileUpload: FileUpload, file: File): Observable<any> {
        const formData = new FormData();
        formData.append('file', file);
        return this.http.post<any>(`${environment.api}/file/upload`, formData);
    }

    // Trouve un seul enregistrements de any
    findOne(primaryKey: any): Observable<any> {
        console.log(primaryKey);
        return this.http.get<any>(`${environment.api}/file/${encodeURI(JSON.stringify(primaryKey))}`);
    }
    getFileUri(primaryKey: any): string{
        return `${environment.api}/file-upload/${encodeURI(JSON.stringify(primaryKey))}`;
    }
}
