<div fxFill>
    <mat-toolbar color="primary"class="mat-elevation-z4 mat-toolbar-white">
        <button  mat-icon-button>
            <mat-icon>money</mat-icon>
        </button>
        <div fxlayout="column" fxFlex="20" style="padding:10px;">
            <div>Liste des valeurs</div>
        </div>
        <div fxFlex></div>
        <div fxLayout="row">
          <button mat-stroked-button color="accent" matRounded  matTooltip="Actualiser" (click)="newValue({})" >
              <mat-icon>add</mat-icon> <span> Nouvel valeur</span>
          </button>
          <div matPaddingSmall></div>
        
          <button mat-stroked-button  matTooltip="Actualiser" (click)="load()" mat-icon-button>
              <mat-icon>refresh</mat-icon>
          </button>
          <div matPaddingSmall></div>
          <div class="bloc-search mat-stroked-button">
              <input [(ngModel)]="searchText" type="text" (keyup)="applyFilter()"/>
              <button (click)="search(searchText)" color="accent"  matTooltip="Lancer la recherche"  mat-icon-button>
                  <mat-icon>search</mat-icon>
              </button>
          </div>
          <div matPaddingSmall></div>
            <!--<button mat-stroked-button mat-icon-button (click)="openForm()"  matTooltip="Nouvelle fidélisation">
                <mat-icon>add</mat-icon>
            </button>-->
        </div>
    </mat-toolbar>
  <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
<div fxLayout="column">
    
        <table mat-table class="table-max-width" matSort  [dataSource]="dataSource" fxFlex>
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
            <!-- Position Column  'code', 'label', 'description', 'values', 'action'-->
            <ng-container matColumnDef="code">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Code </th>
              <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="label">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Les valeurs </th>
                <td mat-cell *matCellDef="let element"> <span *ngIf="element.label">{{element.libelle }}</span> </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="description">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Description </th>
              <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>
          
            <!-- Name Column  -->
            <ng-container matColumnDef="values">
              <th mat-header-cell mat-sort-header *matHeaderCellDef> Valeurs </th>
              <td mat-cell *matCellDef="let element"> {{element.valeurs.length | number }} </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th  mat-header-cell *matHeaderCellDef > action </th>
              <td mat-cell *matCellDef="let element"  class="action-column">
                <div fxLayout="row" >
                  <button mat-icon-button  [matMenuTriggerFor]="menu" >
                      <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" >
                      <button mat-menu-item  (click)="newValue(element)"><mat-icon>edit</mat-icon> Modifier</button>
                      <button mat-menu-item (click)="delete(element)"><mat-icon>delete</mat-icon> Supprimer </button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>
          
            <tr mat-header-row  *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
   
        
      
        </div>
        
</div>