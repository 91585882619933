import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { APPLICATIONS } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/core/services/modal.service';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
    selector: 'app-application-syval',
    templateUrl: './application-syval.component.html',
    styleUrls: ['./application-syval.component.scss']
})
export class ApplicationSyvalComponent implements OnInit {

    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};

    applications = [];
    searchText!: string;
    constructor(
        public dialogRef: MatDialogRef<ApplicationSyvalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modal: ModalService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,

    ) { }

    ngOnInit(): void {
        this.applications = this.storage.get(APPLICATIONS);
    }
    openApp(app:any) {
        this.dialogRef.close(app);
    }
    applyFilter() {
        //this.dataSource.filter = this.searchText;
      }
  

}
