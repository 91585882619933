<!--The content below is only a placeholder and can be replaced.-->
<mat-card fxLayout="column"  class="defaultTheme"  fxFill>
    <mat-toolbar color="primary" color="primary" class="mat-elevation-z0">
      <div fxLayout="row" fxLayoutAlign="start center">
          <!--<button (click)="drawer.toggle()" mat-icon-button>
              <mat-icon>view_headline</mat-icon>
          </button>-->
          <div matPaddingSmall></div>
          <div fxlayout="column" style="padding:10px;">
              <div><img src="../../../assets/logo_1.png" height="40"/></div>
          </div>
          <div>/ AGREMENT <span> - {{ entreprise?.raisonSocial | uppercase }}</span></div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
        
      </div>
      <div fxLayout="row">
        <div matPaddingSmall></div>
        <button  mat-stroked-button (click)="goToState('/f/souscription/list')"  mat-button>
            <mat-icon >home</mat-icon>
            <span matPaddingSmall></span>
            <span>Accueil</span>
        </button>
        <div matPaddingSmall></div>
        <button color="accent"  mat-flat-button (click)="goToState('/f/souscription/form')"  mat-button>
            <mat-icon >close_fullscreen</mat-icon>
            <span matPaddingSmall></span>
            <span>Demander un agrément</span>
        </button>
          <div matPaddingSmall></div>
          <button class="mat-button-rounded"  (click)="openProfil()"  mat-button>
              <mat-icon>person</mat-icon>
              <span matPaddingSmall></span>
              <span>{{user.nom}} {{user.prenoms}}</span>
          </button>
          <div matPaddingSmall></div>
            <button mat-icon-button color="warn" [matMenuTriggerFor]="menuNotif">
                <mat-icon *ngIf="(nb) > 0" [matBadge]="(nb) |number: '1.0-0'" matBadgeColor="warn">notifications</mat-icon>
                <mat-icon *ngIf="(nb) == 0">notifications</mat-icon>
            </button>
            <button mat-icon-button  (click)="showInfoline()" >
              <mat-icon>call</mat-icon>
            </button>
          <div matPaddingSmall></div>

            <mat-menu #menuNotif="matMenu">
                <button mat-menu-item matTooltip="Applications SYVAL" routerLink="/f/message" >
                    <mat-icon *ngIf="nb  > 0" [matBadge]="nb|number: '1.0-0'" >message</mat-icon>
                    <mat-icon *ngIf="nb  == 0" >message</mat-icon>
                    Boite de reception
                </button>
            </mat-menu>
          <button mat-icon-button  [matMenuTriggerFor]="menu" >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" >
            <button  mat-menu-item class="mat-small" (click)="openHelp()" ><mat-icon>help</mat-icon>Guide d'utilisation</button>
            <mat-divider></mat-divider>
            <button  mat-menu-item class="mat-small" (click)="Deconnexion()" ><mat-icon>exit_to_app</mat-icon> Se déconnecter </button>
          </mat-menu>
      </div>
  </mat-toolbar>
  <div fxLayout="row" class="route"  fxFlex>

    <mat-sidenav-container fxFill>
      <!--<mat-sidenav opened class="mat-elevation-z4"  position="start" [mode]="(screenWidth > 840) ? 'side' : 'over'" #drawer>
        <div class="menu-left"  fxLayout="column">
            <div fxFlex>
                <mat-divider></mat-divider>
                <div class="mat-padding">
                    <span class="mat-subheading-2">Mes Demandes</span>
                </div>
                <button gRole="history.treatment" class="menu-button" (click)="goToState('/f/souscription/list')"  mat-button >
                    <mat-icon>content_paste</mat-icon>
                    <span>Demande d'agrément</span> <span matPaddingSmall [matBadge]="nbDemande|number: '1.0-0'"></span>
                </button>
            </div>

            <div fxFlex>
                <mat-divider></mat-divider>
                <div class="mat-padding">
                    <span class="mat-subheading-2">Edition</span>
                </div>
                <button class="menu-button" (click)="goToState('/f/demande-valid')"  mat-button >
                    <mat-icon>content_paste</mat-icon>
                    <span>Demande Validées</span> <span matPaddingSmall></span>
                </button>

                <button class="menu-button" (click)="goToState('/f/demande-cours')"  mat-button >
                    <mat-icon>content_paste</mat-icon>
                    <span>Demande en cours</span> <span matPaddingSmall></span>
                </button>
            </div>
            <div matPadding>
                <i>En cas de besoin d'assistance, veuillez  contacter le +225 0506147624</i>
            </div>

      </div>
      </mat-sidenav>-->

      <mat-card fxFill>
          <router-outlet></router-outlet>
      </mat-card>
    </mat-sidenav-container>
  </div>
</mat-card>
