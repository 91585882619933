import { Directive, ElementRef, Renderer2, HostBinding, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[leftIcon]',
})
export class LeftIconDirective   implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.el.nativeElement.style.padding = '0px 14px 0px 8px';
    }
}
