import { Component, OnInit, ViewChild, Inject, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize, map, startWith, mergeMapTo } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { ModalService, ParamDialog } from 'src/app/core/services/modal.service';
import { Socket } from 'ngx-socket-io';
import { ThemeService } from 'src/app/core/services/theme.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';

/**
 * Node for to-do item
 */
export class TodoItemNode {
    children!: TodoItemNode[];
    item!: string;
}
  
/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
    item!: string;
    level!: number;
    expandable!: boolean;
}
  
/**
 * The Json object for to-do list data.
*/

  
const TREE_DATA = {
    Root: {
      Groceries: {
        'Almond Meal flour': null,
        'Organic eggs': null,
        'Protein Powder': null,
        Fruits: {
          Apple: null,
          Berries: ['Blueberry', 'Raspberry'],
          Orange: null
        }
      },
      Reminders: {
        'Cook dinner': null,
        'Read the Material Design spec': null,
        'Upgrade Application to Angular': null,
        Fruits: {
          Apple: null,
          Berries: ['Orange', 'Mango'],
          Orange: null
        }
      },
      Reminders1: {
        'Cook dinner 1': null,
        'Read the Material Design spec 1': null,
        'Upgrade Application to Angular 1': null,
        Fruits: {
          Apple: null,
          Berries: ['Grapes', 'Pomegranate'],
          Orange: null
        }
      },
      Reminders2: {
        'Cook dinner 2': null,
        'Read the Material Design spec 2': null,
        'Upgrade Application to Angular 2': null,
        Fruits: {
          Apple: null,
          Berries: ['Grapes1', 'Pomegranate1'],
          Orange: null
        }
      },
      Reminders3: [
        'Cook dinner 3',
        'Read the Material Design spec 3',
        'Upgrade Application to Angular 3',
      ]
    }
  };

@Component({
    selector: 'app-tree-view',
    templateUrl: './tree-view.component.html',
    styleUrls: ['./tree-view.component.scss'],
    animations: []
})
export class TreeViewComponent implements OnInit {

    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap = new Map<any, any>();
    searchString = ''
    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap = new Map<any, any>();
    /** A selected parent node to be inserted */
    selectedParent: any = null;
    /** The new item's name */
    newItemName = '';
    treeControl!: FlatTreeControl<any>;
    treeFlattener!: MatTreeFlattener<any, any>;
    dataSource!: MatTreeFlatDataSource<any, any>;
    /** The selection for checklist */
    checklistSelection = new SelectionModel<any>(true /* multiple */);

    @Output() callBack = new EventEmitter<any>();

    //dataChange = new BehaviorSubject<any[]>([]);
    @Input() data: any[] = [];

    //get data(): TodoItemNode[] { return this.dataChange.value; }

    constructor () { 
        this.initializeTree();
    }
    ngOnInit = () => {
        this.initializeTree();
        
    }

    /*///////////////////////////////////////////////////////////*/

    private initializeTree = () => {

        
        //const d = this.buildFileTree(this.data, 0);
        //this.dataChange.next(d);
//        console.log(TREE_DATA, d)
        //this.dataSource.data = this.data;

        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<any>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


        /*this.dataChange.subscribe(data => {
            this.dataSource.data = data;
            this.treeControl.expand(this.treeControl.dataNodes[0])
          });*/
        //this.database.dataChange.subscribe(data => this.dataSource.data = data);
    }

    getLevel = (node: any) => node.level;
    isExpandable = (node: any) => node.expandable;
    getChildren = (node: any): any[] => node.children;
    hasChild = (_: number, _nodeData: any) => _nodeData.expandable;
    hasNoContent = (_: number, _nodeData: any) => _nodeData.item === '';

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: any, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.item === node.item
            ? existingNode
            : <any>{} ;
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.expandable = !!node.children?.length;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    // search filter logic start
    filterLeafNode(node: any): boolean {
        if (!this.searchString) {
            return false
        }
        return node.item.toLowerCase()
            .indexOf(this.searchString?.toLowerCase()) === -1

    }

    filterParentNode(node: any): boolean {

        if (
            !this.searchString ||
            node.item.toLowerCase().indexOf(this.searchString?.toLowerCase()) !==
            -1
        ) {
            return false
        }
        const descendants = this.treeControl.getDescendants(node)

        if (
            descendants.some(
                (descendantNode) =>
                    descendantNode.item
                        .toLowerCase()
                        .indexOf(this.searchString?.toLowerCase()) !== -1
            )
        ) {
            return false
        }

        return true
    }
    // search filter logic end

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: any): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length > 0 && descendants.every(child => {
            return this.checklistSelection.isSelected(child);
        });
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: any): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: any): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node) ? this.checklistSelection.select(...descendants) : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.forEach(child => this.checklistSelection.isSelected(child));
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: any): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: any): void {
        let parent: any = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: any): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length > 0 && descendants.every(child => {
            return this.checklistSelection.isSelected(child);
        });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /* Get the parent node of a node */
    getParentNode(node: any): any {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }
    /*////////////////////////////////////////////////////////////////////////////*/

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `any`.
    **/
    buildFileTree(obj: {[key: string]: any}, level: number): any[] {
        return Object.keys(obj).reduce<any[]>((accumulator, key) => {
        const value = obj[key];
        const node = <any>{};
        node.item = key;

        if (value != null) {
            if (typeof value === 'object') {
            node.children = this.buildFileTree(value, level + 1);
            } else {
            node.item = value;
            }
        }

        return accumulator.concat(
            {
            ...node,
            otherInfo: key
            }
        );
        }, []);
    }
    
}
