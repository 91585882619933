import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DemandeWorkflow } from '../../interfaces/demande.workflow.interface';

@Injectable()
export class WorkflowService {

    private url = `${environment.api}/workflow`;
    constructor(private http: HttpClient) { }

    progressDemande(demandeWorkflow: DemandeWorkflow): Observable<any> {
        return this.http.post<any>(`${this.url}/demande/`, demandeWorkflow);
    }

    signature(state: boolean): Observable<any> {
        return this.http.get<any>(`${this.url}/signature/${state}`);
    }
}
