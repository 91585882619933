export const NEW = '_NEW_';
export const TOKEN = 'TOKEN';
export const FEATURES = 'FEATURES';
export const FLUID = 'FLUID';
export const LOCATION = 'LOCATION';
export const USER = 'USER';
export const PARAM_NOTIF_END = 'PARAM_NOTIF_END';
export const DECLARATION_NOTIF_END = 'DECLARATION_NOTIF_END';
export const TOKEN_ERROR = 'JsonWebTokenError';
export const TOKEN_EXPIRE_ERROR = 'TokenExpiredError';
export const LIVE_TREATMENT = 'LIVE_TREATMENT';
export const APPLICATIONS = 'APPLICATIONS';
export const GROUPES = 'GROUPES';
export const FONCTIONNALITES = 'FONCTIONNALITES';
export const ORGANISATION = 'ORGANISATION';
export const SIGNATURE = 'SIGNATURE';
export const APP_CODE = 'app.agrement';
export const ENTREPRISE = 'ENTREPRISE';
export const NB_MESSAGE = 'NB_MESSAGE';
export const PASSATIONS = 'PASSATIONS';

export const _PROGRESSER_ = '_PROGRESSER_';
export const _RETOURNER_ = '_RETOURNER_';
export const _VALIDER_ = '_VALIDER_';
export const _REJETER_ = '_REJETER_';

export const MENU_ITEMS = [
    {
        group:'Personal data',
        items:[
            { icon:'content_paste',label:'Demandes', link:'/m/demande/list'},
            { icon:'receipt_long',label:'Modèles', link:'/m/modele/list'}
        ]
    }
];