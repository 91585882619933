import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Signup, Signin, VerifyAccount, ResetPassword, SigninToken } from '../../interfaces/authentification.interface';

@Injectable()
export class AuthentificationService {
    
    

    private url = `${environment.api}/authentification/`;
    constructor(private http: HttpClient) { }


    signup(signup: Signup): Observable<any> {
        return this.http.post<any>(`${this.url}signup`, signup);
    }

    signin(signin: Signin): Observable<any> {
        return this.http.post<any>(`${this.url}signin`, signin);
    }

    verifyAccount(verifyAccount: VerifyAccount): Observable<any> {
        return this.http.post<any>(`${this.url}verify-account`, verifyAccount);
    }

    resetPassword(resetPassword: ResetPassword): Observable<any> {
        return this.http.post<any>(`${this.url}reset-password`, resetPassword);
    }

    signinToken(signin: SigninToken): Observable<any> {
        return this.http.post<any>(`${this.url}signin-token`, signin);
    }
}
