<div fxLayout="column" fxFill>
    <mat-toolbar color="primary" class="mat-elevation-z4 mat-toolbar-white">
        <button mat-icon-button>
            <mat-icon>money</mat-icon>
        </button>
        <div fxlayout="column" fxFlex="20" style="padding:10px;">
            <div>Mes  demandes d'agrément</div>
        </div>
        <div fxFlex></div>
        <div fxLayout="row">
            <div matPaddingSmall></div>
            <button mat-stroked-button matTooltip="Actualiser" (click)="load()" mat-icon-button>
                <mat-icon>refresh</mat-icon>
            </button>
            <div matPaddingSmall></div>
            <div class="bloc-search mat-stroked-button" style="background: #fff;">
                <input [(ngModel)]="searchText" type="text"/>
                <button (click)="search(searchText)" color="accent" matTooltip="Lancer la recherche" mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <div matPaddingSmall></div>
          <button mat-icon-button  [matMenuTriggerFor]="menu" >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" >
            <button mat-menu-item (click)="export()"  ><mat-icon>file_download</mat-icon> Exporter en excel</button>
          </mat-menu>
        </div>
    </mat-toolbar>
    <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
    <div fxLayout="column" style="overflow-y: auto;" fxFlex>

        <table mat-table class="table-max-width" matSort [dataSource]="dataSource" fxFlex>
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
            <!-- Position Column -->
            <ng-container matColumnDef="numero">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Numero </th>
                <td mat-cell *matCellDef="let element"> {{element?.numero}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element">

                    <mat-chip-list>
                        <mat-chip color="accent" *ngIf="element?.isRenouvellement" selected>Renouvellement</mat-chip>
                        <mat-chip color="primary" *ngIf="!element?.isRenouvellement" selected>Nouvel agrément</mat-chip>
                    </mat-chip-list></td>
            </ng-container>

            <ng-container matColumnDef="agrement">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Agrément </th>
                <td mat-cell *matCellDef="let element">
                    {{element.typeDemande}}
            </ng-container>

            <ng-container matColumnDef="raisonSocial">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Raison Social </th>
                <td mat-cell *matCellDef="let element"> {{element?.distributeur?.raisonSocial | uppercase}} </td>
            </ng-container>

            <ng-container matColumnDef="activite">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Activité </th>
                <td mat-cell *matCellDef="let element"> {{element?.distributeur?.activite}} </td>
            </ng-container>

            <ng-container matColumnDef="cc">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Compte contribuable </th>
                <td mat-cell *matCellDef="let element"> {{element?.distributeur?.cc}} </td>
            </ng-container>

           

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>


    </div>
    <mat-paginator [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
</div>
