

export class FileUpload {
	id: number | undefined;
    uuid: string | undefined;
    name: string | undefined;
    type: string | undefined;
    size: number | undefined;
    createdDate: Date  | undefined| null;
    lastModifiedDate: Date  | undefined| null;
    base64: string  | undefined| null;
    

public constructor(init?: Partial<FileUpload>) {
        Object.assign(this, init);
    }
}
export class FileUploadEntity {
	id: number | undefined;
    uuid: string | undefined;
    name: string | undefined;
    type: string | undefined;
    size: number | undefined;
    createdDate: Date | undefined;
    lastModifiedDate: Date | undefined;
    base64: string | undefined;    

    public constructor(init?: Partial<FileUpload>) {
        Object.assign(this, init);
    }
}
