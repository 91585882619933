import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { timeout, startWith, debounceTime, switchMap, finalize } from 'rxjs/operators';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { AccountService } from '../../services/auth/account.service';
import { Account } from 'src/app/interfaces/account.interface';

interface Error { email: string; password: string; }
interface Progress { loading: boolean; label: string; }

import { Observable, of } from 'rxjs';


import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { TOKEN, USER } from '../../app.constant';

import { Router } from '@angular/router';

@Component({
  selector: 'app-setting-account',
  templateUrl: './setting-account.component.html',
  styleUrls: ['./setting-account.component.scss']
})
export class SettingAccountComponent implements OnInit {

  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};

    public iForm!: FormGroup;

    public progress: Progress = { loading: false, label: '' }; // Progression du
    public error: Error = {email: '', password: ''}; // Les erreurs du formulaire

    searchControl = new FormControl();
    places!: Observable<any>;
    isLoading = false;

  
    constructor(public fb: FormBuilder,
                private accountService: AccountService,
                @Inject(LOCAL_STORAGE) private storage: StorageService,
                private router: Router) {}

    ngOnInit() {
        this.progress = {loading : false, label: '' }; // Aucun chargement au départ
        
        this.iForm = this.fb.group({
            _id: new FormControl(null, [Validators.required]),
            nom: new FormControl(null, [Validators.required]),
            prenoms: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            mail: new FormControl(null, [Validators.required]),
            pass: new FormControl(null, [Validators.required]),
        });

        this.iForm.patchValue(this.storage.get(USER));
    }

    save(): void {
        this.progress = { loading : true, label: 'Connection en cours ...' };
        const { _id, nom, prenoms, phone, mail, pass } = this.iForm.value;
        let user = this.storage.get(USER);
        
        //const uuid = this.storage.get(USER).uuid;

        const accountSetting: Account = <Account>{ _id, nom, prenoms, phone, mail, pass };
        this.accountService.firstConnexion(accountSetting).subscribe(
            (result: any) => {
                // Affichage du loader
                setTimeout(() => {
                    user = {...user, ...{nom, prenoms, phone}}
                    this.storage.set(USER,user);

                    this.router.navigate(['/m']);
                    this.progress = { loading : false, label: '' };
                }, 3000 );
            },
            (error: any) => {
                // Masquage du loader
                setTimeout(() => {
                    this.progress = { loading : false, label: '' };
                    const { code } = error.status;
                    this.error.email = code === 409 ? error.status.error : null;
                }, 3000 );
            }
        );
    }

}