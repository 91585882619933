import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';


interface Progress { loading: boolean; label: string; }


import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { TOKEN, USER } from '../../app.constant';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountService } from 'src/app/services/auth/account.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {


    user;
    mail;
    public iForm!: FormGroup;
    public inProgress!: Boolean;

    public progress: Progress = { loading: false, label: '' }; // Progression du


    constructor(
        public fb: FormBuilder,
        public dialogRef: MatDialogRef<ResetPasswordComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private administrationSyvalService: AccountService,
        private _snackBar: MatSnackBar,


    ) {
        const u = (localStorage.getItem('USER'))
        this.user = JSON.parse(u ? u : '{}');
        this.mail = this.user.mail;
        //console.log(mail);
    }

    ngOnInit(): void {
        this.iForm = this.fb.group({
            pass: new FormControl(null, [Validators.required]),
            newpass: new FormControl(null, [Validators.required]),
            cpass: new FormControl(null, [Validators.required]),
        });

        //this.user = this.storage.get(USER);
        //this.iForm.patchValue(this.user);
    }

    /* save(){
      const user: User = { ...this.user, ...this.iForm.value}
      this.progress = { loading : true, label: 'sauvegarde en cours ...' };
      this.administrationSyvalService.resetPassword(user).subscribe(
          result => {
            setTimeout(() => { this.progress = { loading : false, label: '' }; }, 3000 );
            this._snackBar.open( `Mot de passe modifié`, 'Fermer', { duration: 2000 });
          },
          error => {
            setTimeout(() => { this.progress = { loading : false, label: '' }; }, 3000 );
            this._snackBar.open( `Impossible de modifier le mot de passe`, 'Fermer', { duration: 2000 });
  
          }
      )
    } */

    save() {
        const user = { ...this.iForm.value }
        user.mail = this.storage.get(USER).mail;
        this.progress = { loading: true, label: 'sauvegarde en cours ...' };
        this.administrationSyvalService.updatePassword(user).subscribe(
            result => {
                console.log('resultat', result)
                setTimeout(() => { this.progress = { loading: false, label: '' }; }, 20000);
                this._snackBar.open(`Mot de passe modifié`, 'Fermer', { duration: 2000 });
                this.dialogRef.close();
            },
            error => {
                setTimeout(() => { this.progress = { loading: false, label: '' }; }, 3000);
                this._snackBar.open(`Impossible de modifier le mot de passe`, 'Fermer', { duration: 2000 });

            }
        )
    }


    cancel() {
        //this.user = this.storage.get(USER);
        //this.iForm.patchValue({});
    }

    close() {
        this.dialogRef.close();
    }

}
