import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CoreModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageServiceModule } from 'ngx-webstorage-service';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { environment } from 'src/environments/environment';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PdfViewerModule } from 'ng2-pdf-viewer';
//import { QuillModule } from 'ngx-quill';
import { ContenteditableValueAccessorModule } from '@tinkoff/angular-contenteditable-accessor';
import { AngularFireModule} from '@angular/fire/compat'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { CarouselModule } from 'ngx-owl-carousel-o';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CoreModule,
        CommonModule,
        CarouselModule,
        //SocketIoModule.forRoot(environment.socketIoConfig),
        FormsModule, ReactiveFormsModule, PdfViewerModule,
        BrowserModule,// ChartsModule,
        NgxMaskModule.forRoot({}),
        PerfectScrollbarModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        //QuillModule.forRoot(),
        CKEditorModule,
        //GaugeChartModule,
        StorageServiceModule,
        ContenteditableValueAccessorModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule
    ],
    schemas: [
        NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA
    ],
    providers:[
        { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
    ],
    exports: []
})
export class ExternalModule { }
