<div fxLayout="column" class="defaultTheme" fxFlex>
    <mat-toolbar color="primary">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>

            <mat-icon>6_ft_apart</mat-icon>

            <div matPaddingSmall></div>
            <span fxFlex="20" style="padding:10px;">Imputation</span>
        </div>
        <div fxLayout="row" fxFlex>
            <!--<input class="input-search" type="text" [matAutocomplete]="auto"/>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option>
                <img class="example-option-img" aria-hidden  height="25">
                <span>Hello</span> |
                <small>Comment allez vous</small>
                </mat-option>
            </mat-autocomplete>
            <button mat-stroked-button><mat-icon>search</mat-icon></button>-->
        </div>
        <div fxLayout="row">
            <button (click)="close()" mat-stroked-button mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <mat-card fxLayout="column" matPadding>
        <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner diameter="60"></mat-spinner>
            <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
        </div>
        <div fxLayout="column" matPadding fxFlex>
            <div matPadding style="font-weight: 100; font-size: 20px;">Destinataires</div>
            <div fxLayout="column" style="height: 150px; overflow-y: auto;" matPadding>
                <mat-selection-list [(ngModel)]="dests"  #shoes [multiple]="true">
                    <mat-list-option *ngFor="let destinataire of destinataires" [value]="destinataire">
                        <mat-icon *ngIf="!destinataire.isMandataire" mat-list-icon>account_circle</mat-icon>
                        <mat-icon *ngIf="destinataire.isMandataire" mat-list-icon>supervised_user_circle</mat-icon>
                        <div mat-line> {{destinataire.organisation.code  }}<!--{{destinataire?.nom}} {{destinataire?.prenoms}}--> </div>
                        <div mat-line> {{destinataire.organisation.libelle  | slice:0:50 }} ...</div>
                    </mat-list-option>
                </mat-selection-list>
            </div>
            
            <div matPadding style="font-weight: 100; font-size: 20px;">Laissez un commentaire</div>
            <div fxFlex matPadding>
                <textarea [(ngModel)]="message" cols="30" rows="6" style="border: 1px solid #ccc; border-radius: 8px; padding: 8px;" fxFlex></textarea>
            </div>
            <div matPadding fxLayout="row" fxLayoutAlign="start center">
                <div matPadding fxFlex></div>
                <button matRounded color="primary" [disabled]="dests.length == 0" mat-flat-button (click)="save()">
                    <mat-icon>send</mat-icon><span matPaddingSmall></span>
                    <span>Envoyer</span>
                </button>
            </div>
        </div>
        
    </mat-card>
</div>