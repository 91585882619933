import { Component, OnInit, Input } from '@angular/core';
import { Progress } from '../../interfaces/progress.interface';

@Component({
  selector: 'app-overlay-spiner',
  templateUrl: './overlay-spiner.component.html',
  styleUrls: ['./overlay-spiner.component.scss']
})
export class OverlaySpinerComponent implements OnInit {

    @Input()
    progress!: Progress;

    
    constructor() { }

    ngOnInit(): void {
    }

}
