import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize, map, startWith } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { GeoLocationService } from 'src/app/core/services/geolocation.service';


import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { TOKEN, FLUID, USER, LOCATION, LIVE_TREATMENT, PARAM_NOTIF_END, DECLARATION_NOTIF_END, APP_CODE, ENTREPRISE } from '../../app.constant';
import { ModalService, ParamDialog } from 'src/app/core/services/modal.service';

import { Entreprise } from '../../interfaces/entreprise.interface';
import { ThemeService } from 'src/app/core/services/theme.service';
import { ApplicationSyvalComponent } from 'src/app/modals/application-syval/application-syval.component';
import { environment } from 'src/environments/environment';
import { DemandeService } from '../../services/database/demande.service';
import { ResetPasswordComponent } from 'src/app/modals/reset-password/reset-password.component';
import { ProfilComponent } from 'src/app/modals/profil/profil.component';
import { CollaborationFormComponent } from 'src/app/modals/collaboration-form/collaboration-form.component';
import { CollaborationService } from 'src/app/services/database/collaboration.service';
import { NotificationService } from 'src/app/services/database/notification.service';
@Component({
    selector: 'app-f',
    templateUrl: './f.component.html',
    styleUrls: ['./f.component.scss']
})
export class FComponent implements OnInit {

    public agreementEnCours: boolean = false;
    execution: any = { operation: { uuid: null, name: ` Aucun traitement en cours`, step: 100, allstep: 100 }, message: `Aucun traitement en cours` };
    public remoteExecution: any[] = [];
    fluid!: string;
    screenWidth!: number;
    user = { nom: null, prenoms: null };
    paramNotif: boolean = false;
    declarationNotif: boolean = false;

    searchControl = new FormControl();
    places!: Observable<any>;
    isLoading = false;
    nb: number = 0;
    nbDemande: number = 0;
    progressRate = 0;
    entreprise: Entreprise = <Entreprise>{};
    //
    constructor(public dialog: MatDialog,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private demandeService: DemandeService,
        private notificationService: NotificationService,
        private collaborationService: CollaborationService,
        private modal: ModalService,
    ) { }

    ngOnInit() {

        this.screenWidth = window.innerWidth;
        window.onresize = () => { this.screenWidth = window.innerWidth; };
        this.user = this.storage.get(USER);
        this.getStateSouscription();
        this.CountDemande();
        this.CountNotification();
        this.loadCollaboration();
    }

    CountNotification = () => {
        this.notificationService.aggregate(
            [
                {
                    $match: { $and: [{ 'proprietaire.mail': this.storage.get(USER).mail }, { lu: { $eq: false } }] }
                },
                { $count: "nb" }
            ]
        ).subscribe(
            result => {
                if (result.length > 0) {
                    const nb = result[0];
                    this.nb = nb.nb;
                }
                else {
                    this.nb = 0;

                }

                //this.storage.set(NB_MESSAGE, nb.nb);
            }
        );
    }

    loadCollaboration() {
        this.collaborationService.query({ 'utilisateur._id': this.storage.get(USER)._id }).subscribe((result: any) => {
            const collaboration: any = (result.length == 1) ? result[0] : {};
            this.entreprise = collaboration.entreprise;
            this.storage.set(ENTREPRISE,collaboration.entreprise);
        });
    }

    getStateSouscription() {
        this.demandeService.query({
            $and: [
                { 'utilisateur._id': this.storage.get(USER) },
                { statut: { $in: ['_ENCOURS_', '_SOUMIS_','_VALIDE_']}}
            ]
        }).subscribe((result: any) => {
            //Après on vérifie si les autorisation cours toujours
            if(result.length > 0) this.agreementEnCours = true;

        });
    }

    goToState(root: string) {
        // this.lCenter = latLng( parseFloat(value.lat), parseFloat(value.lon));
        root = root.replace(':fluid', this.storage.get(FLUID));
        this.router.navigate([root]);
    }

    displayFn(place: any) {
        if (place) { return place.display_name; }
    }


    detailLog(element: any) {

    }

    Deconnexion() {
        this.modal.bottomConfirm(<ParamDialog>{ title: 'Confirmation', message: 'Voulez-vous vous déconnecter ?' })
            .afterDismissed().subscribe((confirmed: any) => {
                if (confirmed) {
                    this.storage.remove(USER);
                    this.storage.remove(DECLARATION_NOTIF_END);
                    this.storage.remove(PARAM_NOTIF_END);
                    this.router.navigate(['/a/signin']);
                }
            });
    }
    openSyvalApp() {
        const dialogRef = this.dialog.open(ApplicationSyvalComponent, {
            width: '400px',
            height: '500px',
            disableClose: false,
            data: {}
        }).afterClosed().subscribe((result: any) => {
            if (result) {
                if (result.code != APP_CODE) {
                    this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: `Voulez-vous quitter l'application ?` }).afterClosed().subscribe((confirmed: any) => {
                        if (confirmed) window.location.href = `http://${environment.targetUri(result.url)}/a/authentification/${this.storage.get(TOKEN).token}`;
                    });
                }
                else {
                    location.reload();
                }
            }
        });
    }
    CountDemande = () => {
      this.demandeService.aggregate(
          [
              {
                  $match: { clientEdit: { $eq: true } }
              },
              { $count: "nb" }
          ]
      ).subscribe(
          result => {
              if (result.length > 0) {
                  const nb = result[0];
                  this.nbDemande = nb.nb;
              }
              else {
                  this.nbDemande = 0;

              }

              //this.storage.set(NB_MESSAGE, nb.nb);
          }
      );
  }

  openProfil() {
    const dialogRef = this.dialog.open(ProfilComponent, {
        width: '800px',
        //height: '700px',
        disableClose: false,
        data: {}
    }).afterClosed().subscribe((result: any) => {
        
    });
}

openResetPassword() {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
        width: '400px',
        disableClose: false,
        data: {}
    }).afterClosed().subscribe((result: any) => {
        
    });
}



SeCoupler() {
    const dialogRef = this.dialog.open(CollaborationFormComponent, { width: '400px', height: '400px', disableClose: false, data: {} })
    .afterClosed().subscribe((result: any) => {
        if(result){
            this.entreprise = result;
            this.storage.set(ENTREPRISE,result);
        }
    });
}

openHelp(){
    window.open('https://storage.googleapis.com/assets.sudcontractors.com/e5be825d-c4c9-4074-b9f7-50c6fc53138d.pdf');
    /*let link = document.createElement("a");
    link.download = "Guide Utilisateur";
    link.href = "../../../assets/help/guide.pdf";
    link.click();*/
}

Dissocier() {
    this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: `Voulez-vous dissocier votre compte de  ${this.storage.get(ENTREPRISE).raisonSocial} ?` }).afterClosed().subscribe((confirmed: any) => {
        if (confirmed){
            //Recupère la collaboration et on supprime
            this.collaborationService.query({ 'utilisateur._id': this.storage.get(USER)._id }).subscribe((result: any) => {
                const collaboration: any = (result.length == 1) ? result[0] : null;
                if(collaboration){
                    this.collaborationService.delete(collaboration._id).subscribe((result: any) => {
                        this.entreprise = <Entreprise>{};
                        this.storage.set(ENTREPRISE,this.entreprise);
                        setTimeout(() => {
                            //this.loadFormData();
                            //this.progress = { loading: false, label: '' };
                            //this._snackBar.open('Demande supprimé', 'OK', { duration: 2000 });
                        }, 3000);
                    })
                }
            });
        }
    });
}

    showInfoline(){
        this.modal.alert({ title: 'Infoline', message: `En cas de besoin d'assistance, veuillez contacter le +225 0506147624`})
    }

}
