<div fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="33">

            <mat-icon>font_download</mat-icon>

            <div matPaddingSmall></div>
            <!--<span fxFlex="20" style="padding:10px;">Agrément / Renouvelement</span> -->
            <span fxFlex="20" style="padding:10px;" *ngIf="!demande.isRenouvellement">Demande d'agrément</span>
            <span fxFlex="20" style="padding:10px;" *ngIf="demande.isRenouvellement">Demande de renouvellement</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxFlex>
            <mat-button-toggle-group [(ngModel)]="sPage" [disabled]="!demande.signe" class="mat-body-2">
                <mat-button-toggle value="demande">Déclaration</mat-button-toggle>
                <mat-button-toggle value="document">Voir le courrier</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <div *ngIf="sPage == 'demande'" fxLayout="row" fxLayoutAlign="end center"  fxFlex="33">

            <button  mat-icon-button (click)="showMessage()" matTooltip="Vous avez un message" *ngIf="form.controls.clientEdit.value && form.controls.observationClient.value">
                <mat-icon>chat</mat-icon>
            </button>
            <div matPaddingSmall *ngIf="form.controls.clientEdit.value"></div>

            <button  mat-stroked-button (click)="save()" *ngIf="form.controls.clientEdit.value" [disabled]="form.invalid"
                 color="primary">
                <mat-icon>save</mat-icon><span matPaddingSmall></span><span>Enregistrer</span>
                
            </button>
            <div matPaddingSmall *ngIf="form.controls.clientEdit.value"></div>

            <button  mat-flat-button *ngIf="form.controls.clientEdit.value" (click)="Soumettre()" [disabled]="form.invalid || !isCompleted()"
                 color="primary">
                <span matPaddingSmall></span><span>Soumettre</span><span matPaddingSmall></span>
                <mat-icon>arrow_right_alt</mat-icon>
            </button>
            <div *ngIf="!demande.clientEdit">
                <div class="stick-orange" *ngIf="demande.statut == '_SOUMIS_'">Soumis</div>
                <div class="stick-blue" *ngIf="demande.statut == '_ENCOURS_' && !demande.signe" >En cours de traitement</div>
                <div class="stick-green" *ngIf="demande.valide == true && demande.signe">Validé</div>
                <div class="stick-red" *ngIf="demande.valide == false && demande.signe">Rejeté</div>
            </div>
            

        </div>
        <div *ngIf="sPage =='document'" fxLayout="row" fxLayoutAlign="end center"  fxFlex="33">
            <button  mat-flat-button (click)="download()" [disabled]="form.invalid || demande.clientEdit != false"
                [disabled]="!demande.signe" color="primary">
                <span matPaddingSmall></span><span>Télécharger</span><span matPaddingSmall></span>
                <mat-icon>download</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <mat-card fxLayout="column"  matPadding fxFlex>
        <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner diameter="60"></mat-spinner>
            <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
        </div>
        <div [ngSwitch]="sPage" fxFlex>
            <div *ngSwitchDefault fxFlex>
                <form matPadding [formGroup]="form" fxLayout="column" fxFlex>


                    <div matPadding fxLayout="row" fxFlex>
                        <div matPadding matPaddingSmall fxLayout="column" fxFlex>
                            <div class="mat-headline" fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon>museum</mat-icon>
                                <span matPaddingSmall></span>
                                <span>Information du Requérant</span>
                            </div>
        
                            <div fxLayout="column" matPadding>
        
                                <mat-form-field  appearance="outline">
                                    <mat-label>Type d'agrément<span>*</span></mat-label>
                                    <mat-select formControlName="typeDemande" [disabled]="!form.controls.clientEdit.value">
                                        <mat-option *ngFor="let demande of typeDemandes" [value]="demande.libelle" > {{demande.libelle}} </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
        
                            <div fxLayout="column" formGroupName="distributeur" matPadding>
                                <div fxLayout="row">
                                    <mat-form-field  appearance="outline">
                                        <mat-label>Forme juridique</mat-label>
                                        <mat-select formControlName="formeJuridique" [disabled]="!form.controls.clientEdit.value">
                                            <mat-option *ngFor="let forme of formeJuridiques" [value]="forme.libelle"> {{forme.libelle}} </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div matPadding></div>
                                    <mat-form-field  appearance="outline" fxFlex>
                                        <mat-label>Raison Sociale</mat-label>
                                        <input matInput formControlName="raisonSocial" autocomplete="off" [readOnly]="!form.controls.clientEdit.value">
                                        <mat-hint>Raison Sociale</mat-hint>
                                        <mat-error *ngIf="form.get('distributeur.raisonSocial').invalid">Champ obligatoire
                                        </mat-error>
                                    </mat-form-field>
                                </div>
        
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <mat-form-field  appearance="outline" fxFlex>
                                        <mat-label>Siège social</mat-label>
                                        <input matInput formControlName="adresse" autocomplete="off" [readOnly]="!form.controls.clientEdit.value">
                                        <mat-hint>Siège social</mat-hint>
                                        <mat-error *ngIf="form.get('distributeur.adresse').invalid">Champ obligatoire
                                        </mat-error>
                                    </mat-form-field>
                                    <div matPadding></div>
                                    <mat-form-field  appearance="outline">
                                        <mat-label>Adresse postale</mat-label>
                                        <input matInput formControlName="codePostal" autocomplete="off" [readOnly]="!form.controls.clientEdit.value">
                                        <mat-hint>Adresse postale</mat-hint>
                                        <mat-error *ngIf="form.get('distributeur.codePostal').invalid">Champ obligatoire
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <mat-form-field  appearance="outline">
                                    <mat-label>Complement d'adresse</mat-label>
                                    <input matInput formControlName="complementAdresse" autocomplete="off" [readOnly]="!form.controls.clientEdit.value">
                                    <mat-hint>Complement d'adresse</mat-hint>
                                    <mat-error *ngIf="form.get('distributeur.complementAdresse').invalid">Champ obligatoire
                                    </mat-error>
                                </mat-form-field>
        
                                <div matPadding></div>
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <mat-form-field  appearance="outline">
                                        <mat-label>CNPS</mat-label>
                                        <input matInput formControlName="cnps" autocomplete="off" [readOnly]="!form.controls.clientEdit.value">
                                        <mat-hint>CNPS</mat-hint>
                                        <mat-error *ngIf="form.get('distributeur.cnps').invalid">Champ obligatoire</mat-error>
                                    </mat-form-field>
                                    <div matPadding></div>
                                    <mat-form-field  appearance="outline" fxFlex>
                                        <mat-label>RCCM</mat-label>
                                        <input matInput formControlName="rccm" autocomplete="off" [readOnly]="!form.controls.clientEdit.value">
                                        <mat-hint>RCCM</mat-hint>
                                        <mat-error *ngIf="form.get('distributeur.rccm').invalid">Champ obligatoire</mat-error>
                                    </mat-form-field>
                                </div>
        
                                <mat-form-field  appearance="outline">
                                    <mat-label>CC</mat-label>
                                    <input matInput formControlName="cc" autocomplete="off" [readOnly]="!form.controls.clientEdit.value">
                                    <mat-hint>CC</mat-hint>
                                    <mat-error *ngIf="form.get('distributeur.cc').invalid">Champ obligatoire</mat-error>
                                </mat-form-field>
        
                                <div matPadding></div>
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <mat-form-field  appearance="outline">
                                        <mat-icon matSuffix>phone</mat-icon>
                                        <mat-label>Téléphone</mat-label>
                                        <input matInput formControlName="phone"  autocomplete="off" [readOnly]="!form.controls.clientEdit.value">
                                        <mat-hint>Téléphone</mat-hint>
                                        <mat-error *ngIf="form.get('distributeur.phone').invalid">Champ obligatoire</mat-error>
                                    </mat-form-field>
                                    <div matPadding></div>
                                    <mat-form-field  appearance="outline" fxFlex>
                                        <mat-icon matSuffix>mail_outline</mat-icon>
                                        <mat-label>Mail</mat-label>
                                        <input matInput formControlName="mail" autocomplete="off" [readOnly]="!form.controls.clientEdit.value">
                                        <mat-hint>Adresse mail</mat-hint>
                                        <mat-error *ngIf="form.get('distributeur.mail').invalid">Champ obligatoire</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div matPadding matPaddingSmall fxLayout="column" fxFlex>
                            <mat-vertical-stepper class="stepper-size" [linear]="false" fxFlex #stepper>
                                <mat-step>
                                    <ng-template matStepLabel>
                                        <div fxLayout="column" fxLayoutAlihn="start center" fxFlex>
                                            <div matPaddingSmall>Pièces jointes</div>
                                            <!--<div fxLayout="row" >
                                                <button *ngIf="!isRenouvellement" [disabled]="!form.controls.clientEdit.value" mat-stroked-button mat-button (click)="loadPieceType('DOCAGREMENT')"> Nouvelle demande</button>
                                                <span matPadding *ngIf="!isRenouvellement"></span>
                                                <button *ngIf="isRenouvellement" [disabled]="!form.controls.clientEdit.value" mat-stroked-button mat-button (click)="loadPieceType('DOCRENOUVELEMENT')"> Renouvellement</button>
                                            </div>-->
                                        </div>
                                        
                                    </ng-template>
                                    <div matPadding fxLayout="column">
                                        <div>
                                            <app-file-upload *ngFor="let piece of pieces" [placeholder]="piece.type.libelle"
                                                accept="application/pdf" [fichier]="piece.fichier" [disabled]="!form.controls.clientEdit.value"
                                                (onUpload)="addDocument($event ,piece)"></app-file-upload>
                                        </div>
                                    </div>
                                </mat-step>
                            </mat-vertical-stepper>
                        </div>
                    </div>    
                </form>
            </div>
            <div *ngSwitchCase="'document'" fxLayout="row" fxLayoutAlign="center center" fxFlex>
                <!--<div  class="preview">
                    <div id="toPrint" class="ck-content" [innerHtml]="demande.document | safeHtml"></div>
                </div>-->
                <pdf-viewer [src]="report" [render-text]="true" style="display: block; width: 1000px; height: 100%;" [show-all]="true"></pdf-viewer>

            </div>
        </div>
        
    </mat-card>
</div>
