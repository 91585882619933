<div fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar color="primary">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>

            <mat-icon>desktop_windows</mat-icon>

            <div matPaddingSmall></div>
            <span fxFlex="20" style="padding:10px;">Syval</span>
        </div>
        <div fxLayout="row" fxFlex>
            <!--<input class="input-search" type="text" [matAutocomplete]="auto"/>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option>
                <img class="example-option-img" aria-hidden  height="25">
                <span>Hello</span> |
                <small>Comment allez vous</small>
                </mat-option>
            </mat-autocomplete>
            <button mat-stroked-button><mat-icon>search</mat-icon></button>-->
        </div>
        <div fxLayout="row" >
            <div matPaddingSmall></div>
            <div class="bloc-search mat-stroked-button">
                <input [(ngModel)]="searchText" placeholder="Chercher votre application" type="text"
                    (keyup)="applyFilter()" style="color: #fff;" />
                <button  color="accent" matTooltip="Lancer la recherche"
                    mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
    </mat-toolbar>
    <mat-card matPadding fxFlex>

        <div matPadding style="overflow-y: auto; height: 400px;" fxFlex>
            <mat-grid-list cols="2" rowHeight="1:1" fxFlex>
                <mat-grid-tile matRipple matRippleColor="blue" *ngFor="let app of applications">
                    <div class="app" fxLayout="column" fxLayoutAlign="center center" matPadding (click)="openApp(app)" fxFill>
                        <div class="mat-padding" fxLayout="column" fxLayoutAlign="center center" fxFlex="80">
                            <div class="mat-body-2" fxLayout="column" fxLayoutAlign="center center" fxFlex="70">
                                <div>
                                    <mat-icon color="accent" style="width: 50px ; height:50px; font-size: 50px;">{{app.icone}}</mat-icon>
                                </div>
                                <div>{{app.nom}}</div>
                            </div>
                        </div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>

    </mat-card>
</div>