
import { Injectable } from '@angular/core';

@Injectable()

export class ParametreGlobalService {

    constructor(

    ) { }
  
    initialiseParametre(){
    }

}
