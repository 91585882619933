import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Like } from '../../../core/classes/typeorm';

import { MatDialog } from '@angular/material/dialog';
import { ModalService, ParamDialog } from '../../../core/services/modal.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ModeleService } from '../../../services/database/modele.service';
import { Modele } from 'src/app/interfaces/modele.interfce';
import { Router } from '@angular/router';
import { APP_CODE, ENTREPRISE, USER } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-modele-document-list',
  templateUrl: './modele-document-list.component.html',
  styleUrls: ['./modele-document-list.component.scss']
})
export class ModeleDocumentListComponent implements OnInit {



  @ViewChild(MatPaginator, {}) paginator: MatPaginator;
  displayedColumns: string[] = ['libelle', 'description', 'type', 'action'];
  fidelityes: any[];
  dataSource = new MatTableDataSource<any>([]);
  inProgress = false;
  inSearchProgress = false;

  searchText = '';

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private modal: ModalService,
    private router: Router,
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private modeleService: ModeleService
  ) { }

  ngOnInit() {
    this.load();
  }

  load() {
    this.inProgress = true; // Démarre le loader
  this.modeleService.query({   app: APP_CODE   }).subscribe(result => {
      this.inProgress = false; // stop le loader
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });

  }

  search(searchText: string) {
  }

  goToState(root: string) {
    this.router.navigate([root]);
  }

  select(element) {
    this.router.navigate(['m/modele/details/', element._id]);
  }

  applyFilter() {
    this.dataSource.filter = this.searchText;
  }

  openFile = (file) => {
    window.open(file.url);
  }

}
