
<mat-card fxLayout="column" fxFill>
    <!--<div matPadding fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex>
            <div>
                <div class="mat-headline">Configurez vôtre compte</div>
                <div class="mat-body-1">Les utilisateurs de Pinterest en découvriront un peu plus sur vous grâce aux informations ci-dessous</div>
            </div>
        </div>       
    </div>-->
        <nav backgroundColor="primary"  mat-tab-nav-bar>
            
            
            <a routerLink="/m/parameter/value" mat-tab-link>
                <mat-icon>money</mat-icon>
                <span matPaddingSmall></span>
                <span>Liste de valeur</span>
            </a>
        </nav>
        <div fxFlex>
            <router-outlet></router-outlet>
        </div>
        
</mat-card>
