import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthInterface, VerificationInterface } from '../../interfaces/auth.interface';

@Injectable()
export class AuthService {

    private url = `${environment.api}/auth/`;
    constructor(private http: HttpClient) { }

    register(auth: AuthInterface): Observable<AuthInterface> {
        return this.http.post<AuthInterface>(`${this.url}register`, auth);
    }

    signin(auth: AuthInterface): Observable<AuthInterface> {
        return this.http.post<AuthInterface>(`${this.url}signin`, auth);
    }

    verifyAccount(verification: VerificationInterface): Observable<AuthInterface> {
        return this.http.post<AuthInterface>(`${this.url}verify`, verification);
    }

    getUserByUUID(uuid: string): Observable<AuthInterface> {
        return this.http.get<AuthInterface>(`${this.url}uuid/${uuid}`);
    }

    sendActivationCode(uuid: string): Observable<AuthInterface> {
        return this.http.get<AuthInterface>(`${this.url}activation/${uuid}`);
    }
}
