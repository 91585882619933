import { Directive, ElementRef, Renderer2, HostBinding, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[matPadding]',
})
export class MatPaddingDirective   implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.el.nativeElement.style.padding = '10px 10px';
    }
}


@Directive({
  selector: '[matPaddingSmall]',
})
export class MatPaddingSmallDirective   implements AfterViewInit {
    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        this.el.nativeElement.style.padding = '5px';
    }
}