import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentDemande } from '../../../statistic/index';
import { Blob } from 'buffer'
import { ModalService, ParamDialog } from '../../../core/services/modal.service';
import { Demande } from 'src/app/interfaces/demande.interface';
import { ParametreGlobalService } from 'src/app/services/settings/parametre-global.service';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { DemandeService } from '../../../services/database/demande.service';
import { ValeurService } from '../../../services/settings/valeur.service';
import { Piece } from 'src/app/interfaces/piece.interface';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { USER, _PROGRESSER_ } from 'src/app/app.constant';
import { Valeur } from 'src/app/interfaces/valeur.interface';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { environment } from 'src/environments/environment';
import { FComponent } from '../f.component';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Workflow } from 'src/app/interfaces/workflow.interface';

import * as html2pdf from 'html2pdf.js'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface Progress { loading: boolean; label: string; }

@Component({
    selector: 'app-souscription-form',
    templateUrl: './souscription-form.component.html',
    styleUrls: ['./souscription-form.component.scss']
})
export class SouscriptionFormComponent implements OnInit {

    sPage: string = 'demande';

    pdf: any = null;
    report!: any;
    reporting: any = {};

    modeResume: boolean = false;

    public progress: Progress = { loading: false, label: '' };
    demande: Demande = <Demande>{}

    constructor(
        private modal: ModalService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        @Inject(FComponent) private parentF: FComponent,
        private route: ActivatedRoute,
        private demandeService: DemandeService,
        private workflowService: WorkflowService,
        private _snackBar: MatSnackBar,
        public fb: FormBuilder,
        private valeurService: ValeurService,
        private router: Router,

    ) { }

    @ViewChild('scrollable') private scrollable!: ElementRef;

    public form!: FormGroup;
    public inProgress: Boolean = false;
    public isLinear: Boolean = false;
    public statutBrouillon!: Valeur;

    public pieces: Piece[] = [];

    public formeJuridiques: Valeur[] = [];
    public typeDemandes: Valeur[] = [];

    public renouvelements: Demande[] = [];
    public isRenouvellement: boolean = false;

    ngOnInit() {

        this.buildForm();
        this.loadValeurs();

        // 
        this.route.paramMap.subscribe(params => {
            const id = params.get('id');
            if (id) {
                this.loadFormData(id);
            }
            else {
                //this.loadPiece('DOCAGREMENT');
            }
        });

        // 
        this.route.url.subscribe((item: any[]) => {
            let reNew = false;
            for(let i = 0; i<= item.length - 1 ; i++){
                const x = item[i];
                if(x.path == "renouvellement"){
                    reNew = true;
                    console.log('active');

                    this.isRenouvellement = true;
                    this.form.controls['isRenouvellement'].patchValue(true);

                    this.route.paramMap.subscribe(params => {
                        const id = params.get('id');
                        if (id) {
                            this.loadFormData(id, ()=>{
                                this.loadPieceType('DOCRENOUVELEMENT');
                            });
                            

                        }
                        else {
                        }
                    });
                    break;
                }
            }

            if(!reNew){
                this.route.paramMap.subscribe(params => {
                    const id = params.get('id');
                    if (id) {
                        this.loadFormData(id);
                    }
                    else {
                        this.loadPiece('DOCAGREMENT');
                    }
                });

            }
        })

    }

    /*private prepareDocument = (demande: any) => {
        const b = demande;
        this.reporting = DocumentDemande.ResumeDocument
        const { template, injector } = this.reporting.template
        const documentDefinition = injector(template, this.demande, this.reporting.model);
        this.generate(documentDefinition);
    }
    private generate = (documentDefinition: any) => {
        this.pdf = pdfMake.createPdf(documentDefinition);
        this.pdf.getBlob(async (blob: any) => {
            this.report = await blob.arrayBuffer();
        });
    }*/

    private prepareDocument = (demande: any) => {
        const b = demande.valide
        this.reporting = (b) ? DocumentDemande.ProjetDocument : DocumentDemande.RejetDocument
        const { template, injector } = this.reporting.template
        const documentDefinition = injector(template, this.demande, this.reporting.model);
        this.generate(documentDefinition);
    }

    private generate = (documentDefinition: any) => {
        this.pdf = pdfMake.createPdf(documentDefinition);
        this.pdf.getBlob(async (blob: any) => {
            this.report = await blob.arrayBuffer();
            console.log(this.report)
        });
    }

    changeModeResume() {
        this.modeResume = !this.modeResume;
    }


    demandeARenouveler() {
        this.demandeService.query({
            $and: [
                { 'utilisateur._id': this.storage.get(USER) },
                { statut: { $in: ['_VALIDE_'] } }
            ]
        }).subscribe((result: any) => {
            //Après on vérifie si les autorisation cours toujours
            console.log(result);
            if (result.length > 0) {
                const demande: Demande = result[0];
                demande._id = null;
                demande.workflow.suivi = [];
                demande.isRenouvellement = true;
                demande.clientEdit = true;
                demande.statut = '';
                demande.observation = '';
                //this.pieces = demande.pieces;
                this.demande = demande;
                //this.formPatchValue(demande);
                //this.loadPiece('DOCRENOUVELEMENT');

            }
            else {
                //this.loadPiece('DOCAGREMENT');
            }
            this.loadPiece('DOCAGREMENT');

            //if(result.length > 0) this.agreementEnCours = true;
        });
    }

    loadPiece = (code: any) => {
        this.inProgress = true; // Démarre le loader
        this.valeurService.query({ code }).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const valeur = (result.length > 0) ? result[0] : { valeurs: [] };
            this.pieces = [];
            for (let i = 0; i <= valeur.valeurs.length - 1; i++) {
                this.pieces.push(
                    {
                        type: valeur.valeurs[i],
                        fichier: null,
                        commentaire: '',
                        valid: false
                    }
                )
            }
        });
    }

    loadPieceType = (code: any) => {
        this.loadPiece(code);
        if(code == 'DOCRENOUVELEMENT') {
            console.log("ici")
            this.form.controls['isRenouvellement'].patchValue(true);
        }
        else {
            this.form.controls['isRenouvellement'].patchValue(false);
        }
    }

    loadValeurs = () => {
        this.inProgress = true; // Démarre le loader
        this.valeurService.query({}).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const valeurs = result;
            for (let i = 0; i <= valeurs.length - 1; i++) {

                if (valeurs[i].code == 'FORMEJURIDIQUE') { this.formeJuridiques = valeurs[i].valeurs; }
                if (valeurs[i].code == 'TYPEAGREMENT') { console.log(valeurs[i]); this.typeDemandes = valeurs[i].valeurs; }
            }
        });
    }

    loadFormData = (id: any, fn?: Function) => {
        this.inProgress = true; // Démarre le loader
        this.demandeService.query({ _id: id }).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const demande: Demande = result[0];

            //
            demande.clientEdit = this.isRenouvellement;
            demande._id = this.isRenouvellement ? null : demande._id;
            demande.numero = this.isRenouvellement ? null : demande.numero;
            demande.statut = this.isRenouvellement ? null : demande.statut;
            demande.signe = this.isRenouvellement ? null : demande.signe;
            demande.signature = this.isRenouvellement ? null : demande.signature;
            demande.qrcode = this.isRenouvellement ? null : demande.qrcode;

            this.demande = demande;
            this.pieces = demande.pieces;
            this.modeResume = true;

            demande.clientEdit = demande.statut == null ? true : demande.clientEdit 

            if(this.isRenouvellement == true){
                demande.statut = null;
            }
            console.log(this.isRenouvellement);

            this.formPatchValue(demande);
            this.prepareDocument(demande);

            if(fn) fn();
        });
    }

    addDocument = ($event: any, piece: any) => {
        for (let i = 0; i <= this.pieces.length - 1; i++) {
            if (piece.type.code == this.pieces[i].type.code) {
                this.pieces[i].fichier = $event;
                break;
            }
        }
        console.log(this.pieces)
    }

    isCompleted = () => {
        let b = true;
        for (let i = 0; i <= this.pieces.length - 1; i++) {
            if (!this.pieces[i].fichier) {
                b = false;
                break;
            }
        }
        return b
    }
    // Construction du formulaire
    buildForm() {

        this.form = this.fb.group({
            _id: new FormControl(null),
            numero: new FormControl(null),
            app: new FormControl('app.agrement'),
            typeDemande: new FormControl(null, [Validators.required]),
            visibilite: new FormControl(null),
            niveau: new FormControl("_CLIENT_"),
            clientEdit: new FormControl(true),
            distributeur: new FormGroup({
                formeJuridique: new FormControl(null, [Validators.required]),

                raisonSocial: new FormControl(null, [Validators.required]),
                logo: new FormControl(null),
                adresse: new FormControl(null, [Validators.required]),
                complementAdresse: new FormControl(null),
                codePostal: new FormControl(null, [Validators.required]),
                cnps: new FormControl(null, this.demande.isRenouvellement ? [Validators.required] : []),
                rccm: new FormControl(null, [Validators.required]),
                cc: new FormControl(null, [Validators.required]),
                phone: new FormControl(null),
                mail: new FormControl(null, [Validators.required]),
                siteWeb: new FormControl(null),
            }),
            isRenouvellement: new FormControl(this.isRenouvellement),
            direction: new FormControl(null),
            date: new FormControl(new Date()),
            debut: new FormControl(null),
            valide: new FormControl(null),
            suivi: new FormControl([]),
            workflow: new FormControl(null),
            document: new FormControl(null),
            documentNote: new FormControl(null),
            numDoc: new FormControl(null),
            signe: new FormControl(null),
            dateSignature: new FormControl(null),
            signature: new FormControl(null),
            qrcode: new FormControl(null),

            siteWord: new FormControl(null),
            regionSite: new FormControl(null),
            article9: new FormControl(null),

            pAgrement: new FormControl(null),
            pDateSignature: new FormControl(null),
            
            utilisateur: new FormControl(this.storage.get(USER), [Validators.required]),
            observation: new FormControl(null),
            observationClient: new FormControl(null),
            statut: new FormControl(null)

        });
    }

    // Remplissage du formulaire avec les données initiales
    formPatchValue(value: any) {
        this.form.patchValue(value);
    }

    save(): void {

        this.progress = { loading: true, label: '' }; // Démarre le loader

        const demande: Demande = this.form.value;
        demande._id = this.isRenouvellement ? null : demande._id;
        demande.pieces = this.pieces;
        const send = (!demande._id) ? this.demandeService.save(demande) : this.demandeService.update(demande);

        send.subscribe((result: any) => {
            setTimeout(() => {
                this.progress = { loading: false, label: '' }; // stop le loader
                if (result) this._snackBar.open('Demande enregistré', 'Dance', { duration: 2000 });
                this.goToState('/f/souscription/list');

            }, 3000);

        });
    }

    Soumettre(): void {
        this.modal.confirm(<ParamDialog>{ title: 'Confirmation', message: `Vous allez soumettre la demande ?` }).afterClosed().subscribe((confirmed: any) => {
            if (confirmed) {
                this.progress = { loading: true, label: '' }; // Démarre le loader

                const demande: Demande = this.form.value;
                //demande.clientEdit = false; //Importation de produits pétroliers
                demande.niveau = !demande.niveau || demande.niveau == '_CLIENT_' ? 'DGH' : demande.niveau;
                demande.pieces = this.pieces;
                demande.workflow = !demande.workflow ? <Workflow><unknown>{ suivi: [] } : demande.workflow;

                console.log(demande)
                const send = this.workflowService.progressDemande(
                    {
                        demande,
                        action: _PROGRESSER_,
                        options: {},
                        utilisateur: this.storage.get(USER)
                    }
                );

                send.subscribe((result: any) => {
                    //Desactivation du bouton demande agrement agreementEnCours
                    this.parentF.agreementEnCours = true;
                    this.parentF.CountDemande();

                    setTimeout(() => {
                        this.progress = { loading: false, label: '' }; // stop le loader
                        if (result) this._snackBar.open('Demande soumise', 'Dance', { duration: 2000 });
                        this.goToState('/f/souscription/list')

                    }, 3000);
                });
            }
        });


    }

    goToState(root: string) {
        this.router.navigate([root]);
    }

    openFile = (file: any) => {
        window.open(file.url);
    }

    download() {
        if (this.pdf) {
            this.pdf.print();
        }
        /*
        var element = document.getElementById('toPrint');
        var opt = {
            margin: 1, //top, left, buttom, right
            filename: 't.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { dpi: 192, scale: 2, letterRendering: true, useCORS: true },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
            pageBreak: { mode: 'css', after: '.break-page' }
        };
        // New Promise-based usage:
        html2pdf().from(element).set(opt).save();*/

    }

    showMessage() {
        this.modal.alert({ title: 'Nouveau Message', message: this.demande.observationClient })
    }
}
