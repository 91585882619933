import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { APPLICATIONS } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/core/services/modal.service';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SIGNATURE } from 'src/app.constant';
import * as  resizebase64 from 'resize-base64';  
import { WorkflowService } from 'src/app/services/workflow/workflow.service';

@Component({
  selector: 'app-document-signe',
  templateUrl: './document-signe.component.html',
  styleUrls: ['./document-signe.component.scss']
})
export class DocumentSigneComponent implements OnInit {


  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
  public config: PerfectScrollbarConfigInterface = {};
  public documentSigne: any = null;

  applications = [];
  signature;
  searchText!:string;
  num!: number;
  date!: Date;
  constructor(
      public dialogRef: MatDialogRef<DocumentSigneComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private modal: ModalService,
      private workflowService: WorkflowService,
      @Inject(LOCAL_STORAGE) private storage: StorageService,

  ) { }

  ngOnInit(): void {
      this.applications = this.storage.get(APPLICATIONS);
      this.signature = this.storage.get(SIGNATURE);
      this.getSignatureInfos();
  }

  private imageToDataUri(img:any, width, height) {

    // create an off-screen canvas
    var canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.height = height;
    canvas.width = width;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
}

getSignatureInfos() {
  this.workflowService.signature(false).subscribe((result: any) => {
    this.num = result.numCount;
    this.date = result.date;

    console.log(this.num, this.date)
  });
}

  close() {
      this.dialogRef.close();
  }

  Send(){
      var canvas:any = document.querySelector('#qr canvas');
      var img:any = document.querySelector('#signature');

      console.log([(canvas.toDataURL()),resizebase64(this.signature.data,200,200)])
      this.dialogRef.close([canvas.toDataURL(),resizebase64(this.signature.data,200,200), this.num, this.date]);
  }

  applyFilter() {
      //this.dataSource.filter = this.searchText;
  }

  addDocument($event:string){
    this.documentSigne = $event;
  }

}
