import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Parametre } from '../../interfaces/parametre.interface';
import { Observable } from 'rxjs';

@Injectable()
export class ParameterService {

    constructor(private http: HttpClient) { }
    // Ajoute un nouvel enregistrement de ParameterEntity 
    save(parameter: Parametre): Observable<any> {
        return this.http.post<any>(`${environment.api}/parameter/`, parameter);
    }
    // Supprime un enregistrement de ParameterEntity
    delete(primaryKey: any): Observable<any> {
        return this.http.delete<any>(`${environment.api}/parameter/${encodeURI(JSON.stringify(primaryKey))}`);
    }
    // Trouve tous les enregistrements de ParameterEntity
    findAll(): Observable<any> {
        return this.http.get<any>(`${environment.api}/parameter/`);
    }
    query(queryParameter: any): Observable<any> {
        return this.http.get<any[]>(`${environment.api}/parameter/query/${encodeURI(JSON.stringify(queryParameter))}`);
    }
    // Trouve un seul enregistrements de ParameterEntity
    findOne(primaryKey: any): Observable<any> {
        return this.http.get<any>(`${environment.api}/parameter/${encodeURI(JSON.stringify(primaryKey))}`);
    }
}
