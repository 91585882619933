import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsSignedGuard, IsNotSignedGuard } from './guards/auth.guard';
import { AComponent } from './pages/a/a.component';
import { AuthentificationComponent } from './pages/authentification/authentification.component';
import { DemandeCoursComponent } from './pages/f/demande-cours/demande-cours.component';
import { DemandeValidComponent } from './pages/f/demande-valid/demande-valid.component';
import { FComponent } from './pages/f/f.component';
import { MessageClientComponent } from './pages/f/message-client/message-client.component';
import { SouscriptionFormComponent } from './pages/f/souscription-form/souscription-form.component';
import { SouscriptionListComponent } from './pages/f/souscription-list/souscription-list.component';
import { SouscriptionComponent } from './pages/f/souscription/souscription.component';
import { DemandeDetailsComponent } from './pages/m/demande-details/demande-details.component';
import { DemandeListComponent } from './pages/m/demande-list/demande-list.component';
import { DemandeComponent } from './pages/m/demande/demande.component';
import { MComponent } from './pages/m/m.component';
import { ModeleDocumentDetailsComponent } from './pages/m/modele-document-details/modele-document-details.component';
import { ModeleDocumentListComponent } from './pages/m/modele-document-list/modele-document-list.component';
import { ModeleDocumentComponent } from './pages/m/modele-document/modele-document.component';
import { MessageComponent } from './pages/message/message.component';
import { ParameterComponent } from './pages/parameter/parameter.component';
import { SettingAccountComponent } from './pages/setting-account/setting-account.component';
import { SigninComponent } from './pages/signin/signin.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ValeurComponent } from './pages/valeur/valeur.component';
import { VerifyAccountComponent } from './pages/verify-account/verify-account.component';

const routes: Routes = [
  {   path: 'm', component: MComponent,
      children: [
          {   path: 'demande', component: DemandeComponent,
                  children: [
                      {   path: 'list', component: DemandeListComponent  },
                      {   path: 'details/:id', component: DemandeDetailsComponent  },
              ],
          },
          {
            path: 'modele',
            component: ModeleDocumentComponent,
            children: [
              { path: 'list', component: ModeleDocumentListComponent },
              { path: 'details', component: ModeleDocumentDetailsComponent },
              { path: 'details/:id', component: ModeleDocumentDetailsComponent },
            ],
          },
          {   path: 'message', component: MessageComponent  },
          //{   path: 'configuration', component: ConfigurationComponent},
          {   
              path: 'parameter', component: ParameterComponent,
              children: [
                  {   path: 'value', component: ValeurComponent },
              ]
          }
      ],
      canActivate: [ IsSignedGuard ]
  },

  {   path: 'f', component: FComponent,
      children: [
          {   path: 'souscription', component: SouscriptionComponent,
                  children: [
                      {   path: 'list', component: SouscriptionListComponent  },
                      {   path: 'form', component: SouscriptionFormComponent  },
                      {   path: 'form/:id', component: SouscriptionFormComponent  },
                      {   path: 'form/renouvellement/:id', component: SouscriptionFormComponent  },
              ],
          },
          {   path: 'message', component: MessageClientComponent  },
          {   path: 'demande-valid', component: DemandeValidComponent  },
          {   path: 'demande-cours', component: DemandeCoursComponent  }
      ],
      canActivate: [ IsSignedGuard ]
  },
  
  {   path: 'a', component: AComponent, pathMatch: 'prefix',  
      children: [
          {   path: 'signin', component: SigninComponent, canActivate: [ IsNotSignedGuard] },
          {   path: 'signup', component: SignupComponent, canActivate: [ IsNotSignedGuard] },
          {   path: 'verify-account', component: VerifyAccountComponent, canActivate: [ IsNotSignedGuard] },
          {   path: 'setting-account', component: SettingAccountComponent, canActivate: [ IsNotSignedGuard] },
          {   path: 'authentification/:token', component: AuthentificationComponent},
          {   path: '',  redirectTo: 'signin', pathMatch: 'full'},
      ]
  },
  {   path: '', redirectTo: 'a/signin', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
