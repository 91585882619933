import { DecimalPipe } from '@angular/common';
import { isNumber } from 'util';

const Template = (params: any) => {
    console.log(params)
    const t = {
        content: [
            {
                margin: [0, 0, 0, 50],
                table: {
                    widths: ['*', 250],
                    body: [
                        [
                            {
                                stack: [
                                    { text: 'MINISTERE DES MINES,\n DU PETROLE ET DE L\'ENERGIE', alignment: "center", bold: true },
                                    { text: '--------------', style: 'subheader', alignment: "center", bold: true }

                                ],
                                alignment: "center"
                            },
                            {
                                stack: [
                                    'REPUBLIQUE DE COTE D’IVOIRE',
                                    { text: 'Union – Discipline – Travail', style: 'subheader' },
                                    { text: '--------------', style: 'subheader' }
                                ],
                                alignment: "center", bold: true
                            }
                        ]
                    ]
                }, layout: 'noBorders'
            },
            { text: '\n', alignment: 'center', bold: true, fontSize: 14 },

            {
                text: [
                    { text: 'ARRETE ', fontSize: 11, bold: true },
                    { text: ` n° ${params.numDoc ?  params.numDoc : '...'}/MMPE/Cab/DGH du ${params.signe ? GenerateDate(params.dateSignature) : '...'} portant renouvellement de l' agrément de la société ${params.distributeur.raisonSocial}  pour la distribution des produits pétroliers et dérivés sur le territoire de la République de Côte d’Ivoire. \n\n`, fontSize: 11, lineHeight: 1.2 },
                    {
                        text: 'Le Ministre des mines, du pétrole et de l’Energie,\n',
                        fontSize: 11, bold: true, lineHeight: 1.2, margin: [20, -35, 0, 0],
                    }
                ], margin: [20, -35, 0, 0],
                style: 'home'
            },
            {
                stack: [

                    { text: `Sur proposition du Directeur Général des Hydrocarbures,\n` },

                    {
                        margin: [0, 1, 0, 50],
                        table: {
                            widths: [20, '*'],
                            body: [
                                [
                                    { text: `Vu` }, 
                                    { text: `la Constitution de la République de Côte d’Ivoire ;` },
                                ],
                                [
                                    { text: `Vu` }, 
                                    { text: `la Loi n°92-469 du 30 juillet 1992, portant répression des fraudes en matière de produits pétroliers et des violations aux prescriptions techniques de sécurité ;` },
                                ],
                                [
                                    { text: `Vu` }, 
                                    { text: `la loi n°2016-410 du 15 juin 2016, relative à la répression des fraudes et des falsifications en matière de vente des biens et des services ;` },

                                ],
                                [
                                    { text: `Vu` }, 
                                    { text: `la loi n°2016-412 du 15 juin 2016 relative à la consommation ;` },

                                ],
                                [
                                    { text: `Vu` }, { text: `la loi n°2019-989 du 27 novembre 2019 relative au système national de métrologie en Côte d’Ivoire ;` },

                                ],
                                [
                                    { text: `Vu` }, { text: `le décret n°92-470 du 30 juillet 1992, portant définition de la procédure de constatation et de la répression des fraudes et violations aux prescriptions de sécurité en matière de produits pétroliers ;` },

                                ],
                                [
                                    { text: `Vu` },
                                    { text: `le décret n°98-43 du 28 janvier 1998, relatif aux installations classées pour la protection de l’environnement ;` },

                                ],
                                [
                                    { text: `Vu` },
                                    { text: `le décret n°2021-466 du 08 septembre 2021, portant organisation du Ministère des Mines, du Pétrole et de l’Energie ;` },

                                ],
                                [
                                    { text: `Vu` },
                                    { text: `le décret n°2022-269 du 19 avril 2022, portant nomination du Premier Ministre, Chef du Gouvernement ;` },

                                ],
                                [
                                    { text: `Vu` },
                                    { text: `le décret n°2022-270 du 20 avril 2022, portant nomination des Membres du Gouvernement ;` },

                                ],
                                [
                                    { text: `Vu` },
                                    { text: `le décret n°2022-301 du 04 mai 2022, portant attributions des Membres du Gouvernement ;` },

                                ],
                                [
                                    { text: `Vu` },
                                    { text: `l’arrêté n° ${params.pAgrement} du ${GenerateDate(params.pDateSignature)}, portant agrément de la
                                    société ${params.distributeur.raisonSocial} pour la distribution des produits pétroliers et dérivés sur le territoire de la
                                    République de Côte d’Ivoire ;` },

                                ],
                                [
                                    { text: `Vu` },
                                    { text: `la demande d’agrément de la société ${params.distributeur.raisonSocial} enregistrée sous le n° ${params.numero} du ${GenerateDate(params.date)};` }

                                ]
                            ]
                        }, layout: 'noBorders'
                    },

                ], fontSize: 11, alignment: 'justify', margin:0, padding:0
            },
            { text: 'ARRETE \n', alignment: 'center', bold: true, fontSize: 14 },

            {
                stack: [
                    {
                        text: [
                            { text: 'Article premier :', bold: true, style: 'soulignment' },
                            { text: `L’agrément pour la distribution des produits pétroliers et dérivés, accordé à la société  ${params.distributeur.raisonSocial}, ${params.distributeur.codePostal}, domiciliée à ${params.distributeur.adresse} ${params.distributeur.complementAdresse}, Téléphone ${params.distributeur.phone}, RCCM : ${params.distributeur.rccm}, NCC : ${params.distributeur.cc} , par arrêté n° ${params.pAgrement} du ${GenerateDate(params.pDateSignature)}, est renouvelé à compter de la date de sa signature pour une durée de cinq (05) ans, renouvelable.. \n` },
                        ],
                        style: 'article'
                    },
                    {
                        text: [
                            { text: 'Article 2 :', bold: true, style: 'soulignment' },
                            { text: `La société  ${params.distributeur.raisonSocial}  est tenue d' exercer son activité dans le strict respect des dispositions des articles 2, 3 et 4 de l' arrêté n° ${params.pAgrement} du ${GenerateDate(params.pDateSignature)}.\n` }
                        ],
                        style: 'article'
                    },
                    
                    {
                        text: [
                            { text: 'Article 3 : ', bold: true, style: 'soulignment' },
                            { text: ' Pour le renouvellement de l’agrément, la société ' + params.distributeur.raisonSocial + ' devra soumettre à la Direction Générale des Hydrocarbures, un dossier comprenant les pièces suivantes :\n' },
                            {
                                text: [
                                    { text: '– Les quantités de produits pétroliers vendus par année et par type de produit ;\n', },
                                    { text: '– le nombre et la localisation de ses établissements pétroliers et dépôts d’hydrocarbures ;\n' },
                                    { text: '– une copie des quittances de paiement des taxes de contrôle et de vérification des dépôts d’hydrocarbures et établissements pétroliers des trois (03) dernières années d’exercice ;\n' },
                                    { text: '– les bilans et rapports des commissaires aux comptes des trois (03) dernières années d’exercice ;\n' },
                                    { text: '– un état des investissements réalisés ;\n' },
                                    { text: '– la liste (nom, prénom, nationalité et fonction) des dirigeants de la société ;\n' },
                                    { text: '– une attestation de mise à jour délivrée par la Caisse Nationale de Prévoyance Sociale ;\n' },
                                    { text: '– une attestation de régularité fiscale délivrée par la Direction Générale des Impôts.\n' },
                                ],
                                margin: [15, 2, 5, 10],
                                alignement: 'justify'
                            },
                            { text: 'La demande de renouvellement doit intervenir au moins trois (03) mois avant l’expiration de la période de validité du présent agrément.\n' },
                        ],
                        style: 'article'
                    },
                    {
                        text: [
                            { text: 'Article 4 : ', bold: true, style: 'soulignment' },
                            { text: ' Le non-respect des dispositions législatives et règlementaires en vigueur peut entrainer, soit la suspension, soit le retrait de cet agrément par arrêté du Ministre en charge des Hydrocarbures..\n' }
                        ],
                        style: 'article'
                    },
                    {
                        text: [
                            { text: 'Article 5 : ', bold: true, style: 'soulignment' },
                            { text: ' Le Directeur Général des Hydrocarbures est chargé de l’application du présent arrêté qui sera publié au Journal Officiel de la République de Côte d’Ivoire.\n' }
                        ],
                        style: 'article'
                    }
                    
                ],
                alignment: 'justify'
            },

            {
                margin: [0, 0, 0, 50],
                table: {
                    widths: ['*', '*'],
                    body: [
                        [

                            {
                                stack: [
                                    { text: '' },
                                    params.qrcode ? { image: params.qrcode, fit: [100, 100] } : { text: '\n\n\n\n\n\n' },
                                ]
                            },
                            {
                                stack: [
                                    { text: `Fait à Abidjan le ${params.signe ? GenerateDate(params.dateSignature) : '...'}.` },
                                    params.signature ? { image: params.signature, fit: [100, 100] } : { text: '\n\n\n\n\n\n' },
                                    { text: 'Mamadou SANGAFOWA-COULIBALY', bold: true },
                                ],
                                alignment: 'right'
                            }


                        ]
                    ]
                }, layout: 'noBorders'
            },
            {
                margin: [0, 0, 0, 50],
                table: {
                    widths: ['*', '*'],
                    body: [
                        [
                            {
                                text: [
                                    { text: 'AMPLIATIONS \n', style: 'ampliations', bold: true, fontSize: 8, decoration: 'underline', decorationStyle: 'solid', decorationColor: 'black' },
                                    { text: '-SGG......................................................     1\n', style: 'ampl' },
                                    { text: '-MMPE.....................................................     1\n', style: 'ampl' },
                                    { text: '-DGH......................................................     1\n', style: 'ampl' },
                                    { text: '-Intéressé................................................     1\n', style: 'ampl' },
                                    { text: '-J.O.R.C.I................................................     1\n', style: 'ampl' },
                                ]
                            },
                            { text: '' }

                        ]
                    ]
                }, layout: 'noBorders'
            }
        ],
        styles: {
            soulignment: {
                decoration: 'underline', decorationStyle: 'solid', decorationColor: 'black', bold: true
            },
            ampl: {
                fontSize: 8
            },
            header: {
                fontSize: 13,
                bold: true,
                alignment: 'right',
                margin: [0, 1, 0, 80]
            },
            headerRight: {
                fontSize: 13,
                bold: true,
                alignment: 'left',
                margin: [0, 12, 0, 2]
            },
            signatureName: {
                fontSize: 13,
                bold: true,
                alignment: 'right',
                margin: [310, -46, 64, 27]
            },
            signatureNameR: {
                fontSize: 13,
                bold: true,
                alignment: 'left',
                margin: [38, 1, 64, 27]
            },
            signature: {
                fontSize: 13,
                bold: false,
                alignment: 'right',
                margin: [0, 1, 35, 60]
            },
            ampliations: {
                fontSize: 13,
                bold: false,
                alignment: 'left',

            },
            subheader: {
                fontSize: 11,
                bold: true
            },
            superMargin: {
                margin: [20, 0, 40, 0],
                fontSize: 15
            },
            home: {
                fontSize: 11,
                alignment: ''
            },
            article: {
                fontSize: 11,
                margin: [0, 5, 0, 5]
            }
        }
    }
    return t;
}

const GenerateDate = (dt) => {
    const prefix = '0';
    const d = new Date(dt ? dt : new Date());
    const y = d.getFullYear();
    const m = d.getMonth() + 1;
    const dy = d.getDate();
    //
    const numMonth = `${prefix.repeat(2 - m.toString().length)}`;
    const numDay = `${prefix.repeat(2 - dy.toString().length)}`;

    return `${dy}/${m}/${y}`
}

const Injector = (template: any, data: any, model: any) => {
    return template(data);
}

export const RenouvellementTemplate = {
    template: Template,
    injector: Injector
}