import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Like } from '../../../core/classes/typeorm';

import { MatDialog } from '@angular/material/dialog';
import { ModalService, ParamDialog } from '../../../core/services/modal.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DemandeService } from '../../../services/database/demande.service';
import { Demande } from 'src/app/interfaces/demande.interface';
import { Router } from '@angular/router';
import { APP_CODE, USER } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';
import { ORGANISATION } from 'src/app.constant';
import { Organisation } from 'src/app/interfaces/organisation.interface';
import { Utilisateur } from 'src/app/interfaces/utilisateur.interface';

@Component({
    selector: 'app-demande-list',
    templateUrl: './demande-list.component.html',
    styleUrls: ['./demande-list.component.scss']
})
export class DemandeListComponent implements OnInit {



// on simule une modif
    @ViewChild(MatPaginator, {}) paginator!: MatPaginator;
    displayedColumns: string[] = ['numero', 'type', 'niveau','dateTransmit','raisonSocial', 'activite', 'cc', 'statut','date', 'document', 'action'];
    fidelityes!: any[];
    sTabIndex:number = 0;
    dataSource = new MatTableDataSource<any>([]);
    dataSourceNew = new MatTableDataSource<any>([]);
    dataSourceCurrent = new MatTableDataSource<any>([]);
    dataSourceValide = new MatTableDataSource<any>([]);
    dataSourceReject = new MatTableDataSource<any>([]);
    organisation: Organisation = <Organisation><unknown>{ code: null };
    public utilisateur!: Utilisateur;

    inProgress = false;
    inSearchProgress = false;

    searchText = '';

    @ViewChild(MatSort, { static: true }) sort!: MatSort;

    constructor(
        public dialog: MatDialog,
        private modal: ModalService,
        private router: Router,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private demandeService: DemandeService
    ) { }

    ngOnInit() {
        this.organisation = this.storage.get(ORGANISATION) ? this.storage.get(ORGANISATION) : <Organisation><unknown>{ code: null };
        this.utilisateur = this.storage.get(USER);
        this.load();
    }
/**
 * 
 * $and: [
                { 'utilisateur._id': this.storage.get(USER) },
                { statut: { $in: ['_ENCOURS_', '_SOUMIS_','_VALIDE_']}}
            ]
 */
    load() {
        this.inProgress = true; // Démarre le loader
        this.demandeService.query({
            $and: [ 
                {   app: APP_CODE   },
                {
                    $or:[
                        {   'workflow.visibilite.mail': this.storage.get(USER).mail  },
                        {   niveau: this.storage.get(ORGANISATION).code  }
                    ]
                }
            ]
        }).subscribe((demandes: Demande[]) => {

            const _demandes_ = demandes.map( (d: Demande) => {

                d.isNew = false;
                d.isInCourse = false;
                d.isDone = false;

                if(d.signe){
                    d.isNew = false;
                    d.isInCourse = false;
                    d.isDone = true;
                }
                else{

                    /*if(d.niveau == this.organisation.code){
                    
                    }*/
                    
                    let presence = 0;
                    for(let i = 0; i <= d.workflow.suivi.length - 1 ; i++){
                        const s = d.workflow.suivi[i];
                        if(s.responsable.mail == this.utilisateur.mail){
                            presence += 2;
                        }

                        if(s.mandataire.mail == this.utilisateur.mail){
                            presence++;
                        }
                    }

                    if(presence == 1){
                        d.isNew = true;
                        d.isInCourse = false;
                        d.isDone = false;
                    }
                    else{
                        if(presence > 1){
                            d.isNew = false;
                            d.isInCourse = true;
                            d.isDone = false;
                        }
                        else{
                            for(let i = 0; i <= d.workflow.visibilite?.length - 1 ; i++){
                                const v = d.workflow.visibilite[i];
                                if(v.mail == this.utilisateur.mail){
                                    d.isNew = true;
                                    d.isInCourse = false;
                                    d.isDone = false;
                                    break;
                                }
                            }
                        }
                    }
                }

                
                return d;
            })

            console.log(_demandes_)
            this.dataSource = new MatTableDataSource<any>(_demandes_)

            this.dataSourceNew = new MatTableDataSource<any>(_demandes_.filter(d => d.isNew == true));
            this.dataSourceCurrent = new MatTableDataSource<any>(_demandes_.filter(d => d.isInCourse == true));
            this.dataSourceValide = new MatTableDataSource<any>(_demandes_.filter(d => d.isDone == true));

            //this.dispatchDemande(result)
            this.inProgress = false; // stop le loader
            /*
            this.dataSource = new MatTableDataSource<any>(result);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            
            console.log(this.dataSource);
            */
        });

    }

    private dispatchDemande = (demandes: Demande[]) => {

        const demandeNew: Demande[] = [];
        const demandeCurrent: Demande[] = [];
        const demandeValide: Demande[] = [];
        const demandeRejete: Demande[] = [];

        const _demandes_ = demandes.map(
            d => {

                d.statut = (d.signe) ? d?.valide == false ? '_REJETE_' : '_VALIDE_' : d.statut
                return d
            }
        )

        for(let i = 0; i<= demandes.length - 1; i++){
            const demande = demandes[i];

            switch(demande.statut){
                case '_VALIDE_':{demandeValide.push(demande);break;}
                case '_REJETE_':{demandeRejete.push(demande);break;}
            }

            for(let j = 0; j<= demande?.workflow?.visibilite?.length -1 ; j++){
                const u = demande?.workflow?.visibilite[j];
                if(u.reinitialisation == false){
                    demandeNew.push(demande);break;
                }

                if(u.reinitialisation == true){
                    demandeCurrent.push(demande);break;
                }
            }
            /*switch(demande.statut){
                case '_SOUMIS_':{demandeNew.push(demande);break;}
                case '_ENCOURS_':{demandeCurrent.push(demande);break;}
                case '_VALIDE_':{demandeValide.push(demande);break;}
                case '_REJETE_':{demandeRejete.push(demande);break;}
            }*/
        }

        this.dataSourceNew.data.length
        this.dataSourceNew = new MatTableDataSource<any>(demandeNew);
        this.dataSourceCurrent = new MatTableDataSource<any>(demandeCurrent);
        this.dataSourceValide = new MatTableDataSource<any>(demandeValide);
        this.dataSourceReject = new MatTableDataSource<any>(demandeRejete);
    }

    search(searchText: string) {
    }

    goToState(root: string) {
        this.router.navigate([root]);
    }

    select(element: any) {
        this.router.navigate(['m/demande/details/', element._id]);
    }

    applyFilter() {
        switch(this.sTabIndex){
            case 0 :{
                this.dataSourceNew.filter = this.searchText;
                break;
            }
            case 1 :{
                this.dataSourceCurrent.filter = this.searchText;

                break;
            }
            case 2 :{
                this.dataSourceValide.filter = this.searchText;
                break;
            }
            case 3 :{
                this.dataSourceReject.filter = this.searchText;
                break;
            }
        }
        
    }

    getTransmitDate = (element) => {
        let d = null;
        for(let i = element?.workflow?.suivi?.length - 1; i>= 0; i--){
            const s = element?.workflow?.suivi[i];
            if(s?.mandataire?.organisation?.code == this.organisation.code) {
                d = s.date;
                break;
            }
        }
        return d;
    }

    openFile = (file: any) => {
        window.open(file.url);
    }

}
