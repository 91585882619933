<div fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar color="primary">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>

            <mat-icon>6_ft_apart</mat-icon>

            <div matPaddingSmall></div>
            <span fxFlex="20" style="padding:10px;">Passation de charges</span>
        </div>
        <div fxLayout="row" fxFlex>
            <!--<input class="input-search" type="text" [matAutocomplete]="auto"/>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option>
                <img class="example-option-img" aria-hidden  height="25">
                <span>Hello</span> |
                <small>Comment allez vous</small>
                </mat-option>
            </mat-autocomplete>
            <button mat-stroked-button><mat-icon>search</mat-icon></button>-->
        </div>
        <div fxLayout="row">
            <button (click)="close()" mat-stroked-button mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <mat-card>
        <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner diameter="60"></mat-spinner>
            <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
        </div>
        <form [formGroup]="form" fxLayout="column">
            <mat-horizontal-stepper class="stepper-size" [linear]="false" #stepper>

                <mat-step fxFlex>
                    <ng-template matStepLabel>Mes Passations</ng-template>
                    <div fxLayout="column" style="height: 280px; overflow-y: auto;" fxFlex>
                        <div matPadding fxLayout="row" fxLayoutAlign="end center">
                            <button matRounded mat-stroked-button (click)="new()">
                                <mat-icon>add</mat-icon><span matPaddingSmall></span><span>Ajouter</span>
                            </button>
                            <span matPaddingSmall></span>
                        </div>
                        <table mat-table matSort [dataSource]="dataSource" fxFlex>
                            <!--- Note that these columns can be defined in any order.
                                  The actual rendered columns are set as a property on the row definition" -->
                            <!-- Position Column -->

                            <ng-container matColumnDef="type">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Type </th>
                                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                            </ng-container>

                            <ng-container matColumnDef="utilisateur">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Mandataire </th>
                                <td mat-cell *matCellDef="let element"> {{element?.mandataire?.nom}} {{element?.mandataire?.prenoms}} </td>
                            </ng-container>

                            <ng-container matColumnDef="debut">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Debut </th>
                                <td mat-cell *matCellDef="let element"> {{element.debut | date : 'dd/mm/yyyy'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="fin">
                                <th mat-header-cell mat-sort-header *matHeaderCellDef> Fin </th>
                                <td mat-cell *matCellDef="let element">
                                    <span *ngIf="!element.fin"> Indéterminé </span>
                                    <span>{{element.debut | date : 'dd/mm/yyyy'}}</span>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> action </th>
                                <td mat-cell *matCellDef="let element" class="action-column">
                                    <div fxLayout="row">
                                        <button class="action-button-datagrid" mat-icon-button matTooltip="Ouvrir"
                                            (click)="select(element)">
                                            <mat-icon>keyboard_arrow_right</mat-icon>
                                        </button>

                                        <button class="action-button-datagrid" mat-icon-button matTooltip="Supprimer"
                                            (click)="delete(element)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </mat-step>

                <mat-step fxFlex>
                    <ng-template matStepLabel>Détails</ng-template>
                    <div matPadding fxLayout="column" fxFlex>
                        <div fxLayout="column" matPadding>
                            <div fxLayout="row" matPadding fxLayoutAlign="start center">
                                <mat-radio-group formControlName="type" (change)="getSubalterne($event)">
                                    <mat-radio-button *ngFor="let typeChoise of typeChoises" [value]="typeChoise.value">
                                        {{typeChoise.label}}</mat-radio-button>
                                </mat-radio-group>
                                <div fxFlex></div>

                                <div fxLayout="row" fxLayoutAlign="start center" matPadding>
                                    <mat-checkbox formControlName="imputation">Imputation</mat-checkbox>
                                    <span matPadding></span>
                                    <mat-checkbox formControlName="signature">Signature</mat-checkbox>
                                </div>
                                <div fxFlex matPadding></div>
                                <div fxLayout="row">
                                    <mat-form-field  appearance="outline">
                                        <mat-label>Période de passation</mat-label>
                                        <mat-date-range-input [rangePicker]="campaignOnePicker">
                                            <input matStartDate placeholder="Debut" formControlName="debut" >
                                            <input matEndDate placeholder="Fin"  formControlName="fin" >
                                        </mat-date-range-input>
                                        <mat-datepicker-toggle matSuffix [for]="campaignOnePicker" ></mat-datepicker-toggle>
                                        <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
                                        <mat-hint>Programmez la visite sur une période</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div fxLayout="column" fxFlex>
                                <div style="height: 125px;">
                                    <mat-radio-group  fxLayout="column" formControlName="mandataire">
                                        <table class="mat-table cdk-table table-max-width" fxFlex>
                                            <thead>
                                                <tr>
                                                    <th class="mat-header-cell cdk-header-cell">
                                                        <div matPadding>Nom</div>
                                                    </th>
                                                    <th class="mat-header-cell cdk-header-cell">
                                                        <div matPadding>Prénoms</div>
                                                    </th>
                                                    <th class="mat-header-cell cdk-header-cell">
                                                        <div matPadding>Mail</div>
                                                    </th>
                                                    <th class="mat-header-cell cdk-header-cell">
                                                        <div matPadding>Votre choix</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let user of users">
                                                    <td class="mat-row cdk-row mat-footer-cell">
                                                        <div class=".mat-body-2" mat-line>{{user.nom}}</div>
                                                    </td>
                                                    <td class="mat-row cdk-row mat-footer-cell">
                                                        <div class=".mat-body-2" mat-line>{{user.prenoms}}</div>
                                                    </td>
                                                    <td class="mat-row cdk-row mat-footer-cell">
                                                        <div class=".mat-body-2" mat-line>{{user.mail}}</div>
                                                    </td>
                                                    <td class="mat-row cdk-row mat-footer-cell">
                                                        <div class=".mat-body-2" fxLayout="row" fxLayoutAlign="end center" matPadding mat-line>
                                                            <mat-radio-button [checked]="passation?.mandataire?._id == user._id" [value]="user"></mat-radio-button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </mat-radio-group>
                                </div>
                                <div>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-step>
            </mat-horizontal-stepper>
            <div matPadding fxLayout="row" fxLayoutAlign="start center">
                
                <div matPadding fxFlex></div>
    
                <button [disabled]="form.invalid" matRounded mat-stroked-button (click)="save()">
                    <mat-icon>check_circle</mat-icon><span matPaddingSmall></span>
                    <span>Valider</span>
                </button>
                <span matPaddingSmall></span>
                <button matRounded mat-stroked-button (click)="close()">
                    <mat-icon>cancel</mat-icon><span matPaddingSmall></span><span>Annuler</span>
                </button>
                <span matPaddingSmall></span>
            </div>
        </form>
    </mat-card>
</div>