import { Directive, ElementRef, HostListener, Input, Inject, AfterViewInit } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
//import { FEATURES } from 'src/app/app.constant';

@Directive({
  selector: '[gRole]'
})
export class GRoleDirective  implements AfterViewInit {

    constructor(
        private el: ElementRef,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        
    ) {
     
    }

    ngAfterViewInit() {
        //const fonctionnalites: any[] = this.storage.get(FEATURES)
        //const role = this.gRole
        //if(!fonctionnalites.includes(role)) this.el.nativeElement.style.display = 'none';
        
    }

    @Input() gRole: string | undefined;

   

}