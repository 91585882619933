import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { timeout } from 'rxjs/operators';

import { AuthentificationService } from '../../services/auth/authentification.service';
import { Signup, Signin, VerifyAccount, ResetPassword } from 'src/app/interfaces/authentification.interface';

import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { TOKEN, USER, FEATURES, APPLICATIONS, GROUPES, FONCTIONNALITES, ORGANISATION, SIGNATURE } from '../../app.constant';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Signature } from 'src/app/interfaces/signature.interface';
import { SignatureService } from 'src/app/services/database/signature.service';

interface Error { email: string; password: string; }
interface Progress { loading: boolean; label: string; }

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};

    public form!: FormGroup;
    public showPassword!: Boolean;
    public inProgress!: boolean;

    public progress: Progress = { loading: false, label: '' }; // Progression du
    public error: Error = {email: '', password: ''}; // Les erreurs du formulaire

    constructor( 
        public fb: FormBuilder, private authService: AuthentificationService,
        @Inject(LOCAL_STORAGE) private storage: StorageService, private signatureService: SignatureService,
        private _snackBar: MatSnackBar,
        private router: Router
    ) { }

    ngOnInit() {
        this.inProgress = false;
        this.form = this.fb.group({
            mail: new FormControl(null, [Validators.required]),
            pass: new FormControl(null, [Validators.required]),
        });
    }

    signin(): void {
        const { mail, pass } = this.form.value;
        const inscription: Signin = { mail, pass };

        this.progress = { loading : true, label: 'Connection en cours ...' };
        this.authService.signin(inscription).subscribe(
                (result: any) => {
                    const [token, utilisateur, groupes, applications,fonctionnalites, organisation ] = result;
                    this.storage.set(TOKEN, token);
                    this.storage.set(USER, utilisateur);
                    this.storage.set(USER, utilisateur);
                    this.storage.set(APPLICATIONS, applications);
                    this.storage.set(GROUPES, groupes);
                    this.storage.set(FONCTIONNALITES, fonctionnalites);
                    this.storage.set(ORGANISATION, organisation);

                    this.signatureService.query({ 'utilisateur.mail': utilisateur.mail }).subscribe(
                        (signatures: Signature[]) => {
                            const signature = (signatures.length > 0) ? signatures[signatures.length - 1] : {};
                            this.storage.set(SIGNATURE, signature);
                            setTimeout(() => {
                                this.progress = { loading : false, label: '' };
                                
                                if(organisation){
                                    this.router.navigate(['/m']);
        
                                }
                                else{
                                    if(utilisateur?.verification?.verifie == true){
                                        this.router.navigate(['/f/souscription/list']);
                                    }
                                    else{
                                        this.router.navigate(['/a/verify-account']);
                                    }
                                }
                                
                                  
                            }, 3000 );
                        }
                    );

                    console.log(result)
                    
                },
              (error: any) => {
                  // Masquage du loader
                  setTimeout(() => {
                      this.progress = { loading : false, label: '' };
                      this._snackBar.open(`Votre login ou mot de passe est incorrecte`, 'Fermer', { duration: 2000, horizontalPosition:'right', verticalPosition:'top' });
                      const { code } = error.status;
                      this.error.email = code === 409 ? error.status.error : null;
                  }, 3000 );
              }
        );
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;

    }

    openHelp(){
        window.open('https://storage.googleapis.com/assets.sudcontractors.com/e5be825d-c4c9-4074-b9f7-50c6fc53138d.pdf');
        /*let link = document.createElement("a");
        link.download = "Guide Utilisateur";
        link.href = "../../assets/help/guide.pdf";
        link.click();*/
    }
}
