<div class="menu-left mat-body-2"  fxLayout="column" mousemove="openSide()" mouseleave="closeSide()" fxFlex>
    <div fxLayout="column" [@popOverState]="stateName" fxFlex>
        <mat-toolbar class="mat-elevation-z0">
            <button mat-icon-button (click)="epingle()">
                <mat-icon *ngIf="pushed">unfold_less</mat-icon>
                <mat-icon *ngIf="!pushed">code</mat-icon>
            </button>
        </mat-toolbar>
        <div fxFlex>
            <div *ngFor="let item of items">
                <!--<div>{{item.group}}</div>-->
                <button *ngFor="let menu of item.items" [matTooltip]="!pushed ? menu.label : null" matTooltipPosition="right" (click)="goToState(menu.link)"  class="menu-button" mat-button >
                    <mat-icon>{{menu.icon}}</mat-icon>
                    <span *ngIf="pushed">{{menu.label}}</span>
                </button>
                <mat-divider></mat-divider>            
            </div>
        </div>
    </div>
</div>