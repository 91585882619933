import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Like } from '../../../core/classes/typeorm';

import { MatDialog } from '@angular/material/dialog';
import { ModalService, ParamDialog } from '../../../core/services/modal.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { DemandeService } from '../../../services/database/demande.service';
import { Demande } from 'src/app/interfaces/demande.interface';
import { Router } from '@angular/router';
import { APP_CODE, USER } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-souscription-list',
    templateUrl: './souscription-list.component.html',
    styleUrls: ['./souscription-list.component.scss']
})
export class SouscriptionListComponent implements OnInit {



    @ViewChild(MatPaginator, {}) paginator!: MatPaginator;
    displayedColumns: string[] = ['numero', 'type', 'raisonSocial', 'activite','date', 'cc', 'statut', 'action'];
    fidelityes!: any[];
    dataSource = new MatTableDataSource<any>([]);
    demandes: Demande[] = []
    inProgress = false;
    inSearchProgress = false;

    searchText = '';

    @ViewChild(MatSort, { static: true }) sort!: MatSort;

    constructor(
        public dialog: MatDialog,
        private modal: ModalService,
        private router: Router,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private demandeService: DemandeService
    ) { }

    ngOnInit() {
        this.load();
    }

    load() {
        this.inProgress = true; // Démarre le loader
        this.demandeService.query({ 'utilisateur._id': this.storage.get(USER)._id, app: APP_CODE  }).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            this.demandes = result;
            this.dataSource = new MatTableDataSource<any>(this.demandes);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    search(searchText: string) {
        console.log(searchText)
        const result = this.demandes.filter(item => item.distributeur.raisonSocial.includes(searchText));
        this.dataSource = new MatTableDataSource<any>(result);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    goToState(root: string) {
        this.router.navigate([root]);
    }

    select(element: any) {
        this.router.navigate(['f/souscription/form/', element._id]);
    }

    goToRenouvellement(element: any) {
        this.router.navigate(['f/souscription/form/renouvellement/', element._id]);
    }

    applyFilter() {
        this.dataSource.filter = this.searchText;
    }

    openFile = (file: any) => {
        window.open(file.url);
    }

}
