import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-over-spinner',
  templateUrl: './over-spinner.component.html',
  styleUrls: ['./over-spinner.component.scss']
})
export class OverSpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
