<mat-nav-list  fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="center center"  fxFlex >
        <p class="mat-title no-wrap">{{data.message}}</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center"  fxFlex >
        <a mat-list-item (click)="valid($event)">
            <mat-icon mat-list-icon>check_circle</mat-icon>
            <span mat-line>Confirmer</span>
            <span mat-line>Cliquer ici pour confirmer</span>
          </a>
        
          <a mat-list-item (click)="cancel($event)">
            <mat-icon mat-list-icon>cancel</mat-icon>
            <span mat-line>Annuler</span>
            <span mat-line>Cliquer ici pour annuler</span>
          </a>
    </div>
    
</mat-nav-list>
  