<div fxLayout="row" class="defaultTheme" fxFill>
    <div  fxLayout="column" style="background:rgb(247, 247, 247);" fxFlex>
        <mat-toolbar color="primary"  fxLayout="column" fxLayoutAlign="center center" fxFlex>
            <img src="../../../assets/logo_1.png" height="80"/>
        </mat-toolbar>
        
    </div>
    <mat-card  fxFlex>
        <router-outlet></router-outlet>
    </mat-card>
</div>