
<mat-card fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar color="primary"class="mat-elevation-z0">
        <button  mat-icon-button>
            <mat-icon>more_horiz</mat-icon>
        </button>
        <div fxlayout="column" fxFlex="20" style="padding:10px;">
            <div>Changer de mot de passe</div>
        </div>
        <div fxFlex></div>
        <div fxLayout="row">
            <button (click)="close()"  mat-stroked-button mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>        
        </div>
    </mat-toolbar>

    <mat-card fxLayout="column" matPadding  fxFlex>
        
        <div fxLayout="column" matPadding fxFlex>
            <!-- Overlay de Connexion -->
            <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
                <mat-spinner diameter="60"></mat-spinner>
                <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
            </div>
            <!-- Fin Overlay -->
            <form [formGroup]="iForm" class="inscription-form mat-padding" fxFlex  fxLayout="column" matPadding fxFlex>
                <div fxLayout="column">
                    <mat-form-field >
                        <mat-label>Mot de passe actuel</mat-label>
                        <mat-icon matSuffix>more_horiz</mat-icon>
                        <input matInput type="password"  placeholder="Entrez votre mot de passe actuel" formControlName="pass"  autocomplete="off">
                    </mat-form-field>
                </div>
                <div matPaddingSmall></div>
                <div fxLayout="column">
                    <mat-form-field  fxFlex>
                        <mat-label>Nouveau mot de passe</mat-label>
                        <mat-icon matSuffix>more_horiz</mat-icon>
                        <input matInput type="password"  placeholder="Entrez votre nouveau mot de passe" formControlName="newpass" autocomplete="off">
                    </mat-form-field>
                    <mat-form-field  fxFlex>
                        <mat-label>Confirmez le mot de passe</mat-label>
                        <mat-icon matSuffix>more_horiz</mat-icon>
                        <input matInput  type="password" placeholder="Confirmez le mot de passe" formControlName="cpass"  autocomplete="off">
                        <mat-error *ngIf="iForm.controls.newpass.value != iForm.controls.cpass.value">La confirmation du nouveau mot de passe est incorrecte</mat-error>
                    </mat-form-field>
                </div>
            </form>  
        </div>

        <div matPadding>
            <div matPadding  fxLayout="row" fxLayoutAlign="center center"  fxFlex>
                
                <div matPaddingSmall matRounded fxFlex></div>
                
                <button matRounded mat-button color="accent">
                    <mat-icon>cancel</mat-icon><span matPaddingSmall></span><span>Annuler</span>
                </button>
                <div matPaddingSmall></div>
                <button [disabled]="iForm.invalid" matRounded rightIcon  mat-flat-button (click)="save()" color="primary">
                  <span matPaddingSmall></span><span>Valider</span><span matPaddingSmall></span>
                  <mat-icon >arrow_right_alt</mat-icon>
                </button>
  
            </div>
  
        </div>
        
    </mat-card>
    
    
</mat-card>