import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Entreprise } from '../../interfaces/entreprise.interface';

@Injectable()
export class EntrepriseService {

    private url = `${environment.api}/entreprise/`;
    constructor(private http: HttpClient) { }

    save(entreprise: Entreprise): Observable<any> {
        return this.http.post<any>(`${this.url}`, entreprise);
    }

    update(entreprise: Entreprise): Observable<any> {
        return this.http.put<any>(`${this.url}`, entreprise);
    }

    delete(primaryKey: any): Observable<any> {
        return this.http.delete<any>(`${this.url}/${primaryKey}`, );
    }

    query(queryParameter: any): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api}/entreprise/query/${encodeURI(JSON.stringify(queryParameter))}`);
    }

    aggregate(param: any): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api}/entreprise/aggregate/${encodeURI(JSON.stringify(param))}`);
    }
    // Trouve un seul enregistrements de TypeEntity
    findOne(primaryKey: any): Observable<any> {
        return this.http.get<any>(`${environment.api}/entreprise/${encodeURI(JSON.stringify(primaryKey))}`);
    }
}
