import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { timeout, startWith, debounceTime, switchMap, finalize } from 'rxjs/operators';


import { AuthentificationService } from '../../services/auth/authentification.service';
import { } from 'src/app/interfaces/authentification.interface';
import { Observable, of } from 'rxjs';
import { USER, TOKEN, APPLICATIONS, GROUPES, FONCTIONNALITES } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

interface Error { code: string; }
interface Progress { loading: boolean; label: string; error: boolean; }

@Component({
    selector: 'app-verify-account',
    templateUrl: './verify-account.component.html',
    styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {


    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};

    public form!: FormGroup;
    public inProgress!: boolean;

    private uuid!: string;
    public user: any = { email: '' };


    public progress: Progress = { loading: false, label: '', error: false }; // Progression du
    public error: Error = { code: '' }; // Les erreurs du formulaire


    constructor(private route: ActivatedRoute,
        public fb: FormBuilder,
        @Inject(LOCAL_STORAGE) private storage: StorageService,

        private authService: AuthentificationService, private router: Router) { }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.uuid = this.storage.get(USER)._id;
            // Initialisation du formulaire
            this.form = this.fb.group({
                _id: new FormControl(this.uuid, [Validators.required]),
                code: new FormControl('', [Validators.required]),
            });
            //this.getUser(this.uuid);
        });
    }

    private getUser(uuid: string) {
        /*
      this.progress = { loading : true, label: `Récuperation des données de l'utilisateur ...`, error: false };
      this.authService.getUserByUUID(uuid).subscribe(
          (result: any) => {
              this.user = result[0];
              // Affichage du loader
              setTimeout(() => {
                  this.progress = { loading : false, label: '', error: false };
              }, 3000 );
          },
          (error: any) => {
              // Masquage du loader
              setTimeout(() => {
                  this.progress = { loading : false, label: `Erreur sur le compte veuillez rafraichir la page`, error: true };
                  const { code } = error.status;
                  // this.error.code = code === 409 ? error.status.error : null;
              }, 3000 );
          }
      );
      */
    }

    sendActivationCode(): void {
        /*
        this.progress = { loading : true, label: `Transmission en cours ...`, error: false };
        this.authService.sendActivationCode(this.uuid).subscribe(
            (result: any) => {
                this.user = result;
                // Affichage du loader
                setTimeout(() => {
                    this.progress = { loading : false, label: '', error: false };
                }, 3000 );
            },
            (err: any) => {
                const { code, error } = err.status;
                // Masquage du loader
                setTimeout(() => {
                    this.progress = { loading : false, label: error, error: true };
                    // this.error.code = code === 409 ? error.status.error : null;
                }, 3000 );
            }
        );
        */
    }

    verify() {

        this.progress = { loading: true, label: `Vérification en cours ...`, error: false };
        this.authService.verifyAccount(this.form.value).subscribe(
            (result: any) => {
                this.user = result;
                // Affichage du loader
                const [token, utilisateur, groupes, applications, fonctionnalites] = result;
                
                this.storage.set(TOKEN, token);
                this.storage.set(USER, utilisateur);
                this.storage.set(APPLICATIONS, applications);
                this.storage.set(GROUPES, groupes);
                this.storage.set(FONCTIONNALITES, fonctionnalites);

                setTimeout(() => {
                    this.progress = { loading: false, label: '', error: false };
                    if (this.user.nom == null) {
                        this.router.navigate(['/a/setting-account']);
                    } else {
                        this.router.navigate(['/m']);
                    }
                }, 3000);
            },
            (err: any) => {
                const { code, error } = err.status;
                // Masquage du loader
                setTimeout(() => {
                    this.progress = { loading: false, label: error, error: false };
                    this.error.code = code !== 200 ? error : null;
                }, 3000);
            }
        );

    }

    doGoogleLogin() {

    }

}