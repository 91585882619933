import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
//import { FEATURES } from 'src/app/app.constant';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor( public router: Router, @Inject(LOCAL_STORAGE) private storage: StorageService) {}
    canActivate(route: ActivatedRouteSnapshot): boolean {
        
        /*const fonctionnalites: any[] = this.storage.get(FEATURES)
        const role = route.data.role;
        
        if(!fonctionnalites.includes(role)){
            //this.router.navigate(['m/access-denied']);
            //return false;
        }*/

        return true;
    }
}