import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';


import { ModalService, ParamDialog } from '../../../core/services/modal.service';
import { Modele } from 'src/app/interfaces/modele.interfce';
import { ParametreGlobalService } from 'src/app/services/settings/parametre-global.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ModeleService } from '../../../services/database/modele.service';
import { ValeurService } from '../../../services/settings/valeur.service';
import { Piece } from 'src/app/interfaces/piece.interface';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { APP_CODE, ENTREPRISE, ORGANISATION, USER, _PROGRESSER_, _REJETER_, _VALIDER_ } from 'src/app/app.constant';
import { Valeur } from 'src/app/interfaces/valeur.interface';
import { WorkflowService } from 'src/app/services/workflow/workflow.service';
import { environment } from 'src/environments/environment';
import { MComponent } from '../m.component';
interface Progress { loading: boolean; label: string; }
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Organisation } from 'src/app/interfaces/organisation.interface';
import { DocumentDemande } from 'src/app/statistic';

import * as html2pdf from 'html2pdf.js'
import * as Editor from '../../../components/ckeditor';
import LineHeight from 'ckeditor5-line-height-plugin/src/lineheight';

@Component({
    selector: 'app-modele-document-details',
    templateUrl: './modele-document-details.component.html',
    styleUrls: ['./modele-document-details.component.scss']
})
export class ModeleDocumentDetailsComponent implements OnInit {

    bModePreview:boolean = false;
    organisation: Organisation = <Organisation>{ code: null };

    public Editor = Editor;
    public config = {
        toolbar: {
            items: [
                //'exportPdf',
                //'|',
                'fontSize',
                'fontFamily',
                '|',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                'underline',
                '|',
                'lineHeight',
                '|',
                'strikethrough',
                '|',
                'alignment',
                '|',
                'numberedList',
                'bulletedList',
                '|',
                'outdent',
                'indent',
                '|',
                'link',
                'blockQuote',
                'imageUpload',
                'insertTable',
                'mediaEmbed',
                '|',
                'findAndReplace',
                'undo',
                'redo',
                'pageBreak',
                'horizontalLine',
                'imageInsert'
            ]
        },
        language: 'fr',
        image: {
            toolbar: [
                'imageTextAlternative',
                'toggleImageCaption',
                'imageStyle:inline',
                'imageStyle:block',
                'imageStyle:side',
                'linkImage'
            ]
        },
        table: {
            contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableCellProperties',
                'tableProperties'
            ]
        }
    };
    
    public onReady( editor ) {
        console.log(editor)
        editor.ui.getEditableElement().parentElement.insertBefore(
            editor.ui.view.toolbar.element,
            editor.ui.getEditableElement(),
        );
    }

    pdf: any = null;
    report: Blob = new Blob();
    reporting: any = {};
    types: Valeur[] = [];
    ServiceAnnexe: string[] = ['Vente de lubrifiant', 'Boutique/Supermarché', 'Lavage (automatique, manuel)', 'Graissage', 'Eau /air', 'Service mobile money',
        'Banque', 'Patisserie'];
    panelOpenState = false;

    yourBoolean: string;

    cuves: any[] = [];

    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;

    venteGaz_selected: boolean;
    selectedOptions: string[];
    selected: boolean;

    public progress: Progress = { loading: false, label: null };
    modele: Modele = <Modele>{}
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
    displayedColumns: string[] = ['longueur', 'diametre', 'type', 'action'];
    displayedIlotColumns: string[] = ['nbredistributeur', 'typedistributeur', 'typeproduit', 'nbrepistolet', 'action'];
    dataSource = new MatTableDataSource<any>([]);
    @ViewChild(MatPaginator, {}) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private modal: ModalService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        @Inject(MComponent) public parentM: MComponent,
        private route: ActivatedRoute,
        private modeleService: ModeleService,
        private workflowService: WorkflowService,
        private _snackBar: MatSnackBar,
        public fb: FormBuilder,
        private valeurService: ValeurService,
        private router: Router,
        private _formBuilder: FormBuilder,
        public dialog: MatDialog,
        //Rm
        private valueService: ValeurService

    ) { }

    @ViewChild('scrollable') private scrollable: ElementRef;

    //public arreteCreation: ArreteCreation = <ArreteCreation>{};
    //public attestationSuivi: AttestationSuivi = <AttestationSuivi>{ chronogramme: null };
    public form: FormGroup;
    public inProgress: Boolean = false;
    public isLinear: Boolean = false;
    public statutBrouillon: Valeur = null;
    public classifications: Valeur[] = [];
    public documentText: string = null;
    //
    ngOnInit() {
        this.organisation = this.storage.get(ORGANISATION) ? this.storage.get(ORGANISATION) : <Organisation>{ code: null };
        this.buildForm();
        this.load();
        this.loadValues(()=>{
        });

        this.route.paramMap.subscribe(params => {
            const id = params.get('id');
            if (id) {
                this.loadFormData(id);
            }
        });
    }

    buildForm() {
        this.form = this.fb.group({
            _id: new FormControl(null),
            app: new FormControl('app.agrement'),
            libelle: new FormControl(null, [Validators.required]),
            description: new FormControl(null),
            document: new FormControl({}),
            documentText:new FormControl(null),
            type: new FormControl(null, [Validators.required])
        });
    }


    private prepareDocument = (modele) => {
        this.modele = this.form.value;
        console.log(this.modele.type)
        switch(this.modele.type){
            case '_ARRETE_':{
                //this.reporting = DocumentDemande.ArreteCreationTemplateDocument;
                break;
            }
            case '_REJET_':{
                //this.reporting = DocumentDemande.AttestationVisiteCreationTemplateDocument;
                break;
            }
            case '_NOTE_':{
                //this.reporting = DocumentDemande.AutorisationExploitationTemplateDocument;
                break;
            }
            default:{
                //this.reporting = DocumentDemande.AttestationVisiteCreationTemplateDocument;
                break;
            }
        }

        const { template, injector } = this.reporting.template
        const documentDefinition = injector(template, {document: modele}, this.reporting.model);
        this.generate(documentDefinition);
    }

    private generate = (documentDefinition) => {
        /*this.pdf = pdfMake.createPdf(documentDefinition);
        this.pdf.getBlob(async (blob) => {
            this.report = await blob.arrayBuffer();
        });*/
    }

    loadValues(fn: Function) {
        this.inProgress = true; // Démarre le loader
        this.valueService.query({  }).subscribe(result => {
            for(let i = 0; i<= result.length - 1 ; i++){
    
                const type: Valeur = result[i];
                switch(type.code){
                    case "CLS": {
                        this.classifications = type.valeurs;
                        break;
                    }
                }
            }
            fn();
        });
    }

    load() {
        this.dataSource = new MatTableDataSource<any>(this.cuves);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    loadFormData = (id) => {
        this.inProgress = true; // Démarre le loader
        this.modeleService.query({ _id: id }).subscribe(result => {
            this.inProgress = false; // stop le loader
            const modele: Modele = result[0];
            console.log(modele)
            this.modele = modele;
            this.documentText = this.modele.documentText;
            this.form.patchValue(modele);
            this.prepareDocument(modele?.document);
        });
    }

    save(): void {

        this.progress = { loading: true, label: null };
        this.modele = this.form.value;
        this.modele.documentText = this.documentText;
        console.log(this.modele)
        const send = (!this.modele._id) ? this.modeleService.save(this.modele) : this.modeleService.update(this.modele);

        send.subscribe(result => {
            setTimeout(() => {
                this.progress = { loading: false, label: null }; // stop le loader
                if (result) this._snackBar.open('Modele enregistré', 'OK', { duration: 2000 });
                this.goToState('/m/modele/list');
            }, 3000);
        });
    }

    editer(document) {
        const modele: Modele = this.form.value;
        this.modele =modele;
        console.log(modele.type)
        let component;
        switch(modele.type){
            case '_ARRETE_':{
                //component = ArreteDocumentComponent;
                break;
            }
            case '_REJET_':{
                //component = VisiteDocumentComponent;
                break;
            }
            case '_NOTE_':{
                //component = AutorisationDocumentComponent;
                break;
            }
            default:{
                //component = VisiteDocumentComponent;
                break;
            }
        }
        const dialogRef = this.dialog.open(component, {
            width: '800px',
            height: '700px',
            disableClose: true,
            data: {document},
        })
        .afterClosed()
        .subscribe((tpl) => {
            if(tpl){
                console.log(tpl)
                this.modele.document = tpl;
                this.form.patchValue(this.modele)
                this.prepareDocument(tpl);
            }
        });
    }


    goToState(root: string) {
        this.router.navigate([root]);
    }

    openFile = (file) => {
        console.log(file)
        window.open(file.url);
    }


    compareFnCode(optionOne, optionTwo): boolean {
        return optionOne.code === optionTwo.code;
    }


    print() {
        /*if (this.pdf) {
            this.pdf.print();
        }*/
        var element = document.getElementById('toPrint');
        /*var myWindow = window.open("", "MsgWindow", "width=200,height=100");
        var content = `
            <div  class="preview ck-blurred ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline">
                <div id="toPrint">
                    ${this.documentText}
                </div>
            </div>`;
        myWindow.document.write(content);*/

        var opt = {
            margin:       1, //top, left, buttom, right
            filename:    't.pdf',
            image:        { type: 'jpeg', quality: 0.98 },
            html2canvas:  { dpi: 192, scale: 2, letterRendering: true, useCORS: true},
            jsPDF:        { unit: 'pt', format: 'a4', orientation: 'portrait'},
            pageBreak:  { mode: 'css', after:'.break-page'}
        };
       

        // New Promise-based usage:
        html2pdf().from(element).set(opt).save();

        
    }

}
