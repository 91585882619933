import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-barometer',
  templateUrl: './barometer.component.html',
  styleUrls: ['./barometer.component.scss']
})
export class BarometerComponent implements OnInit {
    //
    public chartWidth = 300;
    public needleValue = 30;
    public centralLabel = '';

    public options = {
        hasNeedle: true,
        outerNeedle: false,
        needleColor: 'black',
        needleStartValue: 82,
        needleUpdateSpeed: 8,
        arcColors: ['rgb(61,204,91)', 'rgb(239,214,19)', 'rgb(255,84,84)'],
        arcDelimiters: [40, 60],
        rangeLabel: ['52', '8'],
    };
    
    constructor() { }

    ngOnInit(): void {
    }

}
