import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
//import { Message } from '../../interfaces/';

@Injectable()
export class NotificationService {

    constructor(private http: HttpClient) { }

    update(message: any): Observable<any> {
        return this.http.put<any>(`${environment.api}/notification`, message);
    }
    
    query(queryParameter: any): Observable<any> {
        return this.http.get<any[]>(`${environment.api}/notification/query/${encodeURI(JSON.stringify(queryParameter))}`);
    }

    aggregate(params: any): Observable<any> {
        return this.http.get<any[]>(`${environment.api}/notification/aggregate/${encodeURI(JSON.stringify(params))}`);
    }

    delete(primaryKey: any): Observable<any> {
        return this.http.delete<any>(`${environment.api}/notification/${primaryKey}`);
    }
}
