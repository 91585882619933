import { RejeteTemplate } from '../templates/rejete.tpl';

export const RejetDocument = {
    id: 'Bilan',
    template: RejeteTemplate,
    model: {
        title: 'Bilan',
        description: 'Quantité des produits pétroliers enlevées des dépôts par camion et par marketeUr',
        pageOrientation: 'landscape',
        columnsHeader:[],
        columnsSize:[],
        query: (params: any) => {
            return[]
            ;
        },
        params: (fluid:string) => [],
        sums:(fluid:string) => []
    }
};