<mat-card class="defaultTheme" fxLayout="column" fxFill>
    <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner diameter="60"></mat-spinner>
        <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
    </div>
    <mat-toolbar>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
            <div fxLayout="row" fxFlex="35">
                <div fxFlex></div>
                <button    matTooltip="Actualiser" mat-stroked-button mat-icon-button (click)="getMessages()">
                    <mat-icon>refresh</mat-icon>
                </button>
                <div matPaddingSmall></div>
                <div class="bloc-search mat-stroked-button">
                    <input [(ngModel)]="searchText" type="text" (keyup)="applyFilter()" />
                    <button matTooltip="Lancer la recherche" mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div matPadding></div>
            </div>
            
            <div fxFlex>
                <div matPadding></div>
                <span>{{message.objet}}</span>
                <div fxFlex></div>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="delete(message)">
                        <mat-icon>remove</mat-icon> Supprimer le message
                    </button>
                </mat-menu>
            </div>
        </div>
    </mat-toolbar>
    <div fxLayout="row" fxFlex>
        <div fxLayout="column" matPadding fxFlex="35">
            <div fxLayout="column" fxFlex>
                <div matPadding style="font-weight: 100; font-size: 20px;">Boite de reception</div>
                <div fxLayout="column" style="overflow-y: auto;" fxFlex>
                    <table mat-table matSort [dataSource]="dataSource">
                        <ng-container matColumnDef="message">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef style="display: none;"></th>
                            <td mat-cell *matCellDef="let element">
                                <div fxLayout="row" matPadding>
                                    <div fxLayout="column" matPadding>
                                        <mat-icon mat-list-icon color="primary" *ngIf="!element.lu">mark_chat_unread</mat-icon>
                                        <mat-icon mat-list-icon *ngIf="element.lu" style="color: #636363c7;">mark_chat_read</mat-icon>
                                    </div>
                                    <div fxLayout="column">
                                        <div mat-line><span  *ngIf="!element.lu" style="font-weight: 500; font-size: 15px;">{{message.objet}}</span> <span *ngIf="element.lu" style="font-weight: 100; font-size: 15px;">{{element.objet}}</span></div>
                                        <div mat-line> {{stripHtml(element.contenu) | slice:0:30}}... </div>
                                        <div>{{ message.envoyeLe | date:'dd/mm/yyyy' }}</div>
                                    </div>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef style="display: none;"></th>
                            <td mat-cell *matCellDef="let element" class="action-column">
                                <div fxLayout="row" fxLayoutAlign="end center">
                                    <button class="action-button-datagrid" mat-icon-button matTooltip="Ouvrir"
                                        (click)="open(element)">
                                        <mat-icon>keyboard_arrow_right</mat-icon>
                                    </button>
    
                                    <button class="action-button-datagrid" mat-icon-button matTooltip="Supprimer"
                                        (click)="delete(element)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns" style="display: none;"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                
                <mat-paginator itemsPerPageLabel="test" [pageSizeOptions]="[30]" showFirstLastButtons ></mat-paginator>
            </div>
            <!--<mat-selection-list [multiple]="false"  style="overflow-y: auto;" fxFlex>
                <mat-list-option  *ngFor="let message of messages" (click)="open(message)">
                    
                </mat-list-option >
            </mat-selection-list> -->
        </div>
        <div fxLayout="column" matPadding fxFlex>
            <div fxLayout="column" *ngIf="message._id" matPadding fxFlex>
                <div fxLayout="row"  matPadding>
                    <mat-chip-list aria-label="Fish selection">
                        <mat-chip>{{message.emeteur}}</mat-chip>
                    </mat-chip-list>
                    <div fxFlex></div>
                    <div>{{ message.envoyeLe | date:'medium' }}</div>
                </div>
                <div  [innerHtml]="message.contenu" style="overflow-y: auto;"  fxFlex matPadding></div>
                <div style="background-color: #f5f5f5;" matPadding>
                    <div matPadding style="font-weight: 100; font-size: 20px;">Pièces jointes</div>
                    <div *ngFor="let attachment of message.attachments" >
                        <a mat-button mat-stroked-button matRounded (click)="goToState(attachment.data)"><span>Ouvrir le dossier {{attachment.type}}</span> " <span>{{attachment.data.numero}}</span> " </a>
                    </div>
                </div>
            </div>
            <div fxLayout="column"  *ngIf="!message._id" fxLayoutAlign="center center"  matPadding fxFlex>
                <mat-icon mat-list-icon style="font-size: 100px; width: 100px; height: 100px;" >message</mat-icon>
            </div>
        </div>
    </div>
</mat-card>
