import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthentificationService } from 'src/app/services/auth/authentification.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { TOKEN, USER, APPLICATIONS, GROUPES, FONCTIONNALITES } from 'src/app/app.constant';

@Component({
  selector: 'app-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.scss']
})
export class AuthentificationComponent implements OnInit {
    progress!: { loading: boolean; label: string; };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthentificationService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,



    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            const token = params.get('token');
            this.signin(token ? token : '');
        });
    }

    signin(token: string): void {

        this.progress = { loading: true, label: 'Connection en cours ...' };
        this.authService.signinToken({ token }).subscribe(
            (result: any) => {
                const [token, utilisateur, groupes, applications,fonctionnalites ] = result;
                this.storage.set(TOKEN, token);
                this.storage.set(USER, utilisateur);
                this.storage.set(APPLICATIONS, applications);
                this.storage.set(GROUPES, groupes);
                this.storage.set(FONCTIONNALITES, fonctionnalites);
                setTimeout(() => {
                    this.progress = { loading: false, label: '' };
                    if (!utilisateur.nom || !utilisateur.prenoms) {
                        //this.router.navigate(['/a/setting-account']);
                    }
                    else {
                        this.router.navigate(['/m']);
                    }

                }, 3000);
            },
            (error: any) => {
                // Masquage du loader
            }
        );
    }

}
