<div fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar color="primary">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
            <mat-icon>message</mat-icon>
            <div matPaddingSmall></div>
            <span fxFlex="20" style="padding:10px;">Observation</span>
        </div>
        <div fxLayout="row" >
            <button (click)="close()"  mat-stroked-button mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>
    <mat-card fxLayout="column"  matPadding fxFlex>
        <div fxLayout="column" matPadding fxFlex>
            <!--<quill-editor placeholder="Commentaire"></quill-editor>-->
            <textarea [(ngModel)]="observation" cols="30" rows="6" style="border: 1px solid #ccc; border-radius: 8px; padding: 8px;" fxFlex></textarea>
            
            <div matPadding></div>
            <div fxLayout="row" fxLayoutAlign="end center">
                <button matRounded rightIcon mat-flat-button (click)="Send()" [disabled]="!observation" color="primary">
                    <span matPaddingSmall></span><span>Valider</span><span matPaddingSmall></span>
                    <mat-icon>check</mat-icon>
                </button>
            </div>
        </div>
    </mat-card>
</div>