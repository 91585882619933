<div fxLayout="column" fxFill>
    <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>

    <mat-toolbar  class="mat-elevation-z4 mat-toolbar-white">
        <button mat-icon-button>
            <mat-icon>receipt_long</mat-icon>
        </button>
        <div fxlayout="column" fxFlex="20" style="padding:10px;">
            <div>Modèle de document</div>
        </div>
        <div fxFlex></div>
        <div fxLayout="row">
            <button color="primary" class="mat-button-rounded" (click)="goToState('/m/modele/details')"  mat-stroked-button mat-button>
                <mat-icon>add</mat-icon>
                <span class="mat-padding-small"></span>
                <span>Creer un modèle</span>
            </button>
            <div matPaddingSmall></div>
            <button mat-stroked-button matTooltip="Actualiser" (click)="load()" mat-icon-button>
                <mat-icon>refresh</mat-icon>
            </button>
            <div class="mat-padding-small"></div>
            <div class="bloc-search mat-stroked-button">
                <input [(ngModel)]="searchText" type="text" (keyup)="applyFilter()" />
                <button (click)="search(searchText)" color="primary" matTooltip="Lancer la recherche" mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <div class="mat-padding-small"></div>
            <!--<button mat-stroked-button mat-icon-button (click)="openForm()"  matTooltip="Nouvelle fidélisation">
                <mat-icon>add</mat-icon>
            </button>-->
        </div>
    </mat-toolbar>
    <div fxLayout="column" style="overflow-y: auto;" fxFlex>

        <table mat-table class="table-max-width" matSort [dataSource]="dataSource" fxFlex>
            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
            <!-- Position Column -->
            <ng-container matColumnDef="libelle">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Nom </th>
                <td mat-cell *matCellDef="let element"> {{element?.libelle}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Description </th>
                <td mat-cell *matCellDef="let element"> {{element?.description}}  </td>
            </ng-container>



            <ng-container matColumnDef="type">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Statut </th>
                <td mat-cell *matCellDef="let element">
                    <mat-chip-list>
                        <mat-chip color="accent" *ngIf="element?.type == '_ARRETE_'" selected>Projet d'arrêté</mat-chip>
                        <mat-chip color="accent" *ngIf="element?.type == '_REJET_'" selected>Courrier de rejet</mat-chip>
                        <mat-chip color="accent" *ngIf="element?.type == '_NOTE_'" selected>NOTE DE PRESENTATION</mat-chip>

                    </mat-chip-list>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> action </th>
                <td mat-cell *matCellDef="let element">
                    <div fxLayout="row">
                        <button *ngIf="!element.clientEdit" mat-flat-button color="primary"
                            class="action-button-datagrid" (click)="select(element)" matRounded matTooltip="Modifier">
                            <span>Consulter</span>
                            <span matPaddingSmall></span>
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>


    </div>
    <mat-paginator [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
</div>