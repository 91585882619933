import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { APPLICATIONS, USER } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService } from 'src/app/core/services/modal.service';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Distributeur } from 'src/app/interfaces/distributeur.interface';
import { CollaborationService } from 'src/app/services/database/collaboration.service';
import { DistributeurService } from 'src/app/services/database/distributeur.service';
import { Collaboration } from 'src/app/interfaces/collaboration.interface';
import { Entreprise } from 'src/app/interfaces/entreprise.interface';
import { EntrepriseService } from 'src/app/services/database/entreprise.service';
interface Progress { loading: boolean; label: string; }

@Component({
    selector: 'app-collaboration-form',
    templateUrl: './collaboration-form.component.html',
    styleUrls: ['./collaboration-form.component.scss']
})
export class CollaborationFormComponent implements OnInit {

    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};

    entreprise: Entreprise = <Entreprise>{};
    public progress: Progress = { loading: false, label: '' };

    applications = [];
    searchText!:string;
    inProgress:boolean = false;

    constructor(
        public dialogRef: MatDialogRef<CollaborationFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modal: ModalService,
        private collaborationService: CollaborationService,
        private entrepriseService: EntrepriseService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,

    ) { }

    ngOnInit(): void {
        this.applications = this.storage.get(APPLICATIONS);
    }

    search() {
        this.progress = { loading: true, label: '' }; // Démarre le loader
        this.entrepriseService.query({ codeDGH: this.searchText }).subscribe((result: any) => {
            setTimeout(()=>{
                this.progress = { loading: false, label: '' }; // stop le loader
                this.entreprise = (result.length == 1) ? result[0] : <Entreprise>{};
                console.log(this.entreprise,result)
            },3000);
        });
      }

    seCoupler() {
        this.progress = { loading: true, label: '' }; // Démarre le loader

        const collaboration: Collaboration = {
            _id: null,
            entreprise: this.entreprise,
            utilisateur: this.storage.get(USER)
        }
        this.collaborationService.save(collaboration).subscribe((result: any) => {
            setTimeout(()=>{
                this.progress = { loading: false, label: '' }; // stop le loader
                this.dialogRef.close(this.entreprise);
            },3000);
        });
    }

    close() {
        this.dialogRef.close();
    }
    applyFilter() {
        //this.dataSource.filter = this.searchText;
    }



}
