import { Directive, ElementRef, Renderer2, HostBinding, AfterViewInit, EventEmitter, Output, Input } from '@angular/core';
declare var TL: any;

@Directive({
  selector: '[timeline]',
})
export class TimelineDirective   implements AfterViewInit {

    @Output() onSelect = new EventEmitter<string>();
    @Input() timeData = {};

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        //this.el.nativeElement.style.padding = '0px 8px 0px 14px';
        var sample_json = {
            events: [
                {
                    start_date: {
                        month: "1",
                        day: "1",
                        year: "2017"
                    },
                    end_date: {
                        month: "1",
                        day: "2",
                        year: "2017"
                    },
                    text: {
                        headline: "Sun Release",
                        text: "Released Sun"
                    },
                    group: "Sun"
                },
                {
                    start_date: {
                        month: "2",
                        day: "4",
                        year: "2017"
                    },
                    text: {
                        headline: "Sun Release",
                        text: "Released Sun again"
                    },
                    group: "Sun"
                },
                {
                    start_date: {
                        month: "1",
                        day: "16",
                        year: "2017"
                    },
                    text: {
                        headline: "Auth Release",
                        text: "Released Authentication Service"
                    },
                    group: "Authentication"
                }
            ]
        };
      
        const timeline = new TL.Timeline(this.el.nativeElement, this.timeData );
        //console.log(timeline);
    }
}