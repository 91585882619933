<!--<div class="uploader" fxLayout="row" fxLayoutAlign="start center" fxFlex>
  
    <div class="square" *ngFor="let file of fileSelected" fxLayout="column"  [style]="file.style" fxFlex>
        
        <a class="mat-caption close" (click)="deleteSelectedFile()"> X </a>
        <div class="icon-file"  fxLayout="column" fxLayoutAlign="center center" fxFlex>
             <img *ngIf="iconeFile != 'jpg' && iconeFile != 'png' && iconeFile != 'svg'" src="../../../../assets/file-type/{{iconeFile}}.png"/>
                <img class="image-preview" *ngIf="iconeFile == 'jpg' || iconeFile == 'png'  || iconeFile == 'svg'" src="{{imageB64}}"/>
            
        </div>
            <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
        
            <div class="info-file" fxLayout="row" fxLayoutALign="end center" fxFlex>
                <div fxLayout="column" fxFlex>
                    <div><a href="{{imageB64}}" target="_blank" fxFlex><span class="mat-body-2">{{file.name}} {{iconeFile}}</span></a></div>
                    <div class="mat-caption"><span>{{file.size / 1000000 | number}} M</span></div>
                </div>
                <button mat-stroked-button mat-icon-button  (click)="selectFile()">
                    <mat-icon>attach_file</mat-icon>
                </button>
            </div>  
             
        </div>
    <div  class="upload-button" fxLayout="column" fxLayoutAlign="center center" fxFlex>
        <button class="square" mat-stroked-button (click)="selectFile()" fxFlex>
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
    
</div>-->

<div *ngIf="mode != 'photo'" fxLayout="column"  class="defaultTheme upload" style="margin: 5px;"  fxFlex>
    <div fxLayout="row" class="field" fxLayoutAlign="start center" fxFlex>
        <div matPaddingSmall *ngIf="!fichier?._id" style="text-align: center;" fxFlex><span style="flex: 1 1 0%; box-sizing: border-box; padding: 10px;">{{placeholder}}</span></div>
        <div matPaddingSmall *ngIf="fichier?._id" style="text-align: center;"  fxFlex>{{fichier?.nom}}</div>
        <div matPaddingSmall *ngIf="fichier?._id" ><span>{{fichier?.taille / 1000000 | number}} M</span></div>
        <div  fxLayout="row" class="mat-body-1" fxLayoutAlign="end center">
            
            <button mat-icon-button *ngIf="fichier?._id" [matMenuTriggerFor]="menuFile">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menuFile="matMenu"  xPosition="before">
                <button  *ngIf="fichier?._id"  matTooltip="Télécharger"  mat-menu-item (click)="openFile(fichier)"> <mat-icon>download</mat-icon> Télécharger</button>
                <button  *ngIf="fichier?._id" [disabled]="disabled" mat-menu-item matTooltip="Supprimer"  (click)="deleteSelectedFile(fichier)"><mat-icon>backspace</mat-icon> Supprimer</button>
            </mat-menu>

            <button  *ngIf="!fichier?._id" [disabled]="disabled" mat-flat-button mat-stroked-button  style="border-top-left-radius: 0px;" matRounded (click)="selectFile()">
                <div  fxLayout="row"  fxLayoutAlign="start center">
                    <mat-spinner *ngIf="inProgress" mode="indeterminate" diameter="25"></mat-spinner>
                    <mat-icon *ngIf="!inProgress"  >attach_file</mat-icon>
                    <span matPaddingSmall></span>
                    <span>Parcourir</span>
                    <span matPaddingSmall></span>                    
                </div>

            </button>
        </div>
    </div>
    <!--<mat-progress-bar  *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>-->
</div>


<div *ngIf="mode == 'photo'" fxLayout="column" style="margin: 5px;"  fxFlex>
    <div  fxLayout="column" class="mat-caption" fxLayoutAlign="start center" fxFlex>
        <img *ngIf="fichier" [src]="fichier.url" style="height:200px; border-radius: 200px;"/>
        <span matPaddingSmall *ngIf="fichier?._id"></span>
        
        <div  fxLayout="row" class="mat-body-1" fxLayoutAlign="end center" fxFlex>
            <button matRounded mat-stroked-button mat-button color="accent" [disabled]="disabled" *ngIf="!fichier?._id" (click)="selectFile()">
                <div  fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon *ngIf="!inProgress">insert_photo</mat-icon><mat-spinner *ngIf="inProgress" mode="indeterminate" diameter="25"></mat-spinner><span matPaddingSmall></span><span>{{placeholder}}</span>
                    <span matPaddingSmall></span>
                </div>
            </button>
            <span matPaddingSmall></span>
            <button  *ngIf="fichier?._id"  mat-icon-button matTooltip="Supprimer" [disabled]="disabled"  color="warn" (click)="deleteSelectedFile(fichier)">
                <mat-icon>delete</mat-icon>
            </button>

        </div>
    </div>
</div>