import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { timeout } from 'rxjs/operators';
import { PerfectScrollbarConfigInterface, PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { AuthService } from '../../services/auth/auth.service';
import { Signup } from 'src/app/interfaces/authentification.interface';
import { Router } from '@angular/router';
import { TOKEN, USER } from 'src/app/app.constant';
import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { AuthentificationService } from 'src/app/services/auth/authentification.service';

interface Error { email: string; password: string; }
interface Progress { loading: boolean; label: string; }

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};
    public showPassword!: Boolean;
    public showCPassword!: Boolean;

    public form!: FormGroup;

    public progress: Progress = { loading: false, label: '' }; // Progression du
    public error: Error = {email: '', password: ''}; // Les erreurs du formulaire

    constructor(
        public fb: FormBuilder,
        private router: Router,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
         private authService: AuthentificationService
    ) { }

    ngOnInit() {
        this.progress = {loading : false, label: '' }; // Aucun chargement au départ
        this.form = this.fb.group({
            mail: new FormControl(null, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
            _id: new FormControl(null,),
            nom: new FormControl(null, [Validators.required]),
            prenoms: new FormControl(null, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            pass: new FormControl(null, [Validators.required]),
            cpass: new FormControl('', [Validators.required]),
        });
    }

    register(): void {
        const { mail, nom, prenoms, phone, pass, cpass } = this.form.value;
        this.error.password = pass !== cpass ? 'Les mots de passe sont incorrectes' : '';
        const inscription: Signup = { mail, pass,  nom, prenoms, phone };
        console.log(this.form.value, this.error)
        if (this.error.password === null || this.error.password === '') {
            this.progress = { loading : true, label: 'Connection en cours ...' };
            this.authService.signup(inscription).subscribe(
                (result: any) => {
                    // Affichage du loader
                    setTimeout(() => {
                        this.storage.set(USER, result);
                        this.router.navigate(['/a/verify-account']);
                        this.progress = { loading : false, label: '' };
                    }, 3000 );
                },
                (error: any) => {
                    // Masquage du loader
                    console.log(error),
                    this.error.email =error.status.error;
                    setTimeout(() => {
                        this.progress = { loading : false, label: '' };
                    }, 3000 );
                }
            );
        }
    }

}
