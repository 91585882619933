import { ResumeTemplate } from '../templates/resume.tpl';

export const ResumeDocument = {
    id: 'Bilan',
    template: ResumeTemplate,
    model: {
        title: null,
        description: null,
        pageOrientation: 'landscape',
        columnsHeader:[],
        columnsSize:[],
        query: (params: any) => {
            return[]
            ;
        },
        params: (fluid:string) => [],
        sums:(fluid:string) => []
    }
};