<div  class="mat-padding" fxLayout="column" fxLayoutAlign="center center" fxFill>
    <perfect-scrollbar  fxLayout="column" fxLayoutAlign="center center"  [config]="{}">
       

        <div fxLayout="column" fxLayoutAlign="center center" fxFill>
            <form [formGroup]="iForm" class=""  fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="center center" class="mat-padding">
                <span class="mat-headline">Inscription</span>
                </div>
                <div class="bloc-input" fxLayout="column"  fxFlex>
                    <mat-form-field appearance="fill" >
                        <mat-label>Nom</mat-label>
                        <input matInput  placeholder="Entrez votre nom" formControlName="nom"  autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="fill" >
                        <mat-label>Prenoms</mat-label>
                        <input matInput placeholder="Entrez votre prénom" formControlName="prenoms" autocomplete="off">
                    </mat-form-field> 
                    <mat-form-field appearance="fill" >
                        <mat-label>E-mail</mat-label>
                        <input matInput placeholder="Adresse mail" formControlName="mail" autocomplete="off" readonly>
                    </mat-form-field>  
                    <mat-form-field appearance="fill" >
                        <mat-label>Téléphone</mat-label>
                        <input matInput placeholder="Numero de téléphone" formControlName="phone" autocomplete="off">
                    </mat-form-field>         
                </div>
                <div class="mat-caption">Ces informations seront utilisé pour des messages personnalisés</div>
                <div matPaddingSmall></div>
                
                
                    
                <div matPaddingSmall></div>
                
                <button class="mat-button-rounded" color="primary" type="submit" [disabled]="iForm.invalid" mat-flat-button (click)="save()" mat-button>
                    Accepter et Continuer
                </button>                    
            </form>
        </div>
      <!-- Overlay de Connexion -->
      <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
          <mat-spinner diameter="60"></mat-spinner>
          <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
      </div>
      <!-- Fin Overlay -->

  </perfect-scrollbar>
</div>