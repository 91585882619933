import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Account, updatetPassword } from '../../interfaces/account.interface';

@Injectable()
export class AccountService {
    
    private url = `${environment.api}/access/account/`;
    constructor(private http: HttpClient) { }

    firstConnexion(account: Account): Observable<any> {
        return this.http.put<any>(`${this.url}first-connexion`, account);
    }

    getFirstGroup(account: Account): Observable<any> {
        return this.http.get<any>(`${this.url}first-group`);
    }

    updatePassword(updatePassword: updatetPassword): Observable<any> {
        return this.http.put<any>(`${this.url}update-password`, updatePassword);
    }


}
