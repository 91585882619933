<div fxLayout="column" class="defaultTheme route" fxFill>
    <div matPadding fxLayout="column" fxLayoutAlign="center center" fxFlex>
        <div class="mat-headline capitalize" style="text-align: center;">{{data.message}}</div>

        <div fxLayout="row" fxLayoutAlign="center center" matPadding>
            <button mat-flat-button matRounded color="primary"  (click)="valid()">
                <span class="mat-body-2 capitalize"> Confirmer</span>
            </button>
            <span matPaddingSmall></span>
            <button mat-flat-button matRounded (click)="cancel()">
                <span class="mat-body-2 capitalize">Annuler</span>
            </button>

        </div>
    </div>
</div>