<!--The content below is only a placeholder and can be replaced.-->
<div fxLayout="column"  class="defaultTheme"  fxFill>
    <mat-toolbar color="primary" color="primary" class="mat-elevation-z0">
      <div fxLayout="row" fxLayoutAlign="start center">
          <div fxlayout="column" style="padding:10px;">
              <div><img src="../../../assets/logo_1.png" height="40"/></div>
          </div>
          <div>/ <span>AGREMENT</span><br/><span class="mat-caption"></span> </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
        <!--<mat-chip-list>
          <mat-chip><mat-icon>place</mat-icon><div matPaddingSmall></div><span>Abidjan, yopougon selmer</span></mat-chip>
        </mat-chip-list>
        <div matPaddingSmall></div>
        <button mat-icon-button (click)="SearchModal()">
            <mat-icon *ngIf="!isLoading">search</mat-icon>
            <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
        </button>
        <div class="search-zone" fxLayout="row" fxLayoutAlign="start center">

            <input class="input-search mat-body-1" [formControl]="searchControl" type="text" [matAutocomplete]="auto" fxFlex/>
            <mat-autocomplete class="mat-autocomplete-result" #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)='goToPlace($event.option.value)'>
                <mat-option *ngFor="let place of places | async; let index = index" [value]="place" >
                  <mat-icon *ngIf="!isLoading">place</mat-icon>
                  <span class="mat-caption">{{place.name}}</span>
                </mat-option>
            </mat-autocomplete>
            <div fxLayout="row" fxLayoutAlign="start center">
              <button mat-icon-button>
                  <mat-icon *ngIf="!isLoading">place</mat-icon>
                  <mat-spinner *ngIf="isLoading" diameter="20"></mat-spinner>
              </button>
            </div>
        </div>-->
      </div>
      <div fxLayout="row">
        <!--<button class="mat-button-rounded"  routerLink="/m/activity" mat-button>
          <mat-icon>trending_up</mat-icon>
          <span matPaddingSmall></span>
          <span>activité</span>
        </button>
        <div matPaddingSmall></div>
        <button mat-icon-button  [matMenuTriggerFor]="notification" >
          <mat-icon>notifications</mat-icon>
        </button>
        <mat-menu #notification="matMenu" >
          <button mat-menu-item ><mat-icon>person</mat-icon> Paramètres personnels </button>
          <button mat-menu-item ><mat-icon>help</mat-icon> Aide </button>
          <mat-divider></mat-divider>
          <button  mat-menu-item class="mat-small" (click)="Deconnexion()" ><mat-icon>exit_to_app</mat-icon> Se déconnecter </button>
        </mat-menu>-->
        <div></div>
        <div matPaddingSmall></div>
          <div matPaddingSmall></div>
          <button class="mat-button-rounded"  (click)="openProfil()"  mat-button>
              <mat-icon>person</mat-icon>
              <span matPaddingSmall></span>
              <span>{{user.nom}} {{user.prenoms}} [ {{organisation.code |uppercase}} ]</span>
          </button>
            <div matPaddingSmall></div>
            <button mat-icon-button color="warn" [matMenuTriggerFor]="menuNotif">
                <mat-icon *ngIf="(nb) > 0" [matBadge]="(nb) |number: '1.0-0'" matBadgeColor="warn">notifications</mat-icon>
                <mat-icon *ngIf="(nb) == 0">notifications</mat-icon>
            </button>
            <mat-menu #menuNotif="matMenu">
                <button mat-menu-item matTooltip="Applications SYVAL" routerLink="/m/message" >
                    <mat-icon *ngIf="nb  > 0" [matBadge]="nb|number: '1.0-0'" >message</mat-icon>
                    <mat-icon *ngIf="nb  == 0" >message</mat-icon>
                    Boite de reception
                </button>
            </mat-menu>
          <!--<span matPaddingSmall></span>
          <button class="mat-button-rounded mat-large-button" mat-stroked-button color="primary" (click)="Inscription()">
            <mat-icon>person</mat-icon><span matPaddingSmall></span> <span>Inscription</span>
          </button>
          <span matPaddingSmall></span>
          <button class="mat-button-rounded mat-large-button" color="primary"  mat-flat-button (click)="Authentificator()" >
            <mat-icon>person_outline</mat-icon><span matPaddingSmall></span> <span>Connexion</span>
          </button>-->
          <span matPaddingSmall></span>
          <button mat-icon-button  [matMenuTriggerFor]="menu" >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" >
            
            <!--<button mat-menu-item ><mat-icon>help</mat-icon> Aide </button>
            <button mat-menu-item ><mat-icon>settings</mat-icon> paramètres de confidentialités </button>-->
            <mat-divider></mat-divider>
            <button  mat-menu-item class="mat-small" (click)="Deconnexion()" ><mat-icon>exit_to_app</mat-icon> Se déconnecter </button>
          </mat-menu>
      </div>
  </mat-toolbar>
  <div fxLayout="row" class="route"  fxFlex>

    <mat-sidenav-container fxFill>
      <mat-sidenav opened class="mat-elevation-z4" position="start" [mode]="'side'" [ngClass]="menuIsEpingle ? 'maxSide' : 'minSide'" #drawer>
        <app-side-menu [items]="menuItems" (callBack)="sideCallBack($event)" fxFill></app-side-menu>
        
        
      </mat-sidenav>

      <div fxFill>
          <router-outlet></router-outlet>
      </div>
    </mat-sidenav-container>
  </div>
</div>
