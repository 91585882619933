<div class="defaultTheme">
    <mat-toolbar color="primary">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
            
            <mat-icon>font_download</mat-icon>
            
            <div matPaddingSmall></div>
            <span fxFlex="20" style="padding:10px;">Liste de valeur</span>
        </div>
        <div fxLayout="row" fxFlex>
            <!--<input class="input-search" type="text" [matAutocomplete]="auto"/>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option>
                <img class="example-option-img" aria-hidden  height="25">
                <span>Hello</span> |
                <small>Comment allez vous</small>
                </mat-option>
            </mat-autocomplete>
            <button mat-stroked-button><mat-icon>search</mat-icon></button>-->
        </div>
        <div fxLayout="row">
            <button (click)="close()"  mat-stroked-button mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>        
        </div>
    </mat-toolbar>
    <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
    <mat-card>
        <form [formGroup]="form"  fxLayout="column">
            <mat-horizontal-stepper class="stepper-size" [linear]="false" #stepper>
            <mat-step>
                <ng-template matStepLabel>Type de valeur</ng-template>
                <div matPaddingSmall fxLayout="column">
                    
                        <mat-form-field  appearance="outline">
                            <input matInput placeholder="code" formControlName="code">
                            <mat-hint>Code de référence unique par catégorie</mat-hint>
                            <mat-error *ngIf="form.controls.code.invalid">Champ obligatoire</mat-error>
                        </mat-form-field>
                        
                        <mat-form-field  appearance="outline">
                            <input matInput placeholder="libelle" formControlName="libelle">
                            <mat-hint>Nom de la catégorie</mat-hint>
                            <mat-error *ngIf="form.controls.libelle.invalid">Champ obligatoire</mat-error>
                        </mat-form-field>
                
                        <mat-form-field  appearance="outline">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"></textarea>
                            <mat-hint>Decrivez brièvement la catégorie</mat-hint>
                        </mat-form-field>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Liste de valeur</ng-template>
                <div  fxLayout="column" formArrayName="valeurs" >
                        <div fxLayout="row" fxLayoutAlign="end center" fxFlex>
                            <button mat-stroked-button mat-icon-button (click)="add()" [disabled]="form.invalid"   matTooltip="Nouvelle valeur">
                                <mat-icon>add</mat-icon>
                            </button>
                            <div class="mat-padding-smal"></div>
                        </div>
                        <div matPaddingSmall></div>
                        <div class="dynamic-list-size" style="height: 200px; overflow-y: auto;" #scrollable>
                            
                            <div *ngFor="let valeur of form.get('valeurs')['controls']; let i = index">
                                <div [formGroupName]="i">
                                    <mat-form-field  appearance="outline">
                                        <input matInput placeholder="code" formControlName="code">
                                        <mat-hint>Code de la valeur</mat-hint>
                                        <mat-error *ngIf="form.get('valeurs')['controls'][i].invalid">Champ obligatoire</mat-error> 
                                    </mat-form-field>
                                    <mat-form-field  appearance="outline">
                                        <input matInput placeholder="libelle" formControlName="libelle" >
                                        <mat-hint>Nom de la valeur</mat-hint>
                                        <mat-error *ngIf="form.get('valeurs')['controls'][i].invalid">Champ obligatoire</mat-error>
                                    </mat-form-field>
                                    <button class="action-button-datagrid" mat-stroked-button   mat-icon-button  matTooltip="Supprimer"  (click)="delete(i)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-step>
        </mat-horizontal-stepper>
        <div matPadding fxLayout="row" fxLayoutAlign="end center" fxFlex>       
            <button matRounded [disabled]="form.invalid"  mat-stroked-button (click)="save()">
                <mat-icon >check_circle</mat-icon><span matPaddingSmall></span>
                <span>Valider</span>
            </button>
            <span matPaddingSmall></span>
            <button matRounded  (click)="close()" mat-stroked-button>
                <mat-icon>cancel</mat-icon><span matPaddingSmall></span><span>Annuler</span>
            </button>
            <span matPaddingSmall></span>
        </div>
        </form>
    </mat-card>
</div>
