import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { APPLICATIONS, _PROGRESSER_, _RETOURNER_ } from 'src/app/app.constant';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalService, ParamDialog } from 'src/app/core/services/modal.service';
import { PerfectScrollbarComponent, PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Utilisateur } from 'src/app/interfaces/utilisateur.interface';
import { Router } from '@angular/router';
import { UtilisateurService } from 'src/app/services/database/utilisateur.service';
import { MatSort } from '@angular/material/sort';
import { Passation } from 'src/app/interfaces/passation.interface';
import { PassationService } from 'src/app/services/database/passation.service';
import { ORGANISATION, USER } from '../../app.constant';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Demande } from 'src/app/interfaces/demande.interface';
import { Suivi } from 'src/app/interfaces/suivi.interface';
import { Mailtrap } from 'src/app/interfaces/mailtrap.interface';
import { MailtrapService } from 'src/app/services/database/mailtrap.service';

@Component({
    selector: 'app-transmit',
    templateUrl: './transmit.component.html',
    styleUrls: ['./transmit.component.scss']
})
export class TransmitComponent implements OnInit {

    public selectedCheckValue: any;
    public passations: any[] = [];
    public form!: FormGroup;

    utilisateur = { _id: null, nom: null, prenoms: null, code: null, mail: null };

    public passation: Passation = <Passation>{}

    inProgress = false;
    inSearchProgress = false;
    users = [];

    public dests: Utilisateur[] = [];
    public destinataires: Utilisateur[] = [];
    public mandataires: Utilisateur[] = [];
    public demande!: Demande;
    public action!: string;
    public level!: string;
    public message!: string;


    @ViewChild(MatStepper, { static: true }) stepper!: MatStepper;
    @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
    @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};


    searchText = '';

    typeChoises = [
        { label: "Déléguation", value: "delegation" },
        { label: "Intérim", value: "interim" }
    ];

    fluid: any;
    progress: { loading: boolean; label: string; } = { loading: false, label: '' };
    edit: any;

    constructor(
        public dialogRef: MatDialogRef<TransmitComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private modal: ModalService,
        public fb: FormBuilder,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private _snackBar: MatSnackBar,
        private router: Router,
        public utilisateurService: UtilisateurService,
        public passationService: PassationService,
        private mailtrapService: MailtrapService,

    ) { }

    ngOnInit(): void {
        this.utilisateur = this.storage.get(USER);
        const { action, demande } = this.data;
        this.demande = demande;
        this.action = action;
        
        this.load(action, demande);
    }



    loadPassations() {
        this.inProgress = true; // Démarre le loader
        this.passationService.query(
            {
                'responsable._id': this.utilisateur._id
            }
        ).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const { status, data } = result as any;
            this.passations = result;
        });
    }

    load(action: string, demande: Demande) {

        let level:string = '';
        const { niveau, workflow } = demande;

        switch(action){

            case _PROGRESSER_ : {
                for(let i = 0; i<= workflow.etapes.length - 1; i++){
                    const etape = workflow.etapes[i];
                    if(etape == niveau){
                        if(i < workflow.etapes.length - 1){
                            level = workflow.etapes[i+1]
                        }
                        break;
                    }
                }
                break;
            }

            case _RETOURNER_ : {
                console.log(workflow)
                for(let i = 0; i<= workflow.etapes.length - 1; i++){
                    const etape = workflow.etapes[i];
                    if(etape == niveau){
                        if(i > 0){
                            level = workflow.etapes[i-1];
                        }
                        break;
                    }
                }
                break;
            }

        }
        this.level = level;
        this.utilisateurService.query({ 'organisation.code' : level }).subscribe(utilisateurs => {
            this.destinataires = utilisateurs;
            for(let i = 0; i<= this.destinataires.length - 1; i++){
                let  { _id } = this.destinataires[i];
                this.passationService.mandataire(_id).subscribe(passations => {
                    for(let j = 0 ; j<= passations.length - 1; j++) {
                        const passation = passations[i];
                        passation.mandataire.isMandataire = true
                        this.destinataires.push(passation.mandataire);
                    }
                });
            }
        });


    }

    getSubalterne(event:any, fn?: Function): void {
        let query;
        if (event.value === 'delegation') {
            query = { 'organisation.hierachie': this.storage.get(ORGANISATION).code };
            const mandat: Passation = this.form.value;
            mandat.imputation = true; mandat.signature = false;
            this.form.patchValue(mandat);
        }
        else {
            query = { 'organisation.parent.code': this.storage.get(ORGANISATION).hierachie, mail: { $ne: this.utilisateur.mail } };
            const mandat: Passation = this.form.value;
            mandat.imputation = true; mandat.signature = true;
            this.form.patchValue(mandat);
        }

        this.utilisateurService.query(query).subscribe((result: any) => {
            this.inProgress = false; // stop le loader
            const { status, data } = result as any;
            this.users = result;

            if (fn) fn();

        });

    }



    save(): void {
        this.demande.niveau = this.level;
        this.demande.workflow.etape = this.level;

        

        for(let i = 0; i<=  this.demande.workflow.suivi.length - 1; i++){
            this.demande.workflow.suivi[i].action = false;
            this.demande.workflow.suivi[i].imputation = false;
        }

        for(let i = 0; i<=  this.dests.length - 1; i++){
            const utilisateur = this.dests[i];

            //if not exist
            this.demande.workflow.visibilite.push(utilisateur);

            this.demande.workflow.suivi.push(<Suivi><unknown>{
                    action: true, imputation: true,
                    responsable: this.utilisateur,
                    mandataire: utilisateur,
                    commentaire: this.message,
                    date: new Date()
            });

            //Envoi de mail au concerné
            this.mailtrapService.send(<Mailtrap> {
                from:{  email:"erp@dgh.ci","name":"ERP DGH"},
                to:[
                       {    email:utilisateur.mail}
                   ],
                subject:"La demande",
                text:`
                   Bonjour M./Mme/Mlle ${utilisateur.nom} ${utilisateur.prenoms}
                   Le dossier N° ${this.demande.numero} vous a été transmis par M./Mme/Mlle ${this.utilisateur.nom} ${this.utilisateur.prenoms}.
    
                   Cordialement,
                   DGH
                `,
                category:"Agrément Marketeur"
            }).subscribe(
                r => {
                    console.log('transmit')
                }
            )
        }

        if(this.action == '_PROGRESSER_') this.demande.direction = 'up';
        if(this.action == '_RETOURNER_') this.demande.direction = 'down';

        console.log(this.demande)
        this.dialogRef.close(this.demande);

    }

    close() {
        this.dialogRef.close();
    }

    

}
