<div fxLayout="column"  fxFill>
    <mat-toolbar color="primary"class="mat-elevation-z4">
      <button  mat-icon-button>
          <mat-icon>history</mat-icon>
      </button>
      <div fxlayout="column" fxFlex="20" style="padding:10px;">
          <div>Historique des traitements</div>
      </div>
      <div fxFlex></div>
      
      <div fxLayout="row">

        <button mat-stroked-button color="accent" class="mat-button-rounded" disableda="!paramIsNotInExecution || !declarationIsNotInExecution"  matTooltip="Actualiser" (click)="newTreatment(null)" >
            <mat-icon>add</mat-icon> <span> Nouveau chargement</span>
        </button>
      <div matPaddingSmall></div>
        <button mat-stroked-button  matTooltip="Actualiser" (click)="load()" mat-icon-button>
            <mat-icon>refresh</mat-icon>
        </button>
        <div matPaddingSmall></div>
        <div class="bloc-search mat-stroked-button">
            <input [(ngModel)]="searchText" type="text" (keyup)="applyFilter()"/>
            <button (click)="search(searchText)" color="accent"  matTooltip="Lancer la recherche"  mat-icon-button>
                <mat-icon>search</mat-icon>
            </button>
        </div>
        <div matPaddingSmall></div>
          <!--<button mat-stroked-button mat-icon-button (click)="openForm()"  matTooltip="Nouvelle fidélisation">
              <mat-icon>add</mat-icon>
          </button>-->
      </div>
  </mat-toolbar>
  <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
  <div fxLayout="column" fxFlex>
    <table mat-table class="table-max-width" matSort  [dataSource]="dataSource" fxFlex>
        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
        <!-- Position Column -->

        <ng-container matColumnDef="uuid">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Clé </th>
          <td mat-cell *matCellDef="let element"> {{element.uuid}} </td>
        </ng-container>

        <ng-container matColumnDef="month">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Mois </th>
          <td mat-cell *matCellDef="let element"> {{element.month}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="year">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Année </th>
          <td mat-cell *matCellDef="let element"> {{element.year}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="startDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Debut du chargement </th>
          <td mat-cell *matCellDef="let element"> {{element.startDate | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="endDate">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Fin du chargement </th>
          <td mat-cell *matCellDef="let element"> {{element.endDate | date: 'dd/MM/yyyy HH:mm:ss'}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="total">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Nb. total </th>
          <td mat-cell *matCellDef="let element"> {{element.total | number}} </td>
        </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="loaded">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Nb. Recueilli </th>
          <td mat-cell *matCellDef="let element"> {{element.declarations  | number}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="parent">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> lien </th>
          <td mat-cell *matCellDef="let element">
              <button *ngIf="element.parent" color="warn"  mat-icon-button  matTooltip="Réclamation" >
                  <mat-icon>screen_share</mat-icon>
              </button>
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="origin">
          <th mat-header-cell mat-sort-header *matHeaderCellDef> Origine </th>
          <td mat-cell *matCellDef="let element">
              <button *ngIf="element.source === 'API'" color="primary"  mat-icon-button  matTooltip="API" >
                  <mat-icon>public</mat-icon>
              </button>
              <button  *ngIf="element.source === 'FILE'" style="color: #fea623;" matTooltip="Fichier" mat-icon-button>
                  <mat-icon>insert_drive_file</mat-icon>
              </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th  mat-header-cell *matHeaderCellDef > action </th>
          <td mat-cell *matCellDef="let element"  class="action-column">
            <div fxLayout="row" >
              <button mat-icon-button  [matMenuTriggerFor]="menu" >
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button  mat-menu-item class="mat-small" (click)="loadDeclaration(element)" ><mat-icon>folder_open</mat-icon>Voir les déclarations</button>
                <button  mat-menu-item class="mat-small" (click)="newTreatment(element)" ><mat-icon>folder_special</mat-icon>Traiter les réclamations</button>
              </mat-menu>

            </div>
          </td>
        </ng-container>
      
        <tr mat-header-row  *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
</div>