import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Valeur } from '../../interfaces/valeur.interface';

@Injectable()
export class ValeurService {

    private url = `${environment.api}/valeur/`;
    constructor(private http: HttpClient) { }

    save(valeur: Valeur): Observable<any> {
        return this.http.post<any>(`${this.url}`, valeur);
    }

    update(valeur: Valeur): Observable<any> {
        return this.http.put<any>(`${this.url}`, valeur);
    }

    delete(primaryKey: any): Observable<any> {
        return this.http.delete<any>(`${this.url}/${encodeURI(JSON.stringify(primaryKey))}`, );
    }

    query(queryParameter: any): Observable<any[]> {
        return this.http.get<any[]>(`${environment.api}/valeur/query/${encodeURI(JSON.stringify(queryParameter))}`);
    }
    // Trouve un seul enregistrements de TypeEntity
    findOne(primaryKey: any): Observable<any> {
        return this.http.get<any>(`${environment.api}/valeur/${encodeURI(JSON.stringify(primaryKey))}`);
    }
}
