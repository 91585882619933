<div fxLayout="column" fxFill>
    <div fxLayout="column" style="overflow-y: auto;" fxFlex>
        <mat-tab-group backgroundColor="primary" [selectedIndex]="sTabIndex">
            <mat-toolbar class="mat-elevation-z4 mat-toolbar-white">
                <button mat-icon-button>
                    <mat-icon>money</mat-icon>
                </button>
                <div fxlayout="column" fxFlex="20" style="padding:10px;">
                    <div>Mes demandes d'agrément</div>
                </div>
                <div fxFlex></div>
                <div fxLayout="row">
                    <div matPaddingSmall></div>
                    <button mat-stroked-button matTooltip="Actualiser" (click)="load()" mat-icon-button>
                        <mat-icon>refresh</mat-icon>
                    </button>
                    <!--<div matPaddingSmall></div>
            <div class="bloc-search mat-stroked-button">
                <input [(ngModel)]="searchText" type="text" (keyup)="applyFilter()" />
                <button (click)="search(searchText)" color="accent" matTooltip="Lancer la recherche" mat-icon-button>
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <div matPaddingSmall></div>-->
                    <!--<button mat-stroked-button mat-icon-button (click)="openForm()"  matTooltip="Nouvelle fidélisation">
                <mat-icon>add</mat-icon>
            </button>-->
                </div>
            </mat-toolbar>
            <mat-tab>
                <ng-template mat-tab-label> Nouveau - [ {{dataSourceNew.data.length}} ] </ng-template>
                <div fxLayout="column" fxFlex>
                    <mat-toolbar class="mat-elevation-z4 mat-toolbar-white">
                        <button mat-icon-button>
                            <mat-icon>money</mat-icon>
                        </button>
                        <div fxlayout="column" fxFlex="20" style="padding:10px;">
                            <div>Nouvelles demandes</div>
                        </div>
                        <div fxFlex></div>
                        <div fxLayout="row">
                            <div matPaddingSmall></div>
                            <button mat-stroked-button matTooltip="Actualiser" (click)="load()" mat-icon-button>
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <div matPaddingSmall></div>
                            <div class="bloc-search mat-stroked-button">
                                <input [(ngModel)]="searchText" type="text" (keyup)="applyFilter()" />
                                <button (click)="search(searchText)" color="accent" matTooltip="Lancer la recherche"
                                    mat-icon-button>
                                    <mat-icon>search</mat-icon>
                                </button>
                            </div>
                            <div matPaddingSmall></div>
                            <!--<button mat-stroked-button mat-icon-button (click)="openForm()"  matTooltip="Nouvelle fidélisation">
                                <mat-icon>add</mat-icon>
                            </button>-->
                        </div>
                    </mat-toolbar>
                    <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
                    <table mat-table class="table-max-width" matSort [dataSource]="dataSourceNew" fxFlex>
                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->
                        <!-- Position Column -->
                        <ng-container matColumnDef="numero">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Numero </th>
                            <td mat-cell *matCellDef="let element"> {{element?.numero}} </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element?.isRenouvellement" class="stick-grey">Renouvellement</span>
                                <span *ngIf="!element?.isRenouvellement" class="stick-red">Nouvel agrément</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="niveau">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Niveau </th>
                            <td mat-cell *matCellDef="let element"> {{element?.niveau}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dateTransmit">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Date de transmission </th>
                            <td mat-cell *matCellDef="let element"> {{getTransmitDate(element) | date: 'dd/MM/yyyy' }} </td>
                        </ng-container>

                        <ng-container matColumnDef="raisonSocial">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Raison Social </th>
                            <td mat-cell *matCellDef="let element"> {{element?.distributeur?.raisonSocial | uppercase}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="activite">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Activité </th>
                            <td mat-cell *matCellDef="let element"> {{element?.typeDemande}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cc">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Dossier soumis le </th>
                            <td mat-cell *matCellDef="let element"> {{element?.date | date: 'dd/MM/yyyy'}} </td>
                        </ng-container>


                        <ng-container matColumnDef="date">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Compte contribuable </th>
                            <td mat-cell *matCellDef="let element"> {{element?.distributeur?.cc}} </td>
                        </ng-container>

                        <ng-container matColumnDef="statut">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Statut </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="stick-grey" *ngIf="!element.statut">Brouillon</span>
                                <span class="stick-orange"
                                    *ngIf="element?.statut == '_SOUMIS_' && !element.clientEdit">Soumis</span>
                                <span class="stick-blue"
                                    *ngIf="element?.statut == '_ENCOURS_' && !element.signe && !element.clientEdit">En
                                    cours de traitement</span>
                                <span class="stick-green" *ngIf="element?.valide == true && element.signe">Validé</span>
                                <span class="stick-red" *ngIf="element?.valide == false && element.signe">Rejeté</span>
                                <span class="stick-red"
                                    *ngIf="(element?.statut  == '_SOUMIS_' || element?.statut  == '_ENCOURS_') && element.clientEdit">Dossier
                                    retouné</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="document">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Document </th>
                            <td mat-cell *matCellDef="let element">
                                <!--<button class="action-button-datagrid" *ngIf="element.document"
                                    (click)="openFile(element.document)" mat-stroked-button mat-icon-button
                                    matTooltip="Télécharger">
                                    <mat-icon>attach_file</mat-icon>
                                </button>-->
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> action </th>
                            <td mat-cell *matCellDef="let element">

                                <div fxLayout="row">

                                    <button mat-flat-button color="primary" class="action-button-datagrid"
                                        (click)="select(element)" matRounded matTooltip="Modifier">
                                        <span>Consulter</span>
                                        <span matPaddingSmall></span>
                                        <mat-icon>keyboard_arrow_right</mat-icon>
                                    </button>

                                </div>

                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.niveau == organisation.code ? 'current-demande' : ''" ></tr>
                    </table>
                </div>

            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label> En cours - [ {{dataSourceCurrent.data.length}} ] </ng-template>
                <div fxLayout="column" fxFlex>
                    <mat-toolbar class="mat-elevation-z4 mat-toolbar-white">
                        <button mat-icon-button>
                            <mat-icon>money</mat-icon>
                        </button>
                        <div fxlayout="column" fxFlex="20" style="padding:10px;">
                            <div>Demandes en cours de traitement</div>
                        </div>
                        <div fxFlex></div>
                        <div fxLayout="row">
                            <div matPaddingSmall></div>
                            <button mat-stroked-button matTooltip="Actualiser" (click)="load()" mat-icon-button>
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <div matPaddingSmall></div>
                            <div class="bloc-search mat-stroked-button">
                                <input [(ngModel)]="searchText" type="text" (keyup)="applyFilter()" />
                                <button (click)="search(searchText)" color="accent" matTooltip="Lancer la recherche"
                                    mat-icon-button>
                                    <mat-icon>search</mat-icon>
                                </button>
                            </div>
                            <div matPaddingSmall></div>
                            <!--<button mat-stroked-button mat-icon-button (click)="openForm()"  matTooltip="Nouvelle fidélisation">
                                <mat-icon>add</mat-icon>
                            </button>-->
                        </div>
                    </mat-toolbar>
                    <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
                    <table mat-table class="table-max-width" matSort [dataSource]="dataSourceCurrent" fxFlex>
                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->
                        <!-- Position Column -->
                        <ng-container matColumnDef="numero">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Numero </th>
                            <td mat-cell *matCellDef="let element"> {{element?.numero}} </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element?.isRenouvellement" class="stick-grey">Renouvellement</span>
                                <span *ngIf="!element?.isRenouvellement" class="stick-red">Nouvel agrément</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="niveau">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Niveau </th>
                            <td mat-cell *matCellDef="let element">
                                <div><span class="stick-grey">{{element?.niveau}}</span></div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="dateTransmit">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Date de transmission </th>
                            <td mat-cell *matCellDef="let element"> {{getTransmitDate(element) | date: 'dd/MM/yyyy' }} </td>
                        </ng-container>

                        <ng-container matColumnDef="raisonSocial">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Raison Social </th>
                            <td mat-cell *matCellDef="let element"> {{element?.distributeur?.raisonSocial | uppercase}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="activite">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Activité </th>
                            <td mat-cell *matCellDef="let element"> {{element?.distributeur?.activite}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cc">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Dossier soumis le </th>
                            <td mat-cell *matCellDef="let element"> {{element?.date | date: 'dd/MM/yyyy'}} </td>
                        </ng-container>


                        <ng-container matColumnDef="date">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Compte contribuable </th>
                            <td mat-cell *matCellDef="let element"> {{element?.distributeur?.cc}} </td>
                        </ng-container>

                        <ng-container matColumnDef="statut">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Statut </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="stick-grey" *ngIf="!element.statut">Brouillon</span>
                                <span class="stick-orange" *ngIf="element?.statut == '_SOUMIS_'">Soumis</span>
                                <span class="stick-blue" *ngIf="element?.statut == '_ENCOURS_'">En cours de
                                    traitement</span>
                                <span class="stick-green" *ngIf="element?.statut == '_VALIDE_'">Validé</span>
                                <span class="stick-red" *ngIf="element?.statut == '_REJETE_'">Rejeté</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="document">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Document </th>
                            <td mat-cell *matCellDef="let element">
                                <!--<button class="action-button-datagrid" *ngIf="element.document"
                                    (click)="openFile(element.document)" mat-stroked-button mat-icon-button
                                    matTooltip="Télécharger">
                                    <mat-icon>attach_file</mat-icon>
                                </button>-->
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> action </th>
                            <td mat-cell *matCellDef="let element">

                                <div fxLayout="row">

                                    <button mat-flat-button color="primary" class="action-button-datagrid"
                                        (click)="select(element)" matRounded matTooltip="Modifier">
                                        <span>Consulter</span>
                                        <span matPaddingSmall></span>
                                        <mat-icon>keyboard_arrow_right</mat-icon>
                                    </button>

                                </div>

                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.niveau == organisation.code ? 'current-demande' : ''"></tr>
                    </table>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label> Terminé - [ {{dataSourceValide.data.length}} ] </ng-template>
                <div fxLayout="column" fxFlex>
                    <mat-toolbar class="mat-elevation-z4 mat-toolbar-white">
                        <button mat-icon-button>
                            <mat-icon>money</mat-icon>
                        </button>
                        <div fxlayout="column" fxFlex="20" style="padding:10px;">
                            <div>Demandes Traités</div>
                        </div>
                        <div fxFlex></div>
                        <div fxLayout="row">
                            <div matPaddingSmall></div>
                            <button mat-stroked-button matTooltip="Actualiser" (click)="load()" mat-icon-button>
                                <mat-icon>refresh</mat-icon>
                            </button>
                            <div matPaddingSmall></div>
                            <div class="bloc-search mat-stroked-button">
                                <input [(ngModel)]="searchText" type="text" (keyup)="applyFilter()" />
                                <button (click)="search(searchText)" color="accent" matTooltip="Lancer la recherche"
                                    mat-icon-button>
                                    <mat-icon>search</mat-icon>
                                </button>
                            </div>
                            <div matPaddingSmall></div>
                            <!--<button mat-stroked-button mat-icon-button (click)="openForm()"  matTooltip="Nouvelle fidélisation">
                                <mat-icon>add</mat-icon>
                            </button>-->
                        </div>
                    </mat-toolbar>
                    <mat-progress-bar *ngIf="inProgress" mode="indeterminate"></mat-progress-bar>
                    <table mat-table class="table-max-width" matSort [dataSource]="dataSourceValide" fxFlex>
                        <!--- Note that these columns can be defined in any order.
                            The actual rendered columns are set as a property on the row definition" -->
                        <!-- Position Column -->
                        <ng-container matColumnDef="numero">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Numero </th>
                            <td mat-cell *matCellDef="let element"> {{element?.numero}} </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Type </th>
                            <td mat-cell *matCellDef="let element">
                                <span *ngIf="element?.isRenouvellement" class="stick-grey">Renouvellement</span>
                                <span *ngIf="!element?.isRenouvellement" class="stick-red">Nouvel agrément</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="niveau">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Niveau </th>
                            <td mat-cell *matCellDef="let element"> {{element?.niveau}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dateTransmit">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Date de transmission </th>
                            <td mat-cell *matCellDef="let element"> {{getTransmitDate(element) | date: 'dd/MM/yyyy' }} </td>
                        </ng-container>

                        <ng-container matColumnDef="raisonSocial">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Raison Social </th>
                            <td mat-cell *matCellDef="let element"> {{element?.distributeur?.raisonSocial | uppercase}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="activite">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Activité </th>
                            <td mat-cell *matCellDef="let element"> {{element?.typeDemande}} </td>
                        </ng-container>

                        <ng-container matColumnDef="cc">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Dossier soumis le </th>
                            <td mat-cell *matCellDef="let element"> {{element?.date | date: 'dd/MM/yyyy'}} </td>
                        </ng-container>


                        <ng-container matColumnDef="date">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Compte contribuable </th>
                            <td mat-cell *matCellDef="let element"> {{element?.distributeur?.cc}} </td>
                        </ng-container>

                        <ng-container matColumnDef="statut">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Statut </th>
                            <td mat-cell *matCellDef="let element">
                                <span class="stick-grey" *ngIf="!element.statut">Brouillon</span>
                                <span class="stick-orange"
                                    *ngIf="element?.statut == '_SOUMIS_' && !element.clientEdit">Soumis</span>
                                <span class="stick-blue"
                                    *ngIf="element?.statut == '_ENCOURS_' && !element.signe && !element.clientEdit">En
                                    cours de traitement</span>
                                <span class="stick-green" *ngIf="element?.valide == true && element.signe">Validé</span>
                                <span class="stick-red" *ngIf="element?.valide == false && element.signe">Rejeté</span>
                                <span class="stick-red"
                                    *ngIf="(element?.statut  == '_SOUMIS_' || element?.statut  == '_ENCOURS_') && element.clientEdit">Dossier
                                    retouné</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="document">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> Document </th>
                            <td mat-cell *matCellDef="let element">
                                <!--<button class="action-button-datagrid" *ngIf="element.document"
                                    (click)="openFile(element.document)" mat-stroked-button mat-icon-button
                                    matTooltip="Télécharger">
                                    <mat-icon>attach_file</mat-icon>
                                </button>-->
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell mat-sort-header *matHeaderCellDef> action </th>
                            <td mat-cell *matCellDef="let element">

                                <div fxLayout="row">

                                    <button mat-flat-button color="primary" class="action-button-datagrid"
                                        (click)="select(element)" matRounded matTooltip="Modifier">
                                        <span>Consulter</span>
                                        <span matPaddingSmall></span>
                                        <mat-icon>keyboard_arrow_right</mat-icon>
                                    </button>

                                </div>

                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="row.niveau == organisation.code ? 'current-demande' : ''"></tr>
                    </table>
                </div>
            </mat-tab>
        </mat-tab-group>




    </div>
    <mat-paginator [pageSizeOptions]="[50, 100, 150]" showFirstLastButtons></mat-paginator>
</div>