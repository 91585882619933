<div class="mat-padding" fxLayout="column" fxLayoutAlign="center center" fxFill>
  
    <perfect-scrollbar   fxLayout="column" fxLayoutAlign="center center" [config]="{}">
        <div fxLayout="row" fxLayoutAlign="center center" class="mat-padding">
            <span class="mat-headline">Verification de compte</span>
        </div>
        <form [formGroup]="form" class="inscription-form"  fxLayout="column" >
          <div class="bloc-input" fxLayout="column"  fxFlex>
            
            <mat-form-field appearance="fill">
              <mat-label>Code d'activation</mat-label>
              <mat-icon matSuffix>filter_1</mat-icon>
              <input matInput placeholder="ex: 014578" type="text" formControlName="code"  autocomplete="off">
            </mat-form-field>          
          </div>
        <div matPaddingSmall></div>
        <div *ngIf="error.code" class="mat-caption mat-error">{{error.code}}</div>
        <div class="mat-caption">Nous vous avons envoyé un code de verification à l'adresse suivante : </div>
        <div class="mat-body-2">{{user.email}}</div>
        
        <div class="mat-padding"></div>
        <button type="submit" class="mat-button-rounded" mat-flat-button color="primary" (click)="verify()" fxFLex>
          <div *ngIf="inProgress" class="spiner-button" fxLayout="row" fxLayoutAlign="center center" ><mat-spinner diameter="20"></mat-spinner></div>
          <span *ngIf="!inProgress">Valider et continuer</span>
        </button>
        
        <div  fxLayout="column" fxLayoutAlign="center center" matPaddingSmall>
            <span class="mat-body-2">ou </span>
        </div>
        <div matPaddingSmall></div>
        <button class="mat-button-rounded" (click)="sendActivationCode()" color="primary" type="submit"  mat-stroked-button  mat-button>
            Envoyer un code par mail
        </button>
      </form>
      <!-- Overlay de Connexion -->
      <div class="mat-overlay-spinner" *ngIf="progress.loading || progress.error" fxLayout="column" fxLayoutAlign="center center">
        <mat-spinner *ngIf="progress.loading " diameter="60"></mat-spinner>
        <mat-icon color="warn"  *ngIf="progress.error">close</mat-icon>
        <div class="mat-body-2 mat-padding-small" >{{progress.label}}</div>
    </div>
    <!-- Fin Overlay -->

    </perfect-scrollbar>
  </div>
