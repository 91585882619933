import { Injectable } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse,HttpEvent,HttpResponse } from '@angular/common/http';
// component
import { ConfirmComponent } from '../components/confirm/confirm.component';
import { HttpErrorInterceptorComponentComponent } from '../components/http-error-interceptor-component/http-error-interceptor-component.component';

import { ConfirmBottomSheetComponent } from '../components/confirm-bottom-sheet/confirm-bottom-sheet.component';
import { AlertComponent } from '../components/alert/alert.component';
import { PromptComponent } from '../components/prompt/prompt.component';

export interface ParamDialog {
  title: string;
  message: string;
}

@Injectable({ providedIn: 'root' })
export class ModalService {
    constructor(
      public dialog: MatDialog,
      private _bottomSheet: MatBottomSheet
      ) { }

    public alert(paramDialog: ParamDialog): MatDialogRef<AlertComponent, any> {
      const dialogRef = this.dialog.open(AlertComponent, {data: paramDialog, disableClose: true, id:'startId', backdropClass:'startBackDrop'});
      return dialogRef;
    }

    public prompt(paramDialog: ParamDialog): MatDialogRef<PromptComponent, any> {
      const dialogRef = this.dialog.open(PromptComponent, {data: paramDialog, disableClose: true, id:'startId', backdropClass:'startBackDrop'});
      return dialogRef;
    }

    public confirm(paramDialog: ParamDialog): MatDialogRef<ConfirmComponent, any> {
      const dialogRef = this.dialog.open(ConfirmComponent, {data: paramDialog, id:'startId', backdropClass:'startBackDrop'});
      return dialogRef;
    }

    public bottomConfirm(paramDialog: ParamDialog): MatBottomSheetRef<ConfirmBottomSheetComponent, any> {
      const bottomRef = this._bottomSheet.open(ConfirmBottomSheetComponent, {data: paramDialog});
      return bottomRef;
    }

    public interceptorDialog(error: HttpErrorResponse): MatDialogRef<HttpErrorInterceptorComponentComponent, any> {
      const dialogRef = this.dialog.open(HttpErrorInterceptorComponentComponent,
        { width: '480px', height: '540px', disableClose: true, data: error });
      return dialogRef;
    }

}
