<div fxLayout="column" class="defaultTheme" fxFill>
    <mat-toolbar color="primary">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex>

            <mat-icon>close_fullscreen</mat-icon>

            <div matPaddingSmall></div>
            <span fxFlex="20" style="padding:10px;">Se coupler</span>
        </div>
        <div fxLayout="row" fxFlex>
            <!--<input class="input-search" type="text" [matAutocomplete]="auto"/>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option>
                <img class="example-option-img" aria-hidden  height="25">
                <span>Hello</span> |
                <small>Comment allez vous</small>
                </mat-option>
            </mat-autocomplete>
            <button mat-stroked-button><mat-icon>search</mat-icon></button>-->
        </div>
        <button color="accent" class="mat-button-rounded" matTooltip="Se coupler à un entreprise" mat-icon-button
            mat-stroked-button (click)="close()" mat-button>
            <mat-icon>close</mat-icon>
        </button>

    </mat-toolbar>
    <mat-card matPadding fxLayout="column" fxFlex>
        <div class="mat-overlay-spinner" *ngIf="progress.loading" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner diameter="60"></mat-spinner>
            <div class="mat-body-2 mat-padding-small">{{progress.label}}</div>
        </div>
        <div fxLayout="column"  matPadding fxFlex>
            <div matPadding style="overflow-y: auto;" fxLayout="column">
                <div class="bloc-search mat-stroked-button">
                    <input [(ngModel)]="searchText" placeholder="Entrer le code entreprise" type="text"
                        (keyup)="applyFilter()" fxFlex />
                    <button color="accent" matTooltip="Lancer la recherche" (click)="search()" mat-icon-button>
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
            <div matPadding>
                <table  class="mat-table cdk-table table-max-width" fxFlex>
                    <!-- Position Column -->
                    <tbody>
                        <tr>
                            <th class="mat-header-cell cdk-header-cell"><div matPadding>Code DGH</div></th>
                            <td class="mat-row cdk-row mat-footer-cell"><div class=".mat-body-2" mat-line>{{entreprise.codeDGH | uppercase}}</div></td>
                        </tr>
                        <tr>
                            <th class="mat-header-cell cdk-header-cell"><div matPadding>Raison Social</div></th>
                            <td class="mat-row cdk-row mat-footer-cell"><div class=".mat-body-2" mat-line>{{entreprise.raisonSocial}}</div></td>
                        </tr>
                    </tbody>
                </table>  
            </div>
            <div fxFlex></div>
            <div fxLayout="row" fxLayoutAlign="end center" matPadding>
                <button color="accent" [disabled]="!entreprise._id" matRounded mat-flat-button mat-button (click)="seCoupler()">
                    <mat-icon>close_fullscreen</mat-icon>
                    <span matPaddingSmall></span>
                    <span>Se coupler</span>
                </button>
            </div>
        </div>


    </mat-card>
</div>