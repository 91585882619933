import { Component, Input, forwardRef, AfterViewInit, OnInit, Injector, Output, EventEmitter, Optional, Self } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl, FormControl } from '@angular/forms';
import { FileUpload, FileUploadEntity } from '../../interfaces/fileupload.interface';
import { FileUploadService } from '../../services/file-upload.service';
import { ModalService } from '../../services/modal.service';

const noop = () => {
};

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadComponent),
            useValue: (c: FormControl) => {
                console.log(25);
                return c;
            },
            multi: true
        }
    ]
})
export class FileUploadComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    //get fileSelected() { return this._fileSelected; }
    //set fileSelected(val) { this._fileSelected = val; this.propagateChange(this._fileSelected); }

    constructor(
        public fileService: FileUploadService, private inj: Injector,
        private modal: ModalService,
        @Optional() @Self() public ngControl: NgControl
    ) {

        // Replace the provider from above with this.
        if (this.ngControl != null) {
            // Setting the value accessor directly (instead of using
            // the providers) to avoid running into a circular import.
            this.ngControl.valueAccessor = this;
        }
    }

    public file: any;
    public iconeFile: string = '';
    public imageB64: any  | undefined;
    public imageStyle: string | undefined;
    public inProgress: boolean | undefined;

    input: any = document.createElement('input');
    /////////////////
    public unit: string | undefined;
    ////////////////////////////// d/////

    @Input() fichier: any;
    @Input() disabled: boolean = false;
    @Input() mode: string | undefined;
    @Input() accept: string | undefined;
    @Input() limitSize: number | undefined;

    @Input() _fileSelected: any = null;
    @Input() placeholder: string  | undefined  = '';
    @Output() onUpload = new EventEmitter<any>();
    private onChangeCallback: (_: any) => void = noop;

    propagateChange: Function = () => { };
    registerOnChange(fn: Function) { this.propagateChange = fn; }
    registerOnTouched() { }
    writeValue(value: any) { console.log(value); this._fileSelected = value; }

    get value(): any {
        return this._fileSelected;
    };
    //set accessor including call the onchange callback
    set value(v: any) {
        if (v !== this._fileSelected) {
            this._fileSelected = v;
            this.onChangeCallback(v);
        }
    }

    ngOnInit(): void {
        this.onFileSelected();
    }

    onFileSelected() {
        this.inProgress = false;
        this.input.setAttribute('type', 'file');
        this.input.setAttribute('accept', this.accept);
        this.input.addEventListener('change', (e:any) => {
            // Select file
            this.file = this.input.files[0];
            this.limitSize = (this.limitSize && this.limitSize > 0) ? this.limitSize : 999999999999999;
            if (this.file.size <= this.limitSize) {
                this.iconeFile = this.getIcon(this.file);
                const reader = new FileReader();
                reader.readAsDataURL(this.file);
                reader.onload = () => {
                    this.imageB64 = reader.result;
                    const { name, type, size, lastModified } = this.file;

                    const file = new FileUpload({
                        name, size, type,
                        createdDate: new Date(lastModified),
                        lastModifiedDate: new Date(lastModified),
                        base64: this.imageB64
                    });
                    this.inProgress = true;
                    this.fileService.upload(file, this.file).subscribe((result: any) => {
                        setTimeout(() => {
                            this.inProgress = false; // Stop le loader
                            if (result) {
                                this._fileSelected = result;
                                this.value = result;
                                this.fichier = result;
                                this.fichier.url = result.url;
                                //this.fileSelected.push(media);
                                this.onUpload.emit(this._fileSelected);
                            }
                        }, 5000)

                    });
                };
                reader.onerror = function (error) { console.log('Error: ', error); };
            }
            else {
                this.modal.alert({ title: 'Erreur', message: `La taille de ce fichier est supérieur à ${this.limitSize}`})
            }



        });
    }

    ngAfterViewInit(): void {
        /*
          if (!isUndefined(this.fileSelected) ) {
                this.inProgress = true;
                this.fileService.findOne({id: this.fileSelected}).subscribe((result: any) => {
                    this.inProgress = false; // Stop le loader
                    if (result) {
                      this.file = result;
                      this.iconeFile = this.getIcon(this.file);
                      this.imageB64 = this.iconeFile === 'png' || this.iconeFile === 'jpg' || this.iconeFile === 'svg' ? this.fileService.getFileUri({id: this.fileSelected}): null;
                    }
              });
          }*/
    }
    selectFile() { this.input.click(); }

    openFile = (file: any) => {
        window.open(file.url);
    }

    deleteSelectedFile(file: any) {
        /*this.fileSelected.forEach((item, index) => {
            if (item._id === file._id) this.fileSelected.splice(index, 1);
        });*/
        this.file = null;
        this.fichier = null;
    }

    getIcon(file: File): string {
        let icon: string;
        switch (file.type) {
            case 'image/jpeg': { icon = 'jpg'; break; }
            case 'image/png': { icon = 'png'; break; }
            case 'image/svg+xml': { icon = 'svg'; break; }
            case 'application/x-zip-compressed': { icon = 'zip'; break; }
            case 'application/pdf': { icon = 'pdf'; break; }
            case 'application/msword': { icon = 'doc'; break; }
            case 'application/x-msdownload': { icon = 'exe'; break; }
            default: { icon = 'txt'; break; }
        }
        return icon;
    }

    getSize(size:number): number {
        if (isNaN(size)) {
            if (size > 1000 && this.unit !== 'To') {
                size = size / 1000;
                switch (this.unit) {
                    case undefined: { size = this.getSize(size); this.unit = 'ko'; break; }
                    case 'Ko': { size = this.getSize(size); this.unit = 'Mo'; break; }
                    case 'Mo': { size = this.getSize(size); this.unit = 'Go'; break; }
                    case 'Go': { size = this.getSize(size); this.unit = 'To'; break; }
                }
            }
        }
        return size;
    }
}
