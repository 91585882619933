<mat-toolbar color="primary">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
        <mat-icon>http</mat-icon>
        <div matPaddingSmall></div>
        <span fxFlex="20" style="padding:10px;">{{error.statusText}}</span>
    </div>
    <div fxLayout="row" fxFlex>
        <!--<input class="input-search" type="text" [matAutocomplete]="auto"/>
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option>
              <img class="example-option-img" aria-hidden  height="25">
              <span>Hello</span> |
              <small>Comment allez vous</small>
            </mat-option>
          </mat-autocomplete>
          <button mat-stroked-button><mat-icon>search</mat-icon></button>-->
    </div>
    <div fxLayout="row">
        
    </div>
</mat-toolbar>
<div fxLayout="column" matPadding>
    <div class="mat-title"> {{ error.url }}</div>
    <pre class="http-reponse">
        {{ error.error.status.message | json }}
    </pre>
    <div matPadding fxLayout="row" fxLayoutAlign="end center" fxFlex>
        <button matRounded mat-stroked-button>
            <mat-icon>cancel</mat-icon><span matPaddingSmall></span><span>Quitter</span>
        </button>
    </div>
</div>  