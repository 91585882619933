import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpErrorResponse,HttpEvent,HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-http-error-interceptor-component',
  templateUrl: './http-error-interceptor-component.component.html',
  styleUrls: ['./http-error-interceptor-component.component.css']
})
export class HttpErrorInterceptorComponentComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<HttpErrorInterceptorComponentComponent>,
        @Inject(MAT_DIALOG_DATA) public error: HttpErrorResponse
    ) { }

    ngOnInit() {
    }

    close() {
        this.dialogRef.close();
    }

}
